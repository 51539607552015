import React, { useEffect, useState } from "react";
import AdminLayout from "../../../components/layouts/adminLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import { useNavigate } from "react-router";
import Editor from "../../../components/common/Editor/Editor";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import StaticPageService from "../../../services/API/StaticPageService";
import AdminPageHeader from "../../../components/AdminPageHeader";
import { editorRegex } from "../../../utils/utils";

export default function FAQ() {
  const {
    data: aboutUs,
    isLoading: loading,
    isSuccess: success,
    refetch,
  } = useQuery(
    ["/api/get_content/FAQ"],
    StaticPageService.getFAQContent,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const navigate = useNavigate();
  const [data, setData] = useState("");

  

  
  useEffect(()=>{
    if(aboutUs?.data?.content){
     setData(aboutUs?.data?.content)
    }
   },[aboutUs])

  const handleSave = async () => {
    if (data.replace(editorRegex, '').trim().length) {
      try {
        const resp = await StaticPageService.submitFAQContent({
          slug: "FAQ",
          content: data,
        });
        if (resp) {
          toast.success(resp.message);
        }
      } catch (e) {
        toast.error(e.message);
      }
    } else {
      toast.error("Content required");
    }
  };
  return (
    <AdminLayout test="active">
      <AdminPageContainer>
        <div id="main" role="main">
          <div id="content">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item ">
                  <a
                    style={{
                      color: "blue",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/system-admin/license-master")}
                    onKeyDown={() => navigate("/system-admin/license-master")}
                  >
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active">Static Pages</li>

                <li className="breadcrumb-item active">FAQs</li>
              </ol>
            </div>
          

            <div>
              <div className="row">
                <div className="card card-sm card-shadow mb-3">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <AdminPageHeader title="FAQs" />
                    </div>
                    <Editor
                      data={data}
                      setData={setData}
                      handleSave={handleSave}
                      showSaveBtn={true}
                    />
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </AdminPageContainer>
    </AdminLayout>
  );
}
