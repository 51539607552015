import React, { useContext, useState } from "react";
import CustomModal from "../../../components/common/CustomModal";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import Multiselect from "../../../components/common/MultiSelect";
import { Button } from "react-bootstrap";
import { AuthContext } from "../../../context/authContext";
import { useQuery } from "react-query";
import { ROLES } from "../../../constant/roles";

const ReassignVendorModal = ({
  show,
  selectedSRN,
  setSelectedSRN,
  refetch,
  licenseId
}) => {
  const [executive, setExecutive] = useState();
  const [FExecutive, setFExecutive] = useState();
  const [loader, setLoader] = useState("");
  const [errors, setErrors] = useState({});
  const auth = useContext(AuthContext);

  const validate = () => {
    let errors = {};
    if (!executive?.id && !FExecutive?.id) {
      errors.executive =
        "Please select executive or field executive for reassignment.";
    }
    return errors;
  };
  async function reassign() {
    const error = validate();
    if (Object.keys(error).length) {
      setErrors(error);
      return;
    }
    setErrors({});
    if (executive?.id) {
      setLoader("reassign");
      const resp = await AllianceManagerServices.srnReassignToVendor({
        service_ids: [selectedSRN?.id],
        vendorId: executive?.id,
        user_role_id: ROLES.executive,
      });
      setLoader("");
      if (resp) {
        toast.success("Executive reassigned successfully");
        refetch();
        setSelectedSRN(null);
      }
    }
    if (FExecutive?.id) {
      setLoader("reassign");
      const resp2 = await AllianceManagerServices.srnReassignToVendor({
        service_ids: [selectedSRN?.id],
        vendorId: FExecutive?.id,
        user_role_id: ROLES.fieldExecutive,
      });
      setLoader("");
      if (resp2) {
        toast.success("Executive and field executive reassigned successfully");
        refetch();
        setSelectedSRN(null);
      }
    }
  }

  const { data: ExecutivesList } = useQuery(
    ["/api/executive", { vendorIds: [auth?.userId] }],
    AllianceManagerServices.getRQExecutiveList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { data: FExecutivesList } = useQuery(
    ["/api/field_executive", { executiveIds: [executive?.id] }],
    AllianceManagerServices.getRQFieldExecutiveList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return (
    <CustomModal
      show={show}
      onCloseText="Cancel"
      onSubmitText={loader ? <Loader /> : "Reassign"}
      headerTitle={`Reassign ${selectedSRN?.srn_name}`}
      onSubmit={reassign}
      onClose={() => setSelectedSRN(null)}
      disableClose={!!loader}
      disableSubmit={!!loader}
    >
      <div>
        <p className="txt-fail m-0 p-0 pb-2">{errors?.executive}</p>
        <label>Please select executive for reassignment</label>

        <Multiselect
          isMulti={false}
          options={ExecutivesList?.data?.filter(
            (item) => item?.is_enabled == 1 && item?.license_names?.filter((fitem)=>fitem?.id == licenseId)?.length
          )}
          value={executive}
          isSearchable
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          onChange={(option) => {
            setExecutive(option);
            setFExecutive(null);
          }}
          menuPosition="fixed"
          placeholder="Select executive"
        />
      </div>
      <div className="mt-5">
        <label>Please select field executive for reassignment</label>

        <Multiselect
          isMulti={false}
          options={FExecutivesList?.data?.filter(
            (item) => item?.is_enabled == 1 && item?.license_names?.filter((fitem)=>fitem?.id == licenseId)?.length
          )}
          value={FExecutive}
          isSearchable
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          onChange={(option) => setFExecutive(option)}
          menuPosition="fixed"
          placeholder="Select field executive"
        />
        <p className="txt-fail m-0 p-0">{errors.FExecutive}</p>
      </div>
    </CustomModal>
  );
};

export default ReassignVendorModal;
