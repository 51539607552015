/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "bootstrap";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { localStorageKeys } from "../../../constant/keys";
import { AuthContext } from "../../../context/authContext";
import CustomModal from "../../common/CustomModal";
import AuthService from "../../../services/authServices";
import { toast } from "react-toastify";

const AdminHeader = ({ toggleSidebar, setToggleSidebar }) => {
  const auth = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleLogout = async () => {
    setShowModal(false);
    const response = await AuthService.logout();
    if (response) {
      localStorage.removeItem(localStorageKeys.aparajithaData);
      auth.setIsAuthenticated(false);
      toast.success("Logged out successfully");
      navigate("/admin/login");
    }
  };
  return (
    <header id="header">
      <div id="logo-group">
        <span id="logo">
          {" "}
          <img src="/img/aparajitha-logo.svg" alt="SmartAdmin" width="250px" />
        </span>
      </div>
      <div className="pull-right">
        {auth?.isAuthenticated ? (
          <div
            id="logout"
            className="btn-header transparent pull-right"
            onClick={() => {
              setShowModal(true);
            }}
            onKeyDown={() => {
              setShowModal(true);
            }}
          >
            <span>
              {" "}
              <a
                title="Logout"
                data-action="userLogout"
                data-logout-msg="You can improve your security further after logging out by closing this opened browser"
              >
                <i className="fa fa-sign-out"></i>
              </a>{" "}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      <CustomModal
        onClose={handleCloseModal}
        onSubmit={handleLogout}
        show={showModal}
        modalSize={"md"}
        onCloseText="Cancel"
        onSubmitText="Logout"
        headerTitle="Are you sure you want to logout?"
      ></CustomModal>
    </header>
  );
};

export default AdminHeader;
