import React, { useState } from "react";
import VendorLayout from "../../../components/layouts/VendorLayout";
import { Link } from "react-router-dom";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  Sector,
  PieChart,
  Cell,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useQuery } from "react-query";
import VendorManagerServices from "../../../services/API/VendorManager";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import Pagination from "react-js-pagination";
import AdminTable from "../../../components/common/AdminTable/AdminTable";
import LicnseTypeWiseStatistics from "../../../components/AllianceManager/Dashboard/LicenseTypeWiseStatistics";
import DognutChartComponent from "../../../components/common/Charts/DognutChartComponent";
import ExecutiveWorkloadStatistic from "../../../components/Vendor/Dashboard/ExecutiveWorkloadStatistic";
import AreaChartComponent from "../../../components/common/Charts/AreaChartComponent";
import moment from "moment";
import { compare, getDataSortedAreaChart } from "../../../utils/utils";
import Tiles from "../../../components/Vendor/Tile/Tiles";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import DashboardCard from "../../../components/common/DashboardCard";

export default function DashboardVendorManager() {
  // const [statusCards, setStatusCards] = useState([
  //   { title: "Total Request", total: 500, colorClass: "" },
  //   { title: "Request Not Started", total: 110, colorClass: "" },
  //   { title: "Request Inprogress", total: 80, colorClass: "text-info" },
  //   { title: "Request Overdue", total: 20, colorClass: "text-danger" },
  //   { title: "Pending With Aauthority", total: 100, colorClass: "" },
  //   { title: "Copy Shared", total: 80, colorClass: "" },
  //   { title: "Request Completed", total: 300, colorClass: "text-primary" },
  // ]);
  const navigate = useNavigate();
  const limit = 10;
  const [page, setPage] = useState(1);
  const [cardNumber, setCardNumber] = useState(0);

  useEffect(() => {
    if (cardNumber !== 0) {
      navigate("/vendor/overall-license-requests", {
        state: { cardNumber: cardNumber },
      });
    }
  }, [cardNumber]);

  const { data: assignmentStatus } = useQuery(
    ["/api/alliance-assignment-status"], //
    AllianceManagerServices.allianceAssignmentStatus,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { data: overdueCount } = useQuery(
    ["/api/alliance/dashboard/overdue-count"], //
    AllianceManagerServices.executivesWithOverdueCount,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  // getting closure trend data via api

  const { data: closureTrend } = useQuery(
    ["/api/weekly-closure-trend"], //
    AllianceManagerServices.weeklyClosureTrend,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { data } = useQuery(
    ["/api/all_count_license_request", {}],
    AllianceManagerServices.allCountLicenseRequest,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const cards = [
    {
      name: "Total Requests",
      value: data?.data[0]?.total ?? "-",
      cardStatus: "",
      onClick: () => navigate("/vendor/overall-license-requests"),
    },
    {
      name: "Requests Not Started",
      value: data?.data[2]?.total ?? "-",
      cardStatus: "not_started",
      onClick: () =>
        navigate("/vendor/overall-license-requests", {
          state: {
            card: {
              title: "Requests Not Started",
              cardStatus: "not_started",
            },
          },
        }),
    },
    {
      name: "Requests Inprogress",
      value: data?.data[3]?.total ?? "-",
      valueColor: "#00a5e5",
      cardStatus: "in_progress",
      onClick: () =>
        navigate("/vendor/overall-license-requests", {
          state: {
            card: {
              title: "Requests Inprogress",
              cardStatus: "in_progress",
            },
          },
        }),
    },
    {
      name: "Requests Overdue",
      value: data?.data[4]?.total ?? "-",
      valueColor: "#eb6e6e",
      cardStatus: "Overdue",
      onClick: () =>
        navigate("/vendor/overall-license-requests", {
          state: {
            card: {
              title: "Requests Overdue",
              cardStatus: "Overdue",
            },
          },
        }),
    },
    {
      name: "Pending With Authority",
      value: data?.data[7]?.total ?? "-",
      cardStatus: "Pending With Authority",
      onClick: () =>
        navigate("/vendor/overall-license-requests", {
          state: {
            card: {
              title: "Pending With Authority",
              cardStatus: "Pending With Authority",
            },
          },
        }),
    },
    {
      name: "Copy Shared",
      value: data?.data[8]?.total ?? "-",
      cardStatus: "Copy Shared",
      onClick: () =>
        navigate("/vendor/overall-license-requests", {
          state: {
            card: {
              title: "Copy Shared",
              cardStatus: "Copy Shared",
            },
          },
        }),
    },
    {
      name: "Requests Completed",
      value: data?.data[1]?.total ?? "-",
      valueColor: "#00b050",
      cardStatus: "Completed",
      onClick: () =>
        navigate("/vendor/overall-license-requests", {
          state: {
            card: {
              title: "Requests Completed",
              cardStatus: "Completed",
            },
          },
        }),
    },
  ];

  const dataBar = [
    {
      name: "Assign",
      amt: 20,
    },
    {
      name: "Not Assign",
      amt: 40,
    },
  ];

  const COLORS = ["#6FE3A5", "#EA7A66"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <VendorLayout>
      <div id="main" role="main">
        <div id="content">
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item ">
                <Link to={"/vendor/dashboard"}>Home</Link>
              </li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </div>
          <div className="d-flex gap-2 flex-wrap pb-4">
            {cards?.map((item, index) => {
              return (
                <div key={item?.name}>
                  <DashboardCard
                    title={item?.name}
                    valueColor={item?.valueColor}
                    value={item?.value}
                    onClick={() => (item?.onClick ? item?.onClick() : () => {})}
                  />
                </div>
              );
            })}
          </div>
          <div className="row">
            <div className="col-md-4 col-xl-4">
              <div>
                <div className="card card-sm card-shadow mb-3">
                  <div className="card-body">
                    <div className="chartjs-size-monitor">
                      <div className="chartjs-size-monitor-expand">
                        <div className=""></div>
                      </div>
                      <div className="chartjs-size-monitor-shrink">
                        <div className=""></div>
                      </div>
                    </div>
                    <h4>
                      TAT Status
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => (
                          <Tooltip id="button-tooltip" {...props}>
                            TAT Status Formula - <br />
                            Total no. of requests closed within due date / Total
                            no of requests = Adhered <br />
                            Total no. of requests closed after due date / Total
                            no of requests = Lapsed
                          </Tooltip>
                        )}
                      >
                        <span className="pull-right">
                          <i
                            className="fa fa-info-circle fa-fw font-md text-info"
                            rel="popover-hover"
                            data-placement="top"
                            data-original-title="Popover activated on hover"
                            data-content="Sed posuere consectetur est at lobortis. Aenean eu leo quam.
									   Pellentesque ornare sem lacinia quam venenatis vestibulum."
                          ></i>
                        </span>
                      </OverlayTrigger>
                    </h4>

                    <Card>
                      <div className="mt-3 mx-3">
                        <div className="d-flex mb-1 gap-2">
                          <div
                            className="h-20 "
                            style={{
                              width: "20px",
                              backgroundColor: "#6FE3A5",
                            }}
                          >
                            &nbsp;
                          </div>
                          <div>
                            {" Adhered = " + assignmentStatus?.data?.adhered_count ||
                              0}
                          </div>
                        </div>
                        <div className="d-flex gap-2">
                          <div
                            className="h-20"
                            style={{
                              width: "20px",
                              backgroundColor: "#EA7A66",
                            }}
                          >
                            &nbsp;
                          </div>

                          <div>
                            {"Lapsed = " + assignmentStatus?.data?.overdue_count || 0}
                          </div>
                        </div>
                      </div>
                      <Card.Body
                        className="d-flex flex-wrap justify-content-center align-items-center"
                        style={{ height: "230px" }}
                      >
                        {assignmentStatus?.data?.Adhered +
                          assignmentStatus?.data?.Lapsed !==
                        0 ? (
                          <DognutChartComponent
                            data={[
                              {
                                name: "Adhered",
                                value:
                                  +assignmentStatus?.data
                                    ?.adhered_percen_format,
                              },
                              {
                                name: "Lapsed",
                                value:
                                  +assignmentStatus?.data?.lapsed_percen_format,
                              },
                            ]}
                            COLORS={["#6FE3A5", "#EA7A66"]}
                            responsiveHeight={200}
                            responsiveWidth={"100%"}
                          />
                        ) : (
                          <div
                            style={{
                              height: "170px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            No Data to Show
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xl-4">
              <div class="card card-sm card-shadow mb-3 ">
                <div class="card-body" style={{ height: "340px" }}>
                  <h4>Executives With Overdue Count</h4>
                  {overdueCount?.data?.length ? (
                    <AreaChartComponent
                      data={overdueCount?.data?.map((item) => {
                        return {
                          name: item.executive_name,
                          Count: +item.Overdue,
                        };
                      })}
                      bgColor={"#EA7A66"}
                      hideXAxis={true}
                    />
                  ) : (
                    <div
                      style={{
                        height: "280px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      No Data to Show
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xl-4">
              <div class="card card-sm card-shadow mb-3 ">
                <div class="card-body" style={{ height: "340px" }}>
                  <h4>Weekly Closure Trend</h4>
                  {getDataSortedAreaChart(closureTrend)?.length ? (
                    <AreaChartComponent
                      data={getDataSortedAreaChart(closureTrend)}
                      bgColor={"#6FE3A5"}
                    />
                  ) : (
                    <div
                      style={{
                        height: "320px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      No Data to Show
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <ExecutiveWorkloadStatistic />
          </div>
          <div class="row">
            <LicnseTypeWiseStatistics />
          </div>
        </div>
      </div>
    </VendorLayout>
  );
}
