import { async } from "q";
import React from "react";
import axiosInstance, { getAccessToken } from "../axiosConfig";
import { toast } from "react-toastify";
import fileSaver from "file-saver";
import axios from "axios";
class LicenseMasterServices extends React.Component {
  getLicenseList = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key] && data[key] !== 0 && data[key] !== "0") {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.get(`/api/license-mapping`, { params: newData });
  };

  getMappingList = async () => {
    return await axiosInstance.get(`api/license-mapping/all`);
  };

  updateLicenseStatus = async (id, status) => {
    return await axiosInstance.post(
      `/api/license-mapping/update/status/${id}`,
      {
        status: status,
      }
    );
  };

  addLicense = async (data) => {
    return await axiosInstance.post(`/api/license-mapping/add`, data);
  };

  getAllLicense = async (params) => {
    return await axiosInstance.get(`/api/license`, { params: params });
  };

  getAllRelatedLicense = async (params) => {
    return await axiosInstance.get(`/api/license-mapping/related_license`);
  };

  getAllFrontendLicense = async () => {
    return await axiosInstance.get(`api/license`, {
      params: { source: "frontend", active: 1, shortlist: 1, limit: 4 },
    });
  };

  getLicenseClassification = async (params) => {
    return await axiosInstance.get(`/api/license/license-type`, {
      params: params,
    });
  };

  getRegions = async (params) => {
    return await axiosInstance.get(`/api/get_zone`, {
      params: params,
    });
  };

  getAllStates = async (params) => {
    return await axiosInstance.get(`/api/get_states`, {
      params: params,
    });
  };

  getAllCity = async (params) => {
    return await axiosInstance.get(`/api/get_city`, { params: params });
  };

  getAllMunicipal = async (params) => {
    return await axiosInstance.get(`/api/get_municipal_details`, {
      params: params,
    });
  };

  getAllIndustry = async (params) => {
    return await axiosInstance.get(`/api/license/license-industry`, {
      params: params,
    });
  };

  getAllCompanyType = async (params) => {
    return await axiosInstance.get(`/api/license/license-company`, {
      params: params,
    });
  };

  getAllDocuments = async (params) => {
    return await axiosInstance.get(`/api/document-list`, {
      params: params,
    });
  };
  getLicenseDetailsList = async() => {
    
    return await axiosInstance.get(`/api/license_details?limit=0`);
  };


  updateLicense = async (id, data) => {
    return await axiosInstance.post(`/api/license-mapping/update/${id}`, data);
  };

  getLicenseById = async (id) => {
    // const [_key, { id }] = queryKey;
    return await axiosInstance.get(`/api/license-mapping/edit/${id}`);
  };

  downloadReport = async () => {
    // const [_key] = queryKey;
    axiosInstance.defaults.headers.put["responseType"] = "blob";
    return await axiosInstance.get(`/api/license-mapping/export`);
  };


  downloadSampleReport = async (params) => {
    // const [_key] = queryKey;
    axiosInstance.defaults.headers.put["responseType"] = "blob";
    return await axiosInstance.post(`/api/bulk_sample_download`,params);
  };



  uploadReport = async (formData) => {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: getAccessToken(),
    };
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await axios.post(
        `${apiUrl}/api/license-mapping/import`,
        formData,
        {
          headers,
          responseType: "arraybuffer",
        }
      );

      const contentType = response.headers["content-type"];
      if (contentType.includes("application/json")) {
        const arrayBufferConverted = JSON.parse(
          String.fromCharCode.apply(null, new Uint8Array(response.data))
        );
        console.log(arrayBufferConverted);
        toast.success(arrayBufferConverted?.message);
      } else if (
        contentType.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        toast.info(
          "File imported with errors. Please check downloaded file for failures"
        );
        const blob = new Blob([response.data], {
          type: contentType,
        });
        fileSaver.saveAs(blob, "failures.xlsx");
      } else {
        toast.error("Unexpected response format.");
      }
    } catch (error) {
      const responseError = JSON.parse(
        String.fromCharCode.apply(null, new Uint8Array(error?.response?.data))
      );
      if (responseError.status_code == 403) {
        toast.info(responseError?.message);
      } else {
        toast.error("An error occurred while uploading the report.");
      }
    }
  };


  sampleUploadReport = async (formData) => {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: getAccessToken(),
    };
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await axios.post(
        `${apiUrl}/api/bulk_sample_download`,
        formData,
        {
          headers,
          responseType: "arraybuffer",
        }
      );

      const contentType = response.headers["content-type"];
      if (contentType.includes("application/json")) {
        const arrayBufferConverted = JSON.parse(
          String.fromCharCode.apply(null, new Uint8Array(response.data))
        );
        console.log(arrayBufferConverted);
        toast.success(arrayBufferConverted?.message);
      } else if (
        contentType.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        toast.info(
          "File imported with errors. Please check downloaded file for failures"
        );
        const blob = new Blob([response.data], {
          type: contentType,
        });
        fileSaver.saveAs(blob, "failures.xlsx");
      } else {
        toast.error("Unexpected response format.");
      }
    } catch (error) {
      const responseError = JSON.parse(
        String.fromCharCode.apply(null, new Uint8Array(error?.response?.data))
      );
      if (responseError.status_code == 403) {
        toast.info(responseError?.message);
      } else {
        toast.error("An error occurred while uploading the report.");
      }
    }
  };


  licenseSearch = async ({ queryKey }) => {
    const [_key, { searchKey }] = queryKey;
    return await axiosInstance.get(`/api/license-search/${searchKey}`);
  };
  licenseSearchFrontend = async ({ queryKey }) => {
    const [_key, { searchKey }] = queryKey;
    return await axiosInstance.get(
      `/api/license?source=frontend&name=${searchKey}&active=1`
    );
  };
  filterMC = async (payload) => {
    return await axiosInstance.post(`/api/municipal_filter`, payload);
  };
  filterCity = async (payload) => {
    return await axiosInstance.post(`/api/city_filter`, payload);
  };
  filterState = async (payload) => {
    return await axiosInstance.post(`/api/state_filter`, payload);
  };
  getAllRQStates = async ({ queryKey }) => {
    const [_key, params] = queryKey;
    return await axiosInstance.get(`/api/get_states`, {
      params: params,
    });
  };

  getAllRQCompanyType = async ({ queryKey }) => {
    const [_key, params] = queryKey;
    return await axiosInstance.get(`/api/license/license-company`, {
      params: params,
    });
  };

  getAllRQIndustry = async ({ queryKey }) => {
    const [_key, params] = queryKey;
    return await axiosInstance.get(`/api/license/license-industry`, {
      params: params,
    });
  };

  getRQLicenseClassification = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    return await axiosInstance.get(`/api/license/license-type`, {
      params: data,
    });
  };
}

const instance = new LicenseMasterServices();

export default instance;
