import React from "react";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
  useMap,
} from "react-leaflet";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";
import { useQueries, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { PendingStatusList } from "../../../utils/utils";
L.Icon.Default.imagePath = "leaflet_images/";
const LocationWiseCount = () => {
  const navigate = useNavigate();
  const { data: locationWiseCount } = useQuery(
    ["api/map/get-locations"], //
    AllianceManagerServices.locationWiseCount,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const results = useQueries([
    {
      queryKey: [`/api/map/get-locations`, 1],
      queryFn: AllianceManagerServices.locationWiseCount,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-status", 2],
      queryFn: AllianceManagerServices.getStatusList,
      staleTime: Infinity,
    },
  ]);

  const mapCenter = [20.5937, 78.9629]; // Center of India
  const icon = L.icon({
    iconRetinaUrl: iconRetina,
    iconUrl: iconMarker,
    shadowUrl: iconShadow,
  });


  return (
    <MapContainer
      center={mapCenter}
      zoom={5}
      style={{ height: "100%", width: "100%" }}
      attributionControl={false}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {locationWiseCount?.data?.map((item, index) => (
        <Marker
          key={index}
          position={[+item?.latitude, +item?.longitude]}
          draggable={false}
          icon={icon}
          eventHandlers={{
            click: (e) => {
              // navigate('/alliance-manager/overall-license-requests', { state:{pin_code:item?.pincode ,status:PendingStatusList(results[1]?.data?.data)}});
              navigate("/alliance-manager/overall-license-requests", {
                state: {
                  pin_code: item?.pincode,
                  card: {
                    title: "Total Requests",
                    cardStatus: "pin_code",
                  },
                },
              });
            },
          }}
        >
          <Tooltip permanent >{item.service_count}</Tooltip>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default LocationWiseCount;
