import React from "react";
import ExecutiveLayout from "../../../../components/layouts/ExecutiveLayout";
import AdminPageContainer from "../../../../components/AdminPageContainer";
import FEListing from "../../../../Containers/Executive/FEListing";
import FieldExecutive from "../../../../Containers/Vendor/FieldExecutive";

const FEListingPage = () => {
  const detailsPageLink = "/executive/field-executives/details/"
  return (
    <ExecutiveLayout>
      <AdminPageContainer>
        {/* <FEListing /> */}
        <FieldExecutive 
        detailsPageLink={detailsPageLink}
        
        />
      </AdminPageContainer>
    </ExecutiveLayout>
  );
};

export default FEListingPage;
