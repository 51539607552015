import React from "react";
import axiosInstance from "../axiosConfig";

class VendorExecutive extends React.Component {
  getExecutives = async (query) => {
    // const [_key, queryParams] = queryKey;
    // vendorIds
    console.log(query)
    return await axiosInstance.get(`/api/executive`, { params: query });
  };

  getRQExecutives = async ({queryKey}) => {
    const [_key, queryParams] = queryKey;
    // vendorIds
    // console.log(query)
    // vendorIds:[466]
    return await axiosInstance.get(`/api/executive`, { params: queryParams });
  };

  searchExecutive = async (data) => {
    return await axiosInstance.get(`/api/executive/search_executive`, {
      params: data,
    });
  };
  // {
  //   name: data?.name,
  //   email: data?.email,
  //   mobile_no: data.mobile_no,
  // }

  exportExecutives = async () => {
    return await axiosInstance.get(`/api/executive/export_executive`);
  };

  getExecutiveById = async (id) => {
    return await axiosInstance.get(`/api/executive/edit/${id}`);
  };

  deactivateExecutive = async (id, payload) => {
    return await axiosInstance.post(
      `/api/executive/update/status/${id}`,
      payload
    );
  };

  createExecutive = async (payload) => {
    return await axiosInstance.post(`/api/executive/add`, payload);
  };
  updateExecutive = async (id, payload) => {
    return await axiosInstance.post(`/api/executive/update/${id}`, payload);
  };
  filterExecutive = async (data) => {
    return await axiosInstance.get(`/api/executive`, {
      params: data,
    });
  };
}

const instance = new VendorExecutive();

export default instance;
