import React, { createContext, useState } from "react";
import "./locationSettings.css";
import LocationTabs from "./locationTabs/index.jsx";
import AddMC from "./Modals/AddMC";
import AddZone from "./Modals/AddZone";
import AddCity from "./Modals/AddCity";
import AdminLayout from "../../../components/layouts/adminLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import AddState from "./Modals/AddState";
import { useNavigate } from "react-router";
export const LocationSettingsContext = createContext(null);
export const spl_format = /^[A-Za-z\s]*$/;
export default function LocationSettings() {
  const [show, setShow] = useState(null);
  const navigate = useNavigate();
  return (
    <AdminLayout test2="active">
      <AdminPageContainer>
        <LocationSettingsContext.Provider value={{ show, setShow }}>
          <div id="main" role="main">
            <div id="content">
              <div>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item ">
                    <a
                      style={{
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/system-admin/license-master")}
                      onKeyDown={() => navigate("/system-admin/license-master")}
                    >
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Location Settings</li>
                </ol>
              </div>
              {/* tabs */}
              <LocationTabs />
            </div>
          </div>
        </LocationSettingsContext.Provider>
      </AdminPageContainer>
    </AdminLayout>
  );
}
