import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import VendorLayout from "../../../components/layouts/VendorLayout";
import { useState } from "react";
import Pagination from "react-js-pagination";
import VendorExecutiveServices from "../../../services/API/VendorExecutive";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import FilterExecutive from "./FilterExecutive";
import useAuth from "../../../hooks/useAuth";
import format from "date-fns/format";
import { addDays } from "date-fns";

const initial = {
  name: "",
  email: "",
  address: "",
  mobile_no: "",
  is_enabled: true,
  dueDateRange: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ],
  dateSubmit: false,
};
export default function Executive() {
  const [page, setPage] = useState(1);
  const [executiveList, setExecutiveList] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchKey] = useState(null);
  const [exported, setExported] = useState([]);
  const [del, setDel] = useState(null);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filteredData, setfilteredData] = useState(initial);
  const [showDuedateRange, setShowDuedateRange] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const limit = 20;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setfilteredData((filteredData) => ({ ...filteredData, [name]: value }));
  };
  const fetchExecutive = async () => {
    setLoading(true);
    const response = await VendorExecutiveServices.getExecutives({
      page,
      limit,
    });
    if (response) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      console.log(response);

      setExecutiveList(response?.data);
      setDataToShow(response?.data?.data);
    }
    setLoading(false);
  };

  const fetchSearchExecutive = async () => {
    setLoading(true);
    let query = { q: searchkey, limit: limit, page: page };
    const response = await VendorExecutiveServices.searchExecutive(query);
    if (response) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      console.log(response);

      setExecutiveList(response?.data);
      setDataToShow(response?.data?.data);
    }
    setLoading(false);
  };

  const fetchFilterExecutive = async () => {
    setPage(1);
    handleFilter();
  };

  const handleFilter = async () => {
    setLoading(true);
    setFilterStatus(true);

    const toBefiltered = {
      ...filteredData,
      ["dueDateRange"]: `${format(
        filteredData.dueDateRange[0].startDate,
        "dd-MM-yyyy"
      )} to ${format(filteredData.dueDateRange[0].endDate, "dd-MM-yyyy")}`,
    };
    let from_created_on = toBefiltered.dueDateRange
      .split("to")[0]
      ?.trim()
      .split("-")
      .reverse()
      .join("-");
    let to_created_on = toBefiltered.dueDateRange
      .split("to")[1]
      ?.trim()
      .split("-")
      .reverse()
      .join("-");

    const toFilter = {
      is_enabled: filteredData?.is_enabled ? 1 : 0,
      page,
      limit,
    };
    if (toBefiltered.name.length > 0) toFilter.name = toBefiltered.name;
    if (toBefiltered.email.length > 0) toFilter.email = toBefiltered.email;
    if (toBefiltered.mobile_no.length > 0)
      toFilter.mobile_no = toBefiltered.mobile_no;
    if (toBefiltered.dateSubmit) {
      toFilter.from_created_on = from_created_on;
      toFilter.to_created_on = to_created_on;
    }

    const response = await VendorExecutiveServices.filterExecutive(toFilter);
    if (response) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setExecutiveList(response?.data);
      setDataToShow(response?.data?.data);
    }
    setLoading(false);
  };

  const handleSearch = async (e) => {
    const value = e.target.value.trim();
    setSearchKey(e.target.value);
    if (value) {
      setLoading(true);
      setFilterStatus(true);
      let query = { q: value, limit: limit, page: page };
      const response = await VendorExecutiveServices.searchExecutive(query);
      if (response) {
        console.log(response);
        setExecutiveList(response?.data);
        setDataToShow(response?.data?.data);
      }
      setLoading(false);
      setPage(1);
    }
  };

  const clearFilter = () => {
    if (page == 1) {
      fetchExecutive();
    }
    setSearchKey(null);
    setDisplaySearchField(false);
    setFilterStatus(null);
    setfilteredData(initial);
    setShowDuedateRange(false);
    setPage(1);
  };

  useEffect(() => {
    if (searchkey) {
      fetchSearchExecutive();
    } else if (!searchkey && !filterStatus) {
      fetchExecutive();
    } else if (!searchkey && filterStatus) {
      handleFilter();
    }
  }, [page, searchkey]);

  useEffect(() => {
    getExported();
  }, [executiveList]);

  const headers = [
    { label: "S. No.", key: "id" },
    { label: "Name", key: "name" },
    { label: "Mobile Number", key: "mobile_no" },
    { label: "Email ID", key: "email" },
    { label: "Created On", key: "created_at" },
    { label: "Status", key: "is_enabled" },
  ];

  const getExported = async () => {
    const response = await VendorExecutiveServices.exportExecutives();

    if (response) {
      setExported(response?.data);
    }
  };

  const handleDelete = async (exec) => {
    const status = exec?.is_enabled === 1 ? false : true;
    try {
      const response = await VendorExecutiveServices.deactivateExecutive(
        exec?.id,
        {
          status,
        }
      );
      if (response) {
        const message =
          exec?.is_enabled === 1
            ? "Executive Deactivated Successfully"
            : "Executive Activated Successfully";
        toast.success(message);
        setDel(null);
        if(searchkey){
          fetchSearchExecutive();
        }else if(filteredData?.name ||
          filteredData?.email ||
          filteredData?.mobile_no ||
          filterStatus === true ||
          showDuedateRange ){
          handleFilter();
        }else{
            fetchExecutive();
          }
      }
    } catch (er) {
      toast.error(er);
    }
  };

  return (
    <VendorLayout>
      <div id="main" role="main">
        <div id="content">
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item ">
                <Link to={"/vendor/dashboard"}>Home</Link>
              </li>
              <li className="breadcrumb-item active">Executives Lists</li>
            </ol>
          </div>
          <div>
            <div className="row">
              <div className="">
                <div class="card card-sm card-shadow ">
                  <div class="card-body">
                    <div className=" d-flex justify-content-between align-items-center">
                      <h2>Executives Lists</h2>
                      <div className="d-flex justify-content-end align-items-center gap-2 pt-4">
                        <Link
                          to={"/vendor/out-of-office"} 
                          className="button_new pull-right cursor-pointer"
                          // style={{ marginRight: "135px" }}
                        >
                          Out of Office
                        </Link>

                        {(filteredData?.name ||
                          filteredData?.email ||
                          filteredData?.mobile_no ||
                          filterStatus === true ||
                          showDuedateRange ||
                          searchkey) && (
                          <button
                            type="button"
                            className="button_new pull-right cursor-pointer"
                            onClick={clearFilter}
                          >
                            Clear Filters
                          </button>
                        )}
                        {displaySearchField ? (
                          <input
                            type="text"
                            className="button_new pull-right"
                            value={searchkey}
                            onChange={handleSearch}
                            // disabled={workflow === "expert"}
                            placeholder="Search"
                          />
                        ) : (
                          <a
                            className=" button_new pull-right cursor-pointer"
                            onClick={() => setDisplaySearchField(true)}
                            onKeyDown={() => setDisplaySearchField(true)}
                          >
                            <i class="fa fa-search" aria-hidden="true"></i>
                          </a>
                        )}
                        <a
                          className=" button_new pull-right cursor-pointer"
                          onClick={() => setFilterStatus("filter")}
                          onKeyDown={() => setFilterStatus("filter")}
                        >
                          <i
                            className="fa fa-filter fa-fw"
                            title="Show Filters"
                          ></i>
                        </a>
                        <CSVLink
                          className=" button_new pull-right"
                          title="Download"
                          filename={"Executives-Lists.csv"}
                          headers={headers}
                          data={exported}
                          onClick={() => {
                            if (exported)
                              toast.success(
                                "Executives Downloaded Successfully"
                              );
                          }}
                        >
                          <i className="fa fa-download fa-fw"></i>
                        </CSVLink>

                        <a
                          className=" button_new pull-right cursor-pointer"
                          onClick={() => navigate("/vendor/executive/create")}
                          onKeyDown={() => navigate("/vendor/executive/create")}
                        >
                          <i
                            className="fa fa-plus fa-fw"
                            title="Add Executive"
                          ></i>
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="table-responsive">
                        <table
                          className="table table-bordered table-hover dataTable no-footer"
                          style={{ tableLayout: "fixed" }}
                        >
                          <thead>
                            <tr role="row" style={{ fontWeight: 700 }}>
                              <th
                                width="34px"
                                className="text-center align-middle"
                              >
                                S. No.
                              </th>
                              <th className="text-center align-middle">Name</th>
                              <th className="text-center align-middle">
                                Mobile Number
                              </th>
                              <th className="text-center align-middle">
                                Email ID
                              </th>
                              {/* <th
                              className="text-center align-middle"
                              width="13%"
                            >
                              Status
                            </th> */}
                              <th className="text-center align-middle">
                                Created On
                              </th>
                              <th
                                width="50px"
                                className="text-center align-middle"
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          {dataToShow.length !== 0 ? (
                            <tbody>
                              {dataToShow?.map((exec, index) => (
                                <tr>
                                  <td className="text-start align-middle">
                                    {limit * (page - 1) + index + 1}
                                  </td>
                                  <td className="text-start align-middle">
                                    <Link to={`/vendor/executive/${exec?.id}`}>
                                      {exec?.name}
                                    </Link>
                                  </td>
                                  <td className="text-start align-middle">
                                    {exec?.mobile_no?.replace("+91", "")}
                                  </td>
                                  <td className="text-start align-middle">
                                    {exec?.email}
                                  </td>

                                  <td className="text-start align-middle">
                                    {exec?.created_on || exec?.created_at
                                      ? moment(
                                          exec?.created_on || exec?.created_at
                                        ).format("DD-MM-YY hh:mm A")
                                      : "--"}
                                  </td>
                                  <td>
                                    <span
                                      onClick={() =>
                                        navigate(
                                          `/vendor/executive/edit/${exec?.id}`
                                        )
                                      }
                                      onKeyDown={() =>
                                        navigate(
                                          `/vendor/executive/edit/${exec?.id}`
                                        )
                                      }
                                      className="cursor-pointer"
                                      title="Edit"
                                    >
                                      <i className="fa fa-edit fa-fw"></i>
                                    </span>

                                    {exec?.is_enabled === 1 ? (
                                      <i
                                        className="fa fa-circle fa-fw txt-success pe-auto"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        title="Deactivate"
                                        onClick={() => setDel(exec)}
                                        onKeyDown={() => setDel(exec)}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-circle fa-fw txt-fail"
                                        title="Activate"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => setDel(exec)}
                                        onKeyDown={() => setDel(exec)}
                                      ></i>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <tr>
                              <td
                                colSpan={6}
                                className="p-2 text-center align-middle"
                              >
                                {loading ? "Loading..." : "No data found"}
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                      <div className="d-flex justify-content-end mr-2">
                        {executiveList.total > limit && (
                          <Pagination
                            activePage={executiveList?.current_page}
                            itemsCountPerPage={executiveList?.per_page}
                            totalItemsCount={executiveList?.total}
                            pageRangeDisplayed={5}
                            onChange={(number) => setPage(number)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={!!del}
        onHide={() => setDel(null)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to {del?.is_enabled === 1 ? "deactivate " : "activate "}
            this executive?
          </p>
          <div className="d-flex gap-3 justify-content-end">
            <Button
              variant="danger"
              className="px-3 py-2"
              onClick={() => setDel(null)}
            >
              No
            </Button>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={() => handleDelete(del)}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <FilterExecutive
        filterData={filteredData}
        setFilterData={setfilteredData}
        handleChange={handleChange}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        handleFilter={fetchFilterExecutive}
        showDuedateRange={showDuedateRange}
        setShowDuedateRange={setShowDuedateRange}
      />
    </VendorLayout>
  );
}
