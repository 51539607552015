import React, { useState, ChangeEvent, useEffect } from "react";

//------------------css import -------------------
import "../../styles/alliance-manager/licenseRequestDetails.css";
//--------------------bootstrap------------------
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
//--------------------api handler------------------
import AllianceManagerServices from "../../services/API/AllianceManager";

//------------------Third Party library-----------------------
import { ToastContainer, toast, useToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { htmlToText } from "html-to-text";
import validator from "validator";
import { Button, Modal } from "react-bootstrap";

// import DatePicker from "react-datetime";
import "react-datetime/css/react-datetime.css";

import {
  BtnBold,
  BtnItalic,
  createButton,
  EditorProvider,
} from "react-simple-wysiwyg";
import Editor from "react-simple-wysiwyg";
import moment from "moment";

//------------------Components-----------------------
import AdminLayout from "../../components/layouts/adminLayout";
import { Link, useParams } from "react-router-dom";
import SrnActivityLogs from "../../components/AllianceManager/LicenseRequestDetails/SrnActivityLogs";
import DocumentList from "../../components/AllianceManager/LicenseRequestDetails/DocumentList";
import UpdateStatus from "../../components/AllianceManager/LicenseRequestDetails/UpdateStatus";
import CourierDetails from "../../components/AllianceManager/LicenseRequestDetails/CourierDetails";
import PaymentDetails from "../../components/AllianceManager/LicenseRequestDetails/PaymentDetails";
import AliianceManagerLayout from "../../components/layouts/AliianceManagerLayout";
import Loader from "../../components/Loader";
import FullScreenLoader from "../../components/FullScreenLoader";
import { formatMobileNumber } from "../../utils/utils";
import HistoryModal from "../../components/common/HistoryModal";

const LicenseRequestDetails = () => {
  let { license_id } = useParams();
  const initialLicenseStatus = {
    srn_id: license_id,
    status: "",
    sub_status: "",
    renewal_date: null,
    is_valid: 0,
    comment: "",
    document: "",
    documentType: "",
  };
  const initialCourierDetails = {
    srn_id: license_id,
    courier_name: "",
    consignment_number: "",
    comment: "",
    document: "",
    documentType: "",
  };
  const initialPaymentDetails = {
    srn_id: license_id,
    amount: "",
    payment_note: "",
    document: "",
    documentType: "",
  };
  const [key, setKey] = useState(1);
  const [documentStatus, setDocumentStatus] = useState({
    certificateOfIncorporate: true,
    PAN: true,
    articlesOfAssociation: true,
    memorandum: true,
  });
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [details, setDetails] = useState();
  const [vendorList, setVendorList] = useState([]);
  const [srnStatues, setSrnStatues] = useState({ status: "", sub_status: "" });
  const [srnActivity, setSrnActivity] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const [licenseStatus, setLicenseStatus] = useState(initialLicenseStatus);
  const [flag, setFlag] = useState(false);
  const [licenseStatusErrors, setLicenseStatusErrors] = useState({});
  const [courierDetails, setCourierDetails] = useState(initialCourierDetails);
  const [courierDetailsErrors, setCourierDetailsErrors] = useState({});

  const [paymentDetails, setPaymentDetails] = useState(initialPaymentDetails);
  const [paymentDetailsErrors, setPaymentDetailsErrors] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const [assignedTo, setAssignedTo] = useState({
    show: false,
    id: null,
    name: "",
  });
  const [loader, setLoader] = useState("");

  // const [getFormData, setGetFormData] = useState({});

  useEffect(() => {
    licenseRequestDetails();
  }, []);

  const licenseRequestDetails = async () => {
    // setInitialLoading(true);
    setLoader("initial");
    try {
      const results = await Promise.all([
        AllianceManagerServices.licenseRequestDetails(license_id),
        AllianceManagerServices.srnActivityLogs(license_id),
      ]);
      const vendors = await AllianceManagerServices.getVendorList();

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      if (results[0]?.status == "success") {
        setDetails(results[0].data[0]);
        setSrnStatues({
          status: results[0]?.data[0]?.get_srn_statustes?.status,
          sub_status: results[0]?.data[0]?.get_srn_statustes?.sub_status,
        });
      }

      if (results[1]?.status == "success") {
        setSrnActivity(results[1].data);
      }
      // if (results[1]) {
      //   setSrnStatues({
      //     status: results[1]?.data[0][results[1]?.data[0]?.length - 1]?.status,
      //     sub_status:
      //       results[1]?.data[0][results[1]?.data[0]?.length - 1]?.sub_status,
      //   });
      // }
     ;
      if (vendors.status == "success") {
        let licenseId = results[0]?.data[0]?.get_license?.id;

        setVendorList(
          vendors?.data?.filter((item) => {
            if (
              item?.status === "1" &&
              item?.license_names?.filter((fitem) => fitem?.id == licenseId)
                ?.length
            ) {
              return item;
            }
          })
        );
      }

      // setInitialLoading(false);
    } catch (error) {
      toast.error("No data found.");
      // setInitialLoading(false);
    } finally {
      setLoader("");
    }
  };
  const updateStatus = async () => {
    const fieldsError = validateLicenseStatusFields(licenseStatus);
    if (Object.keys(fieldsError).length > 0) {
      setLicenseStatusErrors(fieldsError);
    } else {
      // setLoading(true);
      setLoader("update srn status");
      setLicenseStatusErrors({});
      const formData = new FormData();
      formData.append("document", licenseStatus.document);

      if (
        licenseStatus?.is_valid !== 1 &&
        (licenseStatus?.renewal_date ||
          details?.get_srn_statustes?.renewal_date)
      ) {
        formData.append(
          "renewal_date",
          moment(
            details?.get_srn_statustes?.renewal_date ||
              licenseStatus.renewal_date
          ).format("YYYY-MM-DD HH:mm:ss")
        );
      }

      for (let i in licenseStatus) {
        if (i !== "document" && i !== "renewal_date" && i !== "documentType")
          formData.append(i, licenseStatus[i]);
      }

      if (details?.get_mapped_licenses?.validity == "lifetime") {
        formData.delete("is_valid");
        formData.append("is_valid", 1);
      }

      try {
        const res = await AllianceManagerServices.updateSrnStatus(formData);
        // const res = ""
        if (res?.status == "success") {
          setFlag(false);
          toast.success("Status Updated.");
          licenseRequestDetails();
          setSubStatusList([]);
          // const srnActivityResponse = await AllianceManagerServices.srnActivityLogs(license_id);
          // if (srnActivityResponse?.status == "success") {
          //   setSrnActivity(srnActivityResponse.data);
          // }
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setLicenseStatus(initialLicenseStatus);
        setLoader("");
      }
    }
  };
  const validateLicenseStatusFields = (values) => {
    const errors = {};
    if (!values.status) {
      errors.status = "Please select a status";
    }
    if (subStatusList?.length && !licenseStatus?.sub_status) {
      errors.sub_status = "Please select sub status";
    }
    // if (!values.sub_status) {
    //   errors.sub_status = "Sub-Status Required!";
    // }
    // if (!values.renewal_date && values.is_valid !== 1) {
    //   errors.renewal_date = "Renewal Date Required!";
    // }

    if (details?.get_mapped_licenses?.validity != "lifetime") {
      if (
        (values?.status === "Closed" || values?.status === "Completed") &&
        values.is_valid != "1" &&
        !details?.get_srn_statustes?.is_valid != "1" &&
        !values.renewal_date &&
        !details?.get_srn_statustes?.renewal_date
      ) {
        errors.renewal_date = "Please enter renewal date";
      }

      if (values.renewal_date) {
        let date = moment(values.renewal_date)
          .format("YYYY-MM-DD HH:mm:ss")
          .split(" ")[0];
        let time = moment(values.renewal_date)
          .format("YYYY-MM-DD HH:mm:ss")
          .split(" ")[1];
        if (
          validator.isDate(date) &&
          date <= moment(new Date()).format("YYYY-MM-DD")
        ) {
          errors.renewal_date = "Please select valid date from the calendar";
        } else {
          if (time === "date") {
            errors.renewal_date = "Please select valid date from the calendar";
          }
        }
      }
    }

    if (
      (!values.document && licenseStatus?.status === "Closed") ||
      (!values.document && licenseStatus?.status === "Completed")
    ) {
      errors.document = "Please upload the license document";
    }

    if (values.comment) {
      // errors.comment = "Comment Required!";
      let toCheckCommentLength = htmlToText(values.comment, {
        wordwrap: 130,
      });
      if (toCheckCommentLength.length >= 500) {
        errors.comment = "Comment should be less than 500 character.";
      }
    }
    if (values.document) {
      if (
        !["jpeg", "jpg", "pdf", "png"].includes(
          values?.documentType.toLowerCase()
        )
      ) {
        setLicenseStatus({ ...licenseStatus, document: "" });
        errors.document =
          "The document must be a file of type jpeg, jpg, pdf, png";
      }
    }

    return errors;
  };
  const saveCourierDetails = async () => {
    const fieldsError = validateCourierDetailsFields(courierDetails);
    if (Object.keys(fieldsError).length > 0) {
      setCourierDetailsErrors(fieldsError);
    } else {
      setLoader("update srn courier");
      setCourierDetailsErrors({});
      const formData = new FormData();
      formData.append("document", courierDetails.document);
      for (let i in courierDetails) {
        if (i !== "document" && i !== "documentType")
          formData.append(i, courierDetails[i]);
      }

      try {
        const res = await AllianceManagerServices.updateSrnCourier(formData);
        if (res?.status == "success") {
          toast.success("Courier details saved successfully.");
          const srnActivityResponse =
            await AllianceManagerServices.srnActivityLogs(license_id);
          if (srnActivityResponse?.status == "success") {
            setSrnActivity(srnActivityResponse.data);
          }
        } else {
          toast.error(res.message);
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setCourierDetails(initialCourierDetails);
        setLoader("");
      }
    }
  };
  const validateCourierDetailsFields = (values) => {
    const errors = {};

    if (!values.courier_name) {
      errors.courier_name = "Please enter courier name";
    } else {
      if (values.courier_name.length >= 500) {
        errors.comment = "Courier name should be less than 500 character.";
      }
    }

    if (!values.consignment_number) {
      errors.consignment_number = "Please enter consignment number";
    } else {
      if (toString(values.consignment_number).length >= 500) {
        errors.comment =
          "Consignment number should be less than 500 character.";
      }
    }
    if (!values.comment) {
      errors.comment = "Please enter comment";
    }

    if (!values.document) {
      errors.document = "Document required";
    }

    if (
      values.document &&
      !["jpeg", "jpg", "pdf", "png"].includes(
        values?.documentType.toLowerCase()
      )
    ) {
      setCourierDetails({ ...courierDetails, document: "" });
      errors.document =
        "The document must be a file of type jpeg, jpg, pdf, png";
    }

    return errors;
  };

  const savePaymentDetails = async () => {
    const fieldsError = validatePaymentDetailsFields(paymentDetails);
    if (Object.keys(fieldsError).length > 0) {
      setPaymentDetailsErrors(fieldsError);
    } else {
      setLoader("update srn payment");
      setPaymentDetailsErrors({});
      const formData = new FormData();
      formData.append("document", paymentDetails.document);
      for (let i in paymentDetails) {
        if (i !== "document") formData.append(i, paymentDetails[i]);
      }

      try {
        const res = await AllianceManagerServices.updateSrnPaymentDetails(
          formData
        );
        if (res.status == "success") {
          toast.success("Payment details saved successfully.");
          const srnActivityResponse =
            await AllianceManagerServices.srnActivityLogs(license_id);
          if (srnActivityResponse?.status == "success") {
            setSrnActivity(srnActivityResponse.data);
          }
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setPaymentDetails(initialPaymentDetails);
        setLoader("");
      }
    }
  };
  const validatePaymentDetailsFields = (values) => {
    const errors = {};

    if (!values.amount) {
      errors.amount = "Please enter amount";
    }
    // if(values.amount && values.amount < 1) {
    //   errors.amount = "Amount should be greater than 0";
    // }
    if (values.amount && values.amount.toString().length > 10) {
      errors.amount = "Amount should be less than 10 digit.";
    }

    if (values.payment_note) {
      if (values.payment_note.length >= 500) {
        errors.comment = "Note should be less than 500 character.";
      }
    }

    if (!values.document) {
      errors.document = "Document required";
    }
    if (
      !["jpeg", "jpg", "pdf", "png"].includes(
        values?.documentType.toLowerCase()
      )
    ) {
      setPaymentDetails({ ...paymentDetails, document: "" });
      errors.document =
        "The document must be a file of type jpeg, jpg, pdf, png";
    }
    return errors;
  };

  const reassignToVendor = async (vendorId, name) => {
    setLoader("reassign");
    try {
      const response = await AllianceManagerServices.srnReassignToVendor({
        service_ids: [license_id],
        vendorId,
      });
      if (response?.status == "success") {
        const detailsResponse =
          await AllianceManagerServices.licenseRequestDetails(license_id);
        if (detailsResponse?.status == "success")
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setDetails(detailsResponse.data[0]);
        toast.success(`Reassigned to ${name} Successfully`);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoader("");
    }
  };
  return (
    <AliianceManagerLayout test="active">
      {/* <!-- MAIN PANEL --> */}
      <div id="main" role="main">
        {/* <!-- MAIN CONTENT --> */}
        {loader === "update srn status" ? (
          <FullScreenLoader
            show={loader === "update srn status"}
            title="updating status"
          />
        ) : loader === "update srn courier" ? (
          <FullScreenLoader
            show={loader === "update srn courier"}
            title="updating courier details"
          />
        ) : loader === "update srn payment" ? (
          <FullScreenLoader
            show={loader === "update srn payment"}
            title="updating payment details"
          />
        ) : loader === "reassign" ? (
          <FullScreenLoader
            show={loader === "reassign"}
            title="reassigning to vendor"
          />
        ) : loader === "initial" ? (
          <FullScreenLoader show={loader === "initial"} title="" />
        ) : (
          ""
        )}
        <Modal
          show={assignedTo.show}
          onHide={() => setAssignedTo({ show: false, id: null, name: "" })}
          animation={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to reassign this to {assignedTo?.name}?</p>
            <div className="d-flex gap-3 justify-content-end">
              <Button
                variant="danger"
                className="px-3 py-2"
                onClick={() => setAssignedTo({ show: false, id: null })}
                disabled={loader === "reassign"}
              >
                No
              </Button>
              <Button
                variant="success"
                className="px-3 py-2"
                onClick={() => {
                  reassignToVendor(assignedTo.id, assignedTo?.name);
                  setAssignedTo({ show: false, id: null, name: "" });
                }}
                disabled={loader === "reassign"}
              >
                {/* {loader === "update" ? (
                  <div className="d-flex align-items-center gap-2">
                    <Loader /> loading...
                  </div>
                ) : ( */}
                Yes
                {/* )} */}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {showHistory ? (
          <HistoryModal
            show={showHistory}
            handleHide={() => setShowHistory(false)}
            service_id={license_id}
          />
        ) : (
          ""
        )}

        <div id="content">
          <div>
            <ol class="breadcrumb margin-left-5">
              <li>
                <Link to={"/alliance-manager/dashboard"}>Home</Link>
              </li>
              &nbsp;&nbsp;<span>/</span>&nbsp;&nbsp;
              <li>
                <Link to={"/alliance-manager/overall-license-requests"}>
                  License Requests
                </Link>
              </li>
              &nbsp;&nbsp;<span>/</span>&nbsp;&nbsp;
              <li>License Request Details</li>
            </ol>
          </div>

          <div class="row">
            {/* <td dangerouslySetInnerHTML={{__html: html}} /> */}

            <div>
              <div className="card card-sm card-shadow mb-3">
                {/* {initialLoading ? (
                  <div
                    style={{
                      paddingLeft: "0px",
                      width: "100%",
                      height: "100%",
                      background: "red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      zIndex: "999",
                    }}
                  >
                    <div className="text-center" style={{ position: "fixed" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : ( */}
                <div className="card-body">
                  {/* <ToastContainer /> */}
                  <span
                    className="pull-right mb-3 "
                    style={{ marginRight: "5px" }}
                  >
                    <button
                      onClick={() => setShowHistory(true)}
                      className="button_new mt-3"
                      type="button"
                      // id="dropdownMenuButton1"
                      // data-bs-toggle="dropdown"
                      // aria-expanded="false"
                      style={{
                        border: "1px solid #fec02c",
                        color: "#270640",
                        borderRadius: "10px",
                        background: "white",
                        fontSize: "12px",
                        padding: "2px 10px",
                      }}
                    >
                      <span>Service History&nbsp;&nbsp;</span>
                    </button>
                  </span>
                  {details &&
                    details?.get_srn_statustes?.status?.toLowerCase() !=
                      "completed" &&
                    details?.get_srn_statustes?.status?.toLowerCase() !=
                      "closed" && (
                      <span
                        className="pull-right mb-3 "
                        style={{ marginRight: "5px" }}
                      >
                        <div className="dropdown mt-3">
                          <button
                            // className="button_new "
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              border: "1px solid #fec02c",
                              color: "#270640",
                              borderRadius: "10px",
                              background: "white",
                              fontSize: "12px",
                              padding: "2px 10px",
                            }}
                          >
                            <span>Reassign to vendor&nbsp;&nbsp;</span>
                            <i
                              className="fa-solid fa-sort-down"
                              style={{ fontSize: "15px", marginBottom: "4px" }}
                            ></i>
                          </button>
                          <ul
                            className="dropdown-menu button_new dropdown-lisst"
                            aria-labelledby="dropdownMenuButton1"
                            style={{ borderRadius: "2px" }}
                          >
                            {vendorList?.length ? (
                              vendorList?.map((vendor, key) => (
                                <li
                                  className="dropdown-item"
                                  key={key}
                                  onClick={() =>
                                    setAssignedTo({
                                      show: true,
                                      id: vendor.id,
                                      name: vendor?.name,
                                    })
                                  }
                                  onKeyDown={() =>
                                    setAssignedTo({
                                      show: true,
                                      id: vendor.id,
                                      name: vendor?.name,
                                    })
                                  }
                                  style={{
                                    cursor: "pointer",
                                    wordWrap: "break-word",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  {vendor.name}
                                </li>
                              ))
                            ) : (
                              <li
                                className="dropdown-item"
                                key={key}
                                style={{
                                  cursor: "pointer",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                No options
                              </li>
                            )}
                          </ul>
                        </div>
                      </span>
                    )}

                  <h3>License Request Details</h3>
                  <div className="mb-3">
                    <span className=" font-md srn_btn ">
                      Service Request Number (SRN):
                      <span style={{ color: "#fec02c" }}>
                        <strong>{details?.srn_name || "--"}</strong>
                      </span>
                    </span>
                  </div>

                  <div className="row">
                    <article className="col-lg-6  mb-3">
                      <div
                        className="card card-sm card-shadow mb-3"
                        style={{ height: "100%" }}
                      >
                        <div className="card-body">
                         

                          {details?.get_license?.name || "--"}
                          <div className="padding-top-10">
                            {details?.get_license_type?.name || "--"} |{" "}
                            {/*S&ERenew |*/} {details?.get_city?.name || "--"} |{" "}
                            {details?.get_state?.name || "--"}
                          </div>
                          <div className=" padding-top-10">
                            <span className="note">Requested On: </span>
                            {details?.created_at
                              ? moment(details.created_at).format("DD-MM-YY")
                              : "--"}
                          </div>
                          <div className=" padding-top-10">
                            <span className="note">Current Status: </span>
                            <span style={{ color: "#429fe9" }}>
                              {details?.get_srn_statustes?.status || "--"}
                            </span>
                          </div>
                          <div className=" padding-top-10">
                            <span className="note">Assigned To: </span>
                            {details?.get_executive?.name && (
                              <>
                                <i
                                  className="fa fa-envelope-square font-md"
                                  style={{ color: "#fec02c" }}
                                  title={`Email : ${details?.get_executive?.email}`}
                                ></i>{" "}
                                {"  "}
                                <i
                                  className="fa fa-phone-square font-md"
                                  style={{ color: "#fec02c" }}
                                  title={`Mobile Number : ${details?.get_executive?.mobile_no}`}
                                ></i>
                                <span
                                  style={{ color: "#000", paddingLeft: "5px" }}
                                >
                                  {details?.get_executive?.name}
                                </span>
                              </>
                            )}
                            {details?.get_field_executive?.name &&
                              details?.get_executive?.name &&
                              " , "}
                            {details?.get_field_executive?.name && (
                              <>
                                <i
                                  className="fa fa-envelope-square font-md"
                                  style={{ color: "#fec02c" }}
                                  title={`Email : ${details?.get_field_executive?.email}`}
                                ></i>{" "}
                                {"  "}
                                <i
                                  className="fa fa-phone-square font-md"
                                  style={{ color: "#fec02c" }}
                                  title={`Mobile Number : ${details?.get_field_executive?.mobile_no}`}
                                ></i>
                                <span
                                  style={{ color: "#000", paddingLeft: "5px" }}
                                >
                                  {details?.get_field_executive?.name}
                                </span>
                              </>
                            )}

                            
                          </div>
                          <div className=" padding-top-10">
                            <span className="note">Industry Type: </span>

                            {details?.get_industry?.name || "--"}
                          </div>
                          <div className=" padding-top-10">
                            <span className="note">Company Type: </span>

                            {details?.get_company_type?.name || "--"}
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-lg-6  mb-3">
                      <div
                        className="card card-sm card-shadow"
                        style={{ height: "100%" }}
                      >
                        <div className="card-body">
                          <i
                            className="fa fa-building fa-fw"
                            aria-hidden="true"
                            style={{ color: "#64308e" }}
                            title="Company Name"
                          ></i>
                          {details?.company_name || "--"}
                          <div className="padding-top-10">
                            <i
                              className="fa fa-map-marker-alt fa-fw"
                              aria-hidden="true"
                              style={{ color: "#64308e" }}
                              title="Company Address"
                            ></i>{" "}
                            {details?.company_address || "--"}
                          </div>
                          <div className=" padding-top-10">
                            <i
                              className="fa fa-user fa-fw"
                              aria-hidden="true"
                              style={{ color: "#64308e" }}
                              title="Requested By"
                            ></i>
                            <span className="note">Requested By: </span>

                            {details?.get_user?.name || "--"}
                          </div>
                          <div className=" padding-top-10">
                            <i
                              className="fa fa-envelope fa-fw"
                              aria-hidden="true"
                              style={{ color: "#64308e" }}
                              title="Email Id"
                            ></i>
                            <span className="note">Email: </span>
                            {details?.get_user?.email || "--"}
                          </div>
                          <div className=" padding-top-10">
                            <i
                              className="fa fa-phone fa-fw"
                              aria-hidden="true"
                              style={{ color: "#64308e" }}
                              title="Mobile Number"
                            ></i>
                            <span className="note">Mobile Number: </span>
                            {details?.get_user?.mobile_no
                              ? formatMobileNumber(details?.get_user?.mobile_no)
                              : "--"}
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>

                  <DocumentList
                    documentStatus={documentStatus}
                    setDocumentStatus={setDocumentStatus}
                    srnStatues={srnStatues}
                    licenseRequestDetails={licenseRequestDetails}
                  />

                  <div className="">
                    <div className="card card-sm card-shadow">
                      <div className="card-body">
                        <h3 className="heading_light">
                          <i className="fa-regular fa-comments"></i>&nbsp;
                          Activity Logs
                        </h3>
                        <div className="row">
                          {srnActivity?.length > 0 ? (
                            <SrnActivityLogs srnActivity={srnActivity} />
                          ) : (
                            <article
                              className="col-lg-6  mb-3"
                              style={{
                                maxHeight: "520px",
                                overflowY: "scroll",
                              }}
                            >
                              <div className="card card-sm card-shadow mb	-3">
                                <div
                                  className="card-body"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <text>Currently no logs available...</text>
                                </div>
                              </div>
                            </article>
                          )}

                          <article className="col-lg-6  mb-3">
                            <div
                              className="card card-sm card-shadow"
                              style={{ height: "auto" }}
                            >
                              <div className="card-body smart-form">
                                <ul
                                  id="myTab1"
                                  className="nav nav-tabs bordered"
                                >
                                  <li
                                    className={key == 1 ? "active" : ""}
                                    style={{
                                      width: "33.3%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setKey(1)}
                                    onKeyDown={() => setKey(1)}
                                  >
                                    <a data-toggle="tab">
                                      <i className="fa fa-fw fa-lg fa-gear"></i>{" "}
                                      Update Status
                                    </a>
                                  </li>
                                  <li
                                    className={key == 2 ? "active" : ""}
                                    style={{
                                      width: "33.3%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setKey(2)}
                                    onKeyDown={() => setKey(2)}
                                  >
                                    <a data-toggle="tab">
                                      <i className="fa fa-fw fa-lg fa-envelope"></i>{" "}
                                      Courier Details
                                    </a>
                                  </li>
                                  <li
                                    className={key == 3 ? "active" : ""}
                                    style={{
                                      width: "33.4%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setKey(3)}
                                    onKeyDown={() => setKey(3)}
                                  >
                                    <a data-toggle="tab">
                                      <i className="fa fa-fw fa-lg fa-database"></i>{" "}
                                      Payment Details
                                    </a>
                                  </li>
                                </ul>
                                <div
                                  id="myTabContent1"
                                  className="tab-content padding-10"
                                >
                                  {key == 1 ? (
                                    <UpdateStatus
                                      licenseStatus={licenseStatus}
                                      setLicenseStatus={setLicenseStatus}
                                      licenseStatusErrors={licenseStatusErrors}
                                      srnStatues={srnStatues}
                                      srnActivity={srnActivity}
                                      updateStatus={updateStatus}
                                      setFlag={setFlag}
                                      flag={flag}
                                      details={details}
                                      setCourierDetails={setCourierDetails}
                                      setPaymentDetails={setPaymentDetails}
                                      setCourierDetailsErrors={
                                        setCourierDetailsErrors
                                      }
                                      setPaymentDetailsErrors={
                                        setPaymentDetailsErrors
                                      }
                                      initialCourierDetails={
                                        initialCourierDetails
                                      }
                                      initialPaymentDetails={
                                        initialPaymentDetails
                                      }
                                      setSubStatusList={setSubStatusList}
                                      subStatusList={subStatusList}
                                    />
                                  ) : key == 2 ? (
                                    <CourierDetails
                                    details={details}
                                    srnStatues={srnStatues}
                                      courierDetails={courierDetails}
                                      setCourierDetails={setCourierDetails}
                                      courierDetailsErrors={
                                        courierDetailsErrors
                                      }
                                      saveCourierDetails={saveCourierDetails}
                                      setPaymentDetails={setPaymentDetails}
                                      setPaymentDetailsErrors={
                                        setPaymentDetailsErrors
                                      }
                                      initialPaymentDetails={
                                        initialPaymentDetails
                                      }
                                      initialLicenseStatus={
                                        initialLicenseStatus
                                      }
                                      licenseStatus={licenseStatus}
                                      setLicenseStatus={setLicenseStatus}
                                      setLicenseStatusErrors={
                                        setLicenseStatusErrors
                                      }
                                    />
                                  ) : (
                                    <PaymentDetails
                                      paymentDetails={paymentDetails}
                                      setPaymentDetails={setPaymentDetails}
                                      paymentDetailsErrors={
                                        paymentDetailsErrors
                                      }
                                      savePaymentDetails={savePaymentDetails}
                                      initialLicenseStatus={
                                        initialLicenseStatus
                                      }
                                      setLicenseStatus={setLicenseStatus}
                                      setLicenseStatusErrors={
                                        setLicenseStatusErrors
                                      }
                                      setCourierDetails={setCourierDetails}
                                      setCourierDetailsErrors={
                                        setCourierDetailsErrors
                                      }
                                      initialCourierDetails={
                                        initialCourierDetails
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AliianceManagerLayout>
  );
};

export default LicenseRequestDetails;
