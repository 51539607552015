import React, { useState } from "react";
import { useEffect } from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
const FilterLicenseDetails = ({
  show,
  onClose,
  setIsFilterBtnActive,
  setPage,
  setFilters,
  setClearFields,
  clearFields,
}) => {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [unit, setUnit] = useState("");

  const applyFilter = () => {
    let error = validate();
    if (error) {
      toast.error(error);
      return;
    }
    const filters = {
      name: name,
      field_type: type,
      unit: unit,
    };

    console.log(filters);
    Object.values(filters).forEach((item) => {
      if (item) setIsFilterBtnActive(true);
    });
    setPage(1);
    setFilters(filters);
    setClearFields(false);
    onClose();
  };

  useEffect(() => {
    if (clearFields) {
      setName("");
      setType("");
      setUnit("");
    }
  }, [clearFields]);

  function validate() {
    if (!name.trim()) {
      if (!type.trim()) {
        if (!unit.trim()) {
          return "Please fill out the fields";
        }
      }
    }
  }
  return (
    <Offcanvas show={show} placement="end" onHide={() => onClose()} size="lg">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h3>Filter </h3>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modall_body">
        <div className="container-fluid filter-box pt-3  filterScroll">
          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              Name
            </label>
            <input
              placeholder="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>

          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              Type
            </label>

            <select
              className="form-control mb-3"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <option value={""}>Select Type</option>
              {[
                { title: "Range", value: "0" },
                { title: "Text Field", value: "1" },
              ].map((type, key) => (
                <option key={key} value={type.value}>
                  {type.title}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              Unit
            </label>
            <input
              placeholder="Enter Unit"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
        </div>

        <section className="pull-right m-4" 
        onClick={applyFilter}
        onKeyDown={applyFilter}
        >
          <a className="btn button_new_success p-3">Apply</a>
        </section>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FilterLicenseDetails;
