export const multiSelectStyle = {
  chips: {
    fontSize: "12px",
    // height: "20px",
    width: "auto",
    padding: "5px",
    gap: "5px",
    margin: "5px",
  },
  input: {
    fontSize: "12px",
    paddingLeft: "10px !important",
    height: "10px !important",
  },
  searchBox: {
    padding: "6px",
    minHeight: "25px",
    maxHeight: "auto",
    fontSize: "13px",
    // maxWidth:'auto'
  },
  optionContainer: {
    // To change css for option container
    overflowX: "hidden",
  },
  optionListContainer:{
    position:"relative",
  }
};
