import { Offcanvas } from "react-bootstrap";
import React, { useEffect } from "react";
import { useState } from "react";
import "./filter.css";
import Select from "react-select";
import { Form, Col } from "react-bootstrap";
import { addDays, format } from "date-fns";
import DateRangeComponent from "../AllianceManager/LicenseRequestDetails/DateRangeComponent";
import { toast } from "react-toastify";
import { preventNonNumericalInput } from "../../utils/utils";
const statuses = [
  { name: "Inactive", id: 0 },
  { name: "Active", id: 1 },
  { name: " Pending Authentication", id: 2 },
  { name: "Rejected", id: 3 },
];
const VendorFilter = ({
  show,
  onHide,
  setFilters,
  setPage,
  filters,
  showDuedateRange,
  showReqDateRange,
  setShowReqDateRange,
  setShowDuedateRange,
}) => {
  const [filtersData, setFilterData] = useState(filters);

  const [openDueDateCalendar, setOpenDueDateCalendar] = useState(false);
  const [openRequestDateCalendar, setOpenRequestDateCalendar] = useState(false);

  const applyFilter = () => {
    const {
      name,
      short_name,
      email,
      address,
      man_power_cnt_execuitve,
      man_power_cnt_field,
      dateSubmit,
      requestDateSubmit,
      status,
    } = filtersData;
    if (
      name ||
      short_name ||
      email ||
      address ||
      man_power_cnt_execuitve ||
      man_power_cnt_field ||
      dateSubmit ||
      requestDateSubmit ||
      status
    ) {
      let createdDate = `${format(
        filtersData.dueDateRange[0].startDate,
        "dd-MM-yyyy"
      )} to ${format(filtersData.dueDateRange[0].endDate, "dd-MM-yyyy")}`;
      let closedDate = `${format(
        filtersData.requestedDateRange[0].startDate,
        "dd-MM-yyyy"
      )} to ${format(filtersData.requestedDateRange[0].endDate, "dd-MM-yyyy")}`;

      let from_created_on = createdDate
        .split("to")[0]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");
      let to_created_on = createdDate
        .split("to")[1]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");
      let from_closed_on = closedDate
        .split("to")[0]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");

      let to_closed_on = closedDate
        .split("to")[1]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");

      const toFilter = {};
      if (filtersData.dateSubmit) {
        toFilter.from_created_on = from_created_on;
        toFilter.to_created_on = to_created_on;
      }
      if (filtersData.requestDateSubmit) {
        toFilter.from_closed_on = from_closed_on;
        toFilter.to_closed_on = to_closed_on;
      }

      setFilters({ ...filtersData, ...toFilter });
      setPage(1);
      onHide();
    } else {
      toast.error("Please fill out the fields");
      return;
    }
  };

  return (
    <Offcanvas
      show={show}
      onHide={onHide}
      placement="end"
      // style={{ maxWidth: "320px" }}
      backdropClassName="darkshadow"
      className="rounded-top mt-1"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h3>Filter </h3>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modall_body scrollBody">
        <div className=" filter-box pt-3  filterScroll">
          <div className="container-fluid">
            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                Vendor Name
              </label>
              <input
                placeholder="Enter Vendor Name"
                value={filtersData?.name}
                onChange={(e) =>{
                  if (/^[a-zA-Z ]*$/.test(e.target.value)) { 
                    setFilterData({
                      ...filtersData,
                      name: e.target.value,
                    })
                  }
                }
                }
                type="text"
                className="form-control"
              />
            </div>
            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                Email ID
              </label>
              <input
                placeholder="Enter Vendor E-mail"
                value={filtersData?.email}
                onChange={(e) =>
                  setFilterData({
                    ...filtersData,
                    email: e.target.value,
                  })
                }
                type="text"
                className="form-control"
              />
            </div>
            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                Short Name
              </label>
              <input
                placeholder="Enter Short Name"
                maxLength={3}
                value={filtersData?.short_name}
                onFocus={(e) => {
                  setFilterData({
                    ...filtersData,
                    short_name: e.target.value,
                  });
                }}
                onChange={(e) =>
                  setFilterData({
                    ...filtersData,
                    short_name: e.target.value,
                  })
                }
                type="text"
                className="form-control"
              />
            </div>

            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                Executive Manpower Count
              </label>
              <input
                placeholder="Enter Executive Count"
                maxLength={3}
                onkeypress={(e)=>preventNonNumericalInput(e)}
                onKeyDown={(evt) => [".","e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                value={filtersData?.man_power_cnt_execuitve}
                onFocus={(e) => {
                  setFilterData({
                    ...filtersData,
                    man_power_cnt_execuitve: e.target.value,
                  });
                }}
                onChange={(e) => {
                  if (parseInt(e.target.value) < 0) {
                    setFilterData({
                      ...filtersData,
                      man_power_cnt_execuitve: "",
                    });
                  
                  }else{
                    setFilterData({
                      ...filtersData,
                      man_power_cnt_execuitve: e.target.value,
                    });
                  }
                }}
                type="number"
                className="form-control"
              
              />
            </div>
            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                Field Executive Manpower Count
              </label>
              <input
                placeholder="Enter Field Executive Count "
                maxLength={3}
                value={filtersData?.man_power_cnt_field}
                onkeypress={(e)=>preventNonNumericalInput(e)}
                onKeyDown={(evt) => [".","e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onFocus={(e) => {
                  setFilterData({
                    ...filtersData,
                    man_power_cnt_field: e.target.value,
                  });
                }}
                onChange={(e) => {
                  if (parseInt(e.target.value) < 0) {
                    setFilterData({
                      ...filtersData,
                      man_power_cnt_field: "",
                    });
                   
                  }else{
                    setFilterData({
                      ...filtersData,
                      man_power_cnt_field: e.target.value,
                    });
                  }
                }}
                type="number"
                className="form-control"
              />
            </div>

            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                Address
              </label>
              <input
                placeholder="Enter Address"
                value={filtersData?.address}
                onFocus={(e) =>
                  setFilterData({ ...filtersData, address: e.target.value })
                }
                onChange={(e) =>
                  setFilterData({ ...filtersData, address: e.target.value })
                }
                type="text"
                className="form-control"
              />
            </div>

            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                Created On
              </label>
              <input
                value={
                  showDuedateRange
                    ? `${format(
                        filtersData.dueDateRange[0].startDate,
                        "dd-MM-yy"
                      )} to ${format(
                        filtersData.dueDateRange[0].endDate,
                        "dd-MM-yy"
                      )}`
                    : ""
                }
                placeholder="Select Created On Date Range"
                readOnly
                // className="inputBox"
                onClick={() => setOpenDueDateCalendar((open) => !open)}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  outline: "none",
                  padding: "5px 12px",
                }}
              />
              {openDueDateCalendar && (
                <DateRangeComponent
                  setOpenCalendar={setOpenDueDateCalendar}
                  openCalendar={openDueDateCalendar}
                  isDueDateComponent={true}
                  toBeFiltered={filtersData}
                  setToBeFiltered={setFilterData}
                  setShowReqDateRange={setShowReqDateRange}
                  setShowDuedateRange={setShowDuedateRange}
                />
              )}
            </div>

            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                Closed On
              </label>
              <input
                value={
                  showReqDateRange
                    ? `${format(
                        filtersData.requestedDateRange[0].startDate,
                        "dd-MM-yy"
                      )} to ${format(
                        filtersData.requestedDateRange[0].endDate,
                        "dd-MM-yy"
                      )}`
                    : ""
                }
                readOnly
                placeholder="Select Closed On Date Range"
                // className="inputBox"
                onClick={() => setOpenRequestDateCalendar((open) => !open)}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  outline: "none",
                  padding: "5px 12px",
                }}
              />
              {openRequestDateCalendar && (
                <DateRangeComponent
                  setOpenCalendar={setOpenRequestDateCalendar}
                  openCalendar={openRequestDateCalendar}
                  setShowReqDateRange={setShowReqDateRange}
                  setShowDuedateRange={setShowDuedateRange}
                  isDueDateComponent={false}
                  toBeFiltered={filtersData}
                  setToBeFiltered={setFilterData}
                />
              )}
            </div>

            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                Status
              </label>
              <Form.Group>
                <Form.Control
                  as="select"
                  className="px-3"
                  value={filtersData?.status}
                  onChange={(e) =>
                    setFilterData({
                      ...filtersData,
                      status: e.target.value,
                    })
                  }
                >
                  <option value="">Select Status</option>
                  {statuses?.map((item, index) => (
                    <option value={item?.id} key={index}>
                      {item?.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
          </div>
        </div>

        <section className="pull-right m-4" 
        onKeyDown={() => applyFilter()}
        onClick={() => applyFilter()}
        >
          <a className="btn button_new_success p-3">Apply</a>
        </section>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default VendorFilter;
