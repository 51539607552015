import React, { useEffect, useState } from "react";
import Multiselect from "../../components/common/MultiSelect";
import { useQuery } from "react-query";
import LicenseMasterServices from "../../services/API/LicenseMaster";
import VendorManagerServices from "../../services/API/VendorManager";
import { toast } from "react-toastify";
import { AlphaNumWithSpace, formatMobileNumber, validateEmail, validateName } from "../../utils/utils";
import { useNavigate } from "react-router";
import Loader from "../../components/Loader";
const AddVendor = ({ vendorData }) => {
  const [vendorDetails, setVendorDetails] = useState({
    name: "",
    email: "",
    contact: "",
    address: "",
    company_registration_number:"",
    organization_name:""
  });
  const [shortName, setShortName] = useState("");
  const [regions, setRegions] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [municipals, setMunicipals] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [licenseTypes, setLicenseTypes] = useState([]);
  const [licenseNames, setLicenseNames] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [loader, setLoader] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [regionActive, setRegionActive] = useState(false);
  const navigate = useNavigate();
  const queryKeys = [
    "/api/get_zone",
    "/api/get_states",
    "/api/city_filter",
    "/api/get_municipal_details",
    "/api/license/license-industry",
    "/api/license",
    "/api/license-company",
  ];

  const { data: regionList } = useQuery(
    [queryKeys[0], { active: 1 }],
    VendorManagerServices.getRegions,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: allStateList } = useQuery(
    [
      queryKeys[1],
      {
        // zone_id:regions.map((item) => item?.id),
        active: 1,
      },
    ],

    VendorManagerServices.getAllStates,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: statesList } = useQuery(
    [queryKeys[2], { zone_id: regions?.map((item) => item?.id), active: 1 }],

    VendorManagerServices.filterState,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: cityList } = useQuery(
    [queryKeys[3], { state_id: states?.map((item) => item?.id), active: 1 }],
    VendorManagerServices.filterCity,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: municipalList } = useQuery(
    [queryKeys[4], { city_id: cities?.map((item) => item?.id), active: 1 }],
    VendorManagerServices.getAllMunicipal,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: industryList } = useQuery(
    [queryKeys[5], { active: 1 }],
    VendorManagerServices.getAllIndustry,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: licenseNameList } = useQuery(
    [queryKeys[6], { active: 1 }],
    VendorManagerServices.getLicense,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: licenseCompanyTypeList } = useQuery(
    [queryKeys[7], { active: 1 }],
    VendorManagerServices.getLicenseCompanyTypes,
    {
      refetchOnWindowFocus: false,
    }
  ); 


  const getStates = () => {
    const stateList = [];
    states?.forEach((state) => {
      let status = regions.some((item) => {
        if (item.id !== "all") {
          if (item.id === state.zone_id || item.name === state.zone_name) {
            return true;
          } else {
            return false;
          }
        }
      });

      if (status) {
        stateList.push(state);
      }
    });

    if (stateList.length > 0) {
      setStates(stateList);
    } else {
      setStates([]);
    }
  };

  useEffect(() => {
    if (regionActive) {
      getStates();
    }
  }, [regions]);
  useEffect(() => {
    const citiesList = [];
    cities.forEach((city) => {
      let status = states.some((item) => {
        if (item.id === city.state_id || item.name === city.state_name) {
          return true;
        } else {
          return false;
        }
      });

      if (status) {
        citiesList.push(city);
      }
    });
    if (citiesList.length > 0) {
      setCities(citiesList);
    } else {
      setCities([]);
    }
  }, [states]);

  useEffect(() => {
    const municipalsList = [];
    municipals.forEach((municipl) => {
      let status = cities.some((item) => {
        if (item.id !== "all") {
          if (
            item.id === municipl.city_id ||
            item.name === municipl.city_name
          ) {
            return true;
          } else {
            return false;
          }
        }
      });
      if (status) {
        municipalsList.push(municipl);
      }
    });

    if (municipalsList.length > 0) {
      setMunicipals(municipalsList);
    } else {
      setMunicipals([]);
    }
  }, [cities]);

  useEffect(() => {
    if (vendorData) {
      setVendorDetails({
        name: vendorData?.name,
        email: vendorData?.email,
        contact: vendorData?.mobile_no,
        address: vendorData?.address,
        organization_name:vendorData?.organization_name,
        company_registration_number:vendorData?.company_registration_number

      });
      setShortName(vendorData?.short_name);
      setRegions(vendorData?.zones);
      setStates(vendorData?.states);
      setCities(vendorData?.cities);

      setMunicipals(vendorData?.municipals);
      setIndustries(vendorData?.industry_types);
      setLicenseNames(vendorData?.license_names);
      setCompanyTypes(vendorData?.company_types );
      setEditMode(true);
    }
  }, []);

  const validate = () => {
    let regForShortName = /^[a-zA-Z]*$/;
    if (!vendorDetails?.name?.trim()) return "Please enter vendor name";
    if (vendorDetails?.name?.length < 3 || vendorDetails?.name?.length > 500)
      return "Please enter valid input with 3-500 characters";
    if (!validateName(vendorDetails?.name)) return "Special characters and numbers are not allowed in full name";
    // if (!AlphaNumWithSpace(vendorDetails?.name))
    //   return "Please enter only alphabets in this field";
    if (!vendorDetails?.name?.trim()) return "Please enter vendor e-mail ID";
    if (!vendorDetails?.email?.trim() || !validateEmail(vendorDetails?.email))
      return "Please enter valid e-mail ID";
    if (!vendorDetails?.contact) return "Please enter vendor mobile number";
    if (String(formatMobileNumber(vendorDetails?.contact))?.length != 10)
      return "Please enter valid 10 digit mobile number";
    

    if(vendorDetails?.organization_name){
    if( vendorDetails?.organization_name?.length < 3 ||
      vendorDetails?.organization_name?.length > 100){
      return "Please enter organization name between 3-100 characters";
      }
     }
     
     if(!vendorDetails?.company_registration_number){
      return "Please enter company registration number";
     }
     if(vendorDetails?.company_registration_number){
      if( vendorDetails?.company_registration_number?.length < 3 ||
        vendorDetails?.company_registration_number?.length > 500){
        return "Please enter company registration number between 3-500 characters";
        }
      }
       
       
    // if (!shortName?.trim()) return "Please enter short name";
    if(shortName?.trim()){
    if (shortName?.trim().length > 3 || shortName?.trim().length < 3) {
      return "Please provide a short name with exactly 3 characters";
    }
    if (regForShortName.test(shortName.trim()) === false) {
      return "Please enter only alphabets in short name field";
    }
  }

    if (!vendorDetails?.address) return "Please enter vendor address";
    if (
      vendorDetails?.address?.length < 5 ||
      vendorDetails?.address?.length > 500
    )
    return "Please enter address between 5-500 characters";
    // if (!regions?.length) return "Please select at least one Region";
    if (!states?.length) return "Please select at least one state";
    if (!cities?.length) return "Please select at least one city";
    if (!municipals?.length)
      return "Please select at least one municipal corporation";
    if (!industries?.length) return "Please select at least one industry type";
    if (!licenseNames?.length) return "Please select at least one license";
    if (!companyTypes?.length) return "Please select at least one company type";
  };

  const addVendor = async () => {
    const error = validate();
    if (error) {
      toast.error(error);
      return;
    }
    const payload = {
      name: vendorDetails?.name,
      email: vendorDetails?.email,
      mobile_no: formatMobileNumber(vendorDetails?.contact),
      address: vendorDetails?.address,
      short_name: shortName?.toUpperCase(),
      organization_name:vendorDetails?.organization_name,
      company_registration_number:vendorDetails?.company_registration_number,

      regions: regions?.map((item) => item?.id),
      states: states?.map((item) => item?.id),
      cities: cities?.map((item) => item?.id),

      municipals: municipals?.map((item) => item?.id),
      industryTypes: industries?.map((item) => item?.id),
      licenseNames: licenseNames?.map((item) => item?.id),
      companyTypes: companyTypes?.map((item) => item?.id),
    };

    console.log(payload)
    if (editMode) {
      setLoader("update");
      const resp = await VendorManagerServices.updateVendor(
        vendorData?.id,
        payload
      );
      setLoader("");
      if (resp) {
        toast.success("Vendor updated successfully");
        navigate("/alliance-manager/vendors");
      }
    } else {
      setLoader("add");
      const resp = await VendorManagerServices.addVendor(payload);
      setLoader("");
      if (resp) {
        toast.success("Vendor added successfully");
        navigate("/alliance-manager/vendors");
      }
    }
  };

  const getOptions = (data) => {
    const list = [];

    data?.forEach((item) => {
      if (item.status === "Active") list.unshift(item);
      if (item.status !== "Active") list.push(item);
    });

    return list;
  };

  return (
    <div id="main" role="main">
      <div id="content">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a
                href="#"
                onClick={() => navigate("/alliance-manager/dashboard")}
              >
                Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#" onClick={() => navigate("/alliance-manager/vendors")}>
                Vendors
              </a>
            </li>
            <li className="breadcrumb-item active">
              {vendorData ? "Edit Vendor" : "Add Vendor"}
            </li>
          </ol>
        </div>
        <div className="card card-sm card-shadow mb-3">
          <div className="card-body">
            <form className="smart-form">
              <h2>{editMode ? "Edit" : "Add New"} Vendor</h2>
              {/* <a
                href="vendor_master.html"
                target="_blank"
                className=" button_new pull-right"
              >
                <i className="fa fa-th fa-fw" title="Add Executive"></i>
              </a> */}
              <fieldset>
                <div className="row">
                  <section className="col col-5">
                    <label className="label">
                      Vendor Name<span style={{ color: "red" }}> *</span>
                    </label>
                    <label className="input">
                      <input
                        type="text"
                        className="input-sm"
                        placeholder="Enter Vendor Name"
                        value={vendorDetails?.name}
                        onChange={(e) =>
                          setVendorDetails((prevState) => {
                            return {
                              ...prevState,
                              name: e.target.value,
                            };
                          })
                        }
                      />
                    </label>
                  </section>
                  <section className="col col-4">
                    <label className="label">
                      Email ID<span style={{ color: "red" }}> *</span>
                    </label>
                    <label className="input">
                      <input
                        type="email"
                        className="input-sm"
                        disabled={editMode}
                        placeholder="Enter Email"
                        value={vendorDetails?.email}
                        onChange={(e) =>
                          setVendorDetails((prevState) => {
                            return {
                              ...prevState,
                              email: e.target.value,
                            };
                          })
                        }
                      />
                    </label>
                  </section>
                  <section className="col col-3">
                    <label className="label">
                      Mobile Number<span style={{ color: "red" }}> *</span>
                    </label>
                    <label className="input">
                      <input
                        type="text"
                        className="input-sm"
                        placeholder="Enter Mobile Number"
                        maxLength={10}
                        value={formatMobileNumber(vendorDetails?.contact)}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, "");
                          setVendorDetails((prevState) => {
                            return {
                              ...prevState,
                              contact: value,
                            };
                          });
                        }}
                      />
                    </label>
                  </section>
                </div>

                <div className="row">
                <section className="col col-5">
                    <label className="label">
                      Organization Name
                    </label>
                    <label className="input">
                      <input
                        type="text"
                        className="input-sm"
                        placeholder="Enter Organization Name"
                        value={vendorDetails?.organization_name}
                        onChange={(e) =>
                          setVendorDetails((prevState) => {
                            return {
                              ...prevState,
                              organization_name: e.target.value,
                            };
                          })
                        }
                      />
                    </label>
                  </section>
                  <section className="col col-4">
                    <label className="label">
                      Company Registration Number<span style={{ color: "red" }}> *</span>
                    </label>
                    <label className="input">
                      <input
                        type="email"
                        className="input-sm"
                       
                        placeholder="Enter Company Registration Number"
                        value={vendorDetails?.company_registration_number}
                        onChange={(e) =>
                          setVendorDetails((prevState) => {
                            return {
                              ...prevState,
                              company_registration_number: e.target.value,
                            };
                          })
                        }
                      />
                    </label>
                  </section>
                  <section className="col col-3">
                    <label className="label">
                      Short Name
                      {/* <span style={{ color: "red" }}> *</span> */}
                    </label>
                    <label className="input">
                      <input
                        type="text"
                        className="input-sm"
                        value={shortName}
                        maxLength={3}
                        onChange={(e) => setShortName(e.target.value)}
                        placeholder="Enter Short Name"
                      />
                    </label>
                  </section>
                </div>


                <div className="row">
                <section className="col col-12">
                    <label className="label">
                      Address<span style={{ color: "red" }}> *</span>
                    </label>
                    <label className="input">
                      <input
                        type="text"
                        className="input-sm"
                        value={vendorDetails?.address}
                        onChange={(e) =>
                          setVendorDetails((prevState) => {
                            return {
                              ...prevState,
                              address: e.target.value,
                            };
                          })
                        }
                        placeholder="Enter Address"
                      />
                    </label>
                  </section>
                </div>
              </fieldset>
              <fieldset>
                <h3>Regions Operated</h3>
                <br />
                <div className="row">
                  <section className="col col-xxl-3 col-md-6 col-sm-12">
                    <label className="label">Regions</label>
                    <Multiselect
                      options={regionList?.data}
                      value={regions}
                      onChange={(selectedOption) => {
                        setRegionActive(true);
                        setRegions(selectedOption);
                      }}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      // isOptionDisabled={(option) =>
                      //   option?.status === "Inactive"
                      // }
                      placeholder="Select Regions"
                    />
                  </section>
                  <section className="col col-xxl-3 col-md-6 col-sm-12">
                    <label className="label">
                      States<span style={{ color: "red" }}> *</span>
                    </label>
                    <Multiselect
                      //  options={stateOptions(regions.length)}
                      options={
                        regions.length > 0
                          ? statesList?.data
                          : allStateList?.data
                      }
                      value={states}
                      onChange={(selectedOption) => {
                        // setCities([]);
                        // setMunicipals([]);
                        setStates(selectedOption);
                        // let filteredCities = []
                        // selectedOption.forEach((state)=>{
                        //    let res = cities.filter((city)=>city.state_name === state.state_name)
                        //    if(res.length>0)filteredCities = [...filteredCities,...res]

                        // })
                        // console.log(cities)
                        // console.log(filteredCities)

                        //   setCities(filteredCities)
                      }}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      // isOptionDisabled={(option) =>
                      //   option?.status === "Inactive"
                      // }
                      placeholder="Select States"
                    />
                  </section>
                  <section className="col col-xxl-3 col-md-6 col-sm-12">
                    <label className="label">
                      Cities<span style={{ color: "red" }}> *</span>
                    </label>
                    <Multiselect
                      options={cityList?.data}
                      value={cities}
                      onChange={(selectedOption) => {
                        // setMunicipals([]);
                        setCities(selectedOption);
                      }}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      // isOptionDisabled={(option) =>
                      //   option?.status === "Inactive"
                      // }
                      placeholder="Select Cities"
                    />
                  </section>
                  <section className="col col-xxl-3 col-md-6 col-sm-12">
                    <label className="label">
                      Municipal Corporations
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Multiselect
                      options={municipalList?.data}
                      value={municipals}
                      onChange={(selectedOption) =>
                        setMunicipals(selectedOption)
                      }
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      // isOptionDisabled={(option) =>
                      //   option?.status === "Inactive"
                      // }
                      placeholder="Select Municipal Corporations"
                    />
                  </section>
                </div>
              </fieldset>
              <fieldset>
                <h3>Area of Expertise</h3>
                <br />
                <div className="row">
                  <section className="col col-4">
                    <label className="label">
                      Industry Types<span style={{ color: "red" }}> *</span>
                    </label>
                    <Multiselect
                      options={industryList?.data}
                      value={industries}
                      onChange={(selectedOption) =>
                        setIndustries(selectedOption)
                      }
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      placeholder="Select Industry Types"
                    />
                  </section>

                  <section className="col col-4">
                    <label className="label">
                      License<span style={{ color: "red" }}> *</span>
                    </label>
                    <Multiselect
                      options={licenseNameList?.data}
                      value={licenseNames}
                      onChange={(selectedOption) =>
                        setLicenseNames(selectedOption)
                      }
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      placeholder="Select License"
                    />
                  </section>
                  <section className="col col-4">
                    <label className="label">
                      Company Types<span style={{ color: "red" }}> *</span>
                    </label>
                    <Multiselect
                      options={licenseCompanyTypeList?.data}
                      value={companyTypes}
                      onChange={(selectedOption) =>
                        setCompanyTypes(selectedOption)
                      }
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      placeholder="Select Company Types"
                    />
                  </section>
                </div>
              </fieldset>
              <footer>
                <button
                  type="button"
                  className="btn button_new button_new_success d-flex text-center align-items-center"
                  onClick={() => addVendor()}
                  disabled={!!loader}
                >
                  {loader ? <Loader /> : editMode ? "Update" : "Save"}
                </button>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVendor;
