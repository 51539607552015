import React from "react";
import axiosInstance from "../axiosConfig";

class VendorManagerServices extends React.Component {
  getAllVendor = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key]) {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.get(`/api/vendor/list`, { params: newData });
  };

  addVendor = async (payload) => {
    // console.log(payload)
    return await axiosInstance.post(`/api/vendor/add`, payload);
  };

  downloadReport = async () => {
    // const [_key] = queryKey;
    axiosInstance.defaults.headers.put["responseType"] = "blob";
    return await axiosInstance.post(`/api/vendor/export`);
  };

  updateVendorStatus = async (id, status, remark) => {
    return await axiosInstance.post(`/api/vendor/update/status_vendor/${id}`, {
      is_enabled: status,
      remark,
    });
  };

  updateVendorRequest = async (id, status, remark) => {
    return await axiosInstance.post(`/api/vendor/status/${id}`, {
      status,
      remark,
    });
  };

  getVendorDetails = async ({ queryKey }) => {
    const [_Key, { id }] = queryKey;
    if (id) {
      return await axiosInstance.get(`/api/vendor/edit/${id}`);
    }
  };

  updateVendor = async (id, payload) => {
    if (id && payload) {
      return await axiosInstance.post(`/api/vendor/update/${id}`, payload);
    }
  };

  getVendorSpocsList = async ({ queryKey }) => {
    const [_key, { data }] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key] && key != "id") {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.get(`api/vendor/spoc/${data?.id}`, {
      params: newData,
    });
  };

  downloadVendorsSpocs = async (id) => {
    // const [_key] = queryKey;
    axiosInstance.defaults.headers.put["responseType"] = "blob";
    return await axiosInstance.post(`/api/vendor/spoc-export/${id}`);
  };

  getRegions = async ({ queryKey }) => {
    const [_key, queryParams] = queryKey;
    return await axiosInstance.get(`/api/get_zone`, { params: queryParams });
  };

  getAllStates = async ({ queryKey }) => {
    const [_key, queryParams] = queryKey;
    const { zone_id, active } = queryParams;

    return await axiosInstance.get(`/api/get_states`, {
      params: { active },
    });
  };
  filterState = async ({ queryKey }) => {
    const [_key, { zone_id, active }] = queryKey;
    return await axiosInstance.post(`/api/state_filter`, { zone_id, active });
  };

  filterCity = async ({ queryKey }) => {
    const [_key, { state_id }] = queryKey;
    return await axiosInstance.post(`/api/city_filter`, { state_id });
  };

  getAllMunicipal = async ({ queryKey }) => {
    const [_key, { city_id, active }] = queryKey;
    return await axiosInstance.post(`/api/municipal_filter`, {
      city_id,
      active,
    });
  };

  getAllIndustry = async ({ queryKey }) => {
    const [_key, queryParams] = queryKey;
    return await axiosInstance.get(`/api/license/license-industry`, {
      params: queryParams,
    });
  };

  getLicenseClassification = async ({ queryKey }) => {
    const [_key, queryParams] = queryKey;
    return await axiosInstance.get(`/api/license/license-type`, {
      params: queryParams,
    });
  };

  getLicense = async ({ queryKey }) => {
    const [_key, queryParams] = queryKey;
    return await axiosInstance.get(`/api/license`, {
      params: queryParams,
    });
  };

  getLicenseCompanyTypes = async ({ queryKey }) => {
    const [_key, queryParams] = queryKey;
    return await axiosInstance.get(`/api/license-company`, {
      params: queryParams,
    });
  };

  executiveWorkloadList = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key]) {
        newData[key] = data[key];
      }
    });

    return await axiosInstance.get(
      `/api/alliance/dashboard/vendor_workload_list`,
      { params: newData }
    );
  };
}

const instance = new VendorManagerServices();

export default instance;

// api/vendor/spoc/{id}
