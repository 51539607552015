import React, { useState } from "react";
import CustomModal from "../../../components/common/CustomModal";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import Multiselect from "../../../components/common/MultiSelect";
import { Button } from "react-bootstrap";

const ReassignModal = ({
  vendorsList,
  show,
  selectedSRN,
  setSelectedSRN,
  licenseId,
  refetch,
}) => {
  const [vendor, setVendor] = useState();
  const [loader, setLoader] = useState("");
  const [error, setError] = useState("");
  async function reassign() {
    if (vendor?.id) {
      setLoader("reassign");
      const resp = await AllianceManagerServices.srnReassignToVendor({
        service_ids: [selectedSRN?.id],
        vendorId: vendor?.id,
      });
      setLoader("");
      if (resp) {
        toast.success("Vendor reassigned successfully");
        refetch();
        setSelectedSRN(null);
      }
    } else {
      setError("Please select vendor");
    }
  }

  return (
    <CustomModal
      show={true}
      onCloseText="Cancel"
      onSubmitText={loader ? <Loader /> : "Reassign"}
      headerTitle="Reassign Vendor"
      onSubmit={reassign}
      onClose={() => setSelectedSRN(null)}
      disableClose={!!loader}
      disableSubmit={!!loader}
    >
      <div>
        <label>Please select vendor for reassignment</label>

        <Multiselect
          isMulti={false}
          options={vendorsList?.filter((item) => !!item?.is_enabled && item?.license_names?.filter((fitem)=>fitem?.id == licenseId)?.length)}
          value={vendor}
          isSearchable
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          onChange={(option) => setVendor(option)}
          menuPosition="fixed"
          placeholder="Select Vendor"
        />
        <p className="txt-fail m-0 p-0">{error}</p>
      </div>
    </CustomModal>
  );
};

export default ReassignModal;
