import React from 'react'
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

const BarChartComponent = ({data,COLORS}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
    <BarChart
      width={800}
      height={600}
      data={data}
      className='rechart-surface'
    >
      <defs>
        <linearGradient
          id="colorUv"
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop
            offset="5%"
            stopColor="#8884d8"
            stopOpacity={0.8}
          />
          <stop
            offset="95%"
            stopColor="#8884d8"
            stopOpacity={0}
          />
        </linearGradient>
        <linearGradient
          id="colorPv"
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop
            offset="5%"
            stopColor="#82ca9d"
            stopOpacity={0.8}
          />
          <stop
            offset="95%"
            stopColor="#82ca9d"
            stopOpacity={0}
          />
        </linearGradient>
      </defs>
      <XAxis dataKey="name" />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip/>

      <Bar dataKey="Count">
        {data?.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={COLORS[index % COLORS.length]}
          />
        ))}
      </Bar>
    </BarChart>
  </ResponsiveContainer>
  )
}

export default BarChartComponent