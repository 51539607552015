import React from "react";
import { HeadProvider, Link } from "react-head";
import AdminHeader from "../../Headers/AdminHeader";
import AdminSideMenu from "../../SideMenus/AdminSideMenu";
import { useState } from "react";
import AdminFooter from "../../Footer/AdminFooter";

const AdminLayout = ({ children, sideMenu = true, footer = true }) => {
  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: " 100vh",
      }}
    >
      <AdminHeader />
      <div className="d-flex " style={{ minHeight: "90vh" }}>
        {sideMenu && <AdminSideMenu />}
        <div className="w-100 overflow-auto">
          <>
            {children}
          </>
        </div>
      </div>
            {footer && <AdminFooter />}
    </div>
  );
};

export default AdminLayout;
