import React, { useContext, useEffect, useState } from "react";
import AdminLayout from "../../components/layouts/adminLayout";
import AuthServices from "../../services/authServices";
import { validateEmail } from "../../utils/utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import NormalFooter from "../../components/Footer/NormalFooter";
export default function Forgot() {
  const [email, setEmail] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleForgot = async (e) => {
    e.preventDefault();
    const error = {};
    if (!email || !validateEmail(email)) {
      error.email1 = !email
        ? "Please enter your e-mail ID"
        : "Please enter valid e-mail ID";
      setErrors(error);
      setEmail("");
      return;
    }
    setErrors({});
    setLoading("forgot");
    const response = await AuthServices.forgot({ email });
    if (response) {
      toast.success(response.message);
      navigate("/admin/login");
    } else {
      setEmail("");
    }
    setLoading(false);
  };
  return (
    <AdminLayout sideMenu={false} footer={false}>
     <div
        style={{
          background: "url(/img/screen-background.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="d-flex justify-content-center align-items-center h-100 "
      >
          <div className="col-lg-4">
            <div className="position-relative">
              <div className="card card-lg">
                <div className="card-body">
                  <h2 className="mb-4">Forgot Password</h2>
                  <form>
                    <div className="row">
                      <div className="col-sm-12 mb-4 mb-sm-0">
                        <div className="position-relative mb-3">
                          <label
                            className="form-label font-md"
                            for="exampleFormControlInput2"
                          >
                            Email ID <span style={{ color: "red" }}> *</span>
                          </label>

                          <input
                            type="email"
                            id="exampleFormControlInput2"
                            className="form-control"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors?.email1 && (
                            <div className="text-danger  mt-2 ml-1">
                              {errors?.email1}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="d-grid gap-4">
                      <button
                        type="submit"
                        href="#"
                        className="btn btn-primary btn-lg"
                        onClick={!loading ? handleForgot : () => {}}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <NormalFooter /> */}
       </div>
    </AdminLayout>
  );
}
