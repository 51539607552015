import React, { useEffect, useRef, useState } from "react";

import AllianceManagerServices from "../../../services/API/AllianceManager";
import LicenseMasterServices from "../../../services/API/LicenseMaster";

import Pagination from "react-js-pagination";
import { toast } from "react-toastify";
import format from "date-fns/format";
import { useQueries } from "react-query";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FullScreenLoader from "../../../components/FullScreenLoader";
import LicenseRequestsFilter from "../../../components/AllianceManager/LicenseRequests/LicenseRequestsFilter";
import { filterCitiesByName, filterCitiesFromData } from "../../../utils/utils";
import Breadcrumb from "../../../components/common/Breadcrumb";
import Tiles from "../../../components/Vendor/Tile/Tiles";
import CompletedRequests from "./CompletedRequests";
import AdminPageContainer from "../../../components/AdminPageContainer";
const totalCard = {
  title: "Total Requests",
  total: "",
  colorClass: "text-base",
};

const CSVheaders = [
  { label: "S.No", key: "id" },
  { label: "Fast Track", key: "srn_fasttrack" },
  { label: "SRN", key: "srn_name" },
  { label: "Type of Request", key: "license_type" },
  { label: "Location", key: "location" },
  { label: "License", key: "license_name" },
  { label: "Executive", key: "executive_name" },
  { label: "Field Executive", key: "field_executive_name" },
  { label: "Due Date", key: "srn_duedate" },
  { label: "Requested Date", key: "srn_requesteddate" },
  { label: "Requested By", key: "requested_by" },
  { label: "Status", key: "srn_status" },
  { label: "Sub Status", key: "srn_substatus" },
  // { label: "Vendor", key: "vendor_name" },
];

const TotalRequests = () => {
  const results = useQueries([
    {
      queryKey: [`/api/vendor_list`, 1],
      queryFn: AllianceManagerServices.getVendorList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-status", 2],
      queryFn: AllianceManagerServices.getStatusList,
      staleTime: Infinity,
    },

    {
      queryKey: ["api/license/license-type", 3],
      queryFn: AllianceManagerServices.getLicenseTypeList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/get_states", 4],
      queryFn: AllianceManagerServices.getStateList,
      staleTime: Infinity,
    },

    {
      queryKey: ["/api/license", 5],
      queryFn: AllianceManagerServices.getLicenseNameList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/user_list", 6],
      queryFn: AllianceManagerServices.getRequestedByList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-category", 7],
      queryFn: AllianceManagerServices.getCategoryList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-company", 8],
      queryFn: AllianceManagerServices.getCompanyTypeList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-industry", 9],
      queryFn: AllianceManagerServices.getIndustryTypeList,
      staleTime: Infinity,
    },
  ]);

  const { state } = useLocation();
  console.log(state)

  const initialStateToBeFiltered = {
    fastTrackRequests: false,
    fastTrackStatus: false,
    requestType: [],
    // vendor: [],
    executives: state?.executiveId
      ? [{ id: state?.executiveId, name: state?.executiveName }]
      : [],
    field_executives: state?.fieldExecutiveId
      ? [{ id: state?.fieldExecutiveId, name: state?.fieldExecutiveName }]
      : [],
    status: state?.status ? state?.status : [],
    subStatus: [],
    cardStatus: "",
    state: [],
    cities: [],
    license: [],
    requestedBy: [],
    category: [],
    companyTypes: [],
    industryTypes: [],
    dueDateRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    dateSubmit: false,
    requestedDateRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    requestDateSubmit: false,
    ["request-status"]: state?.requestStatus ? state?.requestStatus : "",

    // dueDateRange:""
  };

  const limit = 20;
  const [page, setPage] = useState(1);
  const [loading, setIsLoading] = useState(false);

  const [mainData, setMainData] = useState();
  const [allLicenseRequests, setAllLicenseRequests] = useState();
  const [dataToShow, setDataToShow] = useState([]);
  const [statusCards, setStatusCards] = useState([]);
  const [isSearchbarActive, setIsSearchbarActive] = useState(false);
  const [isSearchingActive, setIsSearchingActive] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [openFilterBox, setOpenFilterBox] = useState(false);
  const [selectedCard, setSelectedCard] = useState(state?.card || totalCard);
  const [exportData, setExportData] = useState([]);
  const [toBeFiltered, setToBeFiltered] = useState(initialStateToBeFiltered);
  const [activeClearFilterBtn, setActiveClearFilterBtn] = useState(false);
  const [csvLink, setCsvLink] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [applyCardFilter, setApplyCardFilter] = useState(false);
  const [subStatusList, setSubStatusList] = useState([]);
  const excelRef = useRef();
  const [cityList, setCityList] = useState([]);
  const [showDuedateRange, setShowDuedateRange] = useState(false);
  const [showReqDateRange, setShowReqDateRange] = useState(false);
  const [card, setCard] = useState(state?.card?.title);
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.fieldExecutiveId || state?.executiveId || state?.card) {
      if (state?.card?.title != "Requests Completed") {
        handleApplyFilter(state?.card?.cardStatus);
      }
    } else {
      fetchAllLicenseRequests();
    }
  }, []);

  useEffect(() => {
    if (excelRef.current && exportData?.length) {
      excelRef.current.link.click();
    }
  }, [exportData]);
  const fetchAllLicenseRequests = async () => {
    setIsLoading(true);
    try {
      const res = await AllianceManagerServices.getAllLicenseRequests({
        page,
        limit,
      });
      if (res?.status == "success") {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setMainData(res?.data);
        setAllLicenseRequests(res?.data);
        setDataToShow(res?.data?.data);
        setIsLoading(false);
      } else {
        setAllLicenseRequests({});
        setDataToShow([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  async function search(){
    setIsLoading(true);
        
    try {
      const toBeShown = await AllianceManagerServices.search({
        searchQuery,
        paramsData: {
          page,
          limit,
          "request-status": selectedCard?.cardStatus,
        },
      });

      if (toBeShown?.status == "success") {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setAllLicenseRequests(toBeShown?.data);
        setDataToShow(toBeShown?.data?.data);
        setIsLoading(false);
      } else {
        setAllLicenseRequests({});
        setDataToShow([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    let timer;
    if (searchQuery?.trim() && !applyFilter) {
      timer = setTimeout(() => {
       search()
      }, 1000);
    }
    if (!searchQuery.trim() && applyFilter) {
      filter(selectedCard?.cardStatus);
    }
    if (!searchQuery.trim() && !applyFilter) {
      if (!state) {
        fetchAllLicenseRequests();
      }
    }
    return () => {
      clearTimeout(timer);
    };
  }, [page, searchQuery]);

  function handleApplyFilter(cardStatus, removeExecutive, ignoreCardStatus) {
    if (
      toBeFiltered?.fastTrackStatus ||
      toBeFiltered?.requestType?.length > 0 ||
      toBeFiltered?.status?.length > 0 ||
      toBeFiltered?.subStatus?.length > 0 ||
      toBeFiltered?.executives?.length ||
      toBeFiltered?.field_executives?.length ||
      toBeFiltered?.state?.length > 0 ||
      toBeFiltered?.cities?.length > 0 ||
      toBeFiltered?.license?.length > 0 ||
      toBeFiltered?.requestedBy?.length > 0 ||
      toBeFiltered?.category?.length > 0 ||
      toBeFiltered?.companyTypes?.length > 0 ||
      toBeFiltered?.industryTypes?.length > 0 ||
      toBeFiltered?.dateSubmit ||
      toBeFiltered?.requestDateSubmit ||
      (ignoreCardStatus ? false : cardStatus)
    ) {
      // setOpenFilterBox(false);
      setApplyFilter(true);
      if (page !== 1) {
        setPage(1);
      } else {
        filter(cardStatus, removeExecutive);
      }
      setActiveClearFilterBtn(true);
      // setIsLoading(true);
      setSearchQuery("");
      setIsSearchingActive(false);
    } else {
      // setOpenFilterBox(false);
      toast.error("Please fill out the fields");
      return;
    }
  }

  useEffect(() => {
    if (applyCardFilter) {
      handleApplyFilter();
      setApplyCardFilter(false);
    }
  }, [applyCardFilter]);
  async function filter(cardStatus, removeExecutive) {
    try {
      setIsLoading(true);
      const toBefiltered = {
        ...toBeFiltered,
        ["dueDateRange"]: `${format(
          toBeFiltered.dueDateRange[0].startDate,
          "dd-MM-yyyy"
        )} to ${format(toBeFiltered.dueDateRange[0].endDate, "dd-MM-yyyy")}`,
        ["requestedDateRange"]: `${format(
          toBeFiltered.requestedDateRange[0].startDate,
          "dd-MM-yyyy"
        )} to ${format(
          toBeFiltered.requestedDateRange[0].endDate,
          "dd-MM-yyyy"
        )}`,
      };
      // let srn_fasttrack = toBefiltered.fastTrackRequests ? 1 : 0;
      let fromDate = toBefiltered.dueDateRange
        .split("to")[0]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");
      let toDate = toBefiltered.dueDateRange
        .split("to")[1]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");
      let requested_from_date = toBefiltered.requestedDateRange
        .split("to")[0]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");
      let requested_to_date = toBefiltered.requestedDateRange
        .split("to")[1]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");
      //  let withPage = firstTime ? 1 : page
      //  if(firstTime){
      //   setPage(1)
      //  }
      const toFilter = {
        page,
        limit,
      };
      if (toBeFiltered?.fastTrackStatus && toBeFiltered?.fastTrackRequests)
        toFilter["srn_fasttrack"] = 1;
      if (toBefiltered?.requestType?.length > 0)
        toFilter["license-type"] = toBefiltered.requestType;
      // if (toBeFiltered.vendor.length > 0)
      //   toFilter["vendor-name"] = toBeFiltered.vendor;
      if (toBeFiltered?.status?.length > 0)
        toFilter["license-status"] = toBeFiltered.status;
      if (toBeFiltered?.subStatus?.length > 0)
        toFilter["license-substatus"] = toBeFiltered.subStatus;
      if (toBeFiltered?.cities?.length > 0)
        toFilter["assigned-city"] = toBeFiltered.cities;
      if (toBeFiltered?.license?.length > 0)
        toFilter["l-name"] = toBeFiltered.license;
      if (
        (toBeFiltered?.executives?.length ||
          toBeFiltered?.field_executives?.length) &&
        !removeExecutive
      ) {
        toFilter["assigned-to"] = [
          ...(toBeFiltered?.executives?.map((item) => item?.name) || []),
          ...(toBeFiltered?.field_executives?.map((item) => item?.name) || []),
        ];
      }
      if (toBeFiltered?.requestedBy?.length > 0)
        toFilter["u-name"] = toBeFiltered.requestedBy;
      if (toBeFiltered?.state?.length > 0)
        toFilter["assigned-state"] = toBeFiltered.state;
      if (toBeFiltered?.companyTypes?.length > 0)
        toFilter["company-type"] = toBeFiltered.companyTypes;
      if (toBeFiltered?.category?.length > 0)
        toFilter["license-category"] = toBeFiltered.category;
      if (toBeFiltered?.industryTypes?.length > 0)
        toFilter["industry-type"] = toBeFiltered.industryTypes;
      if (toBeFiltered.dateSubmit) {
        toFilter["from_date"] = fromDate;
        toFilter["to_date"] = toDate;
      }
      if (toBeFiltered.requestDateSubmit) {
        toFilter["requested_from_date"] = requested_from_date;
        toFilter["requested_to_date"] = requested_to_date;
      }
      if (cardStatus) {
        toFilter["request-status"] = cardStatus;
      }
      const response = await AllianceManagerServices.overallRequestListFilter(
        toFilter
      );
      if (response?.status == "success") {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setAllLicenseRequests(response.data);
        setDataToShow(response.data.data);
        // setToBeFiltered(initialStateToBeFiltered);
        setOpenFilterBox(false);
        setIsLoading(false);
      } else {
        // toast.error(response.message);
        setDataToShow([]);
        setIsLoading(false);
        setOpenFilterBox(false);
      }
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
      setOpenFilterBox(false);
      setActiveClearFilterBtn(false);
    }
  }

  async function clearFilters() {
    if (page == 1) {
      fetchAllLicenseRequests();
    }
    setToBeFiltered({
      ...initialStateToBeFiltered,
      field_executives: [],
      executives: [],
    });
    setSelectedCard(totalCard);
    setApplyFilter(false);
    setActiveClearFilterBtn(false);
    setIsSearchingActive(false);
    setShowDuedateRange(false);
    setShowReqDateRange(false);
    setSearchQuery("");
    setPage(1);
  }

  useEffect(() => {
    const getCities = async () => {
      const stateListRes = await AllianceManagerServices.getStateList();

      const stateIds = toBeFiltered.state.map((state) => {
        return stateListRes?.data?.find((item) => item.state_name === state).id;
      });
      try {
        const resp = await LicenseMasterServices.filterCity({
          state_id: stateIds,
          active: 1,
        });

        if (resp) {
          const cities = resp?.data?.map((item) => item.name);

          setCityList(cities);
        }
      } catch (e) {}
    };
    getCities();
  }, [toBeFiltered.state]);

  useEffect(() => {
    if (toBeFiltered.status.length > 0) {
      const getSubStatus = async () => {
        const statusIds = toBeFiltered.status.map((status) => {
          return results[1]?.data?.data?.find((item) => item.name === status)
            ?.id;
        });
        try {
          const resp = await AllianceManagerServices.filterSubStatusByStatus({
            license_id: statusIds,
          });
          if (resp) {
            const subStatus = resp?.data?.map((item) => item.name);
            setSubStatusList(subStatus);
          }
        } catch (e) {}
      };
      getSubStatus();
    }
  }, [toBeFiltered.status]);

  const totalBreadcrumb = [
    {
      title: "Home",
      link: "/vendor/dashboard",
      isActive: true,
    },
    {
      title: `${selectedCard?.title}`,
      link: "",
      isActive: false,
    },
  ];

  const compltedBreaDCRUMB = [
    {
      title: "Home",
      link: "/vendor/dashboard",
      isActive: true,
    },
    {
      title: "Requests Completed",
      link: "",
      isActive: false,
    },
  ];
  const downloadReport = async (event, done) => {
    const resp = await AllianceManagerServices.downloadReport({
      "request-status": selectedCard?.cardStatus,
    });
    const newresp = resp?.map((item, index) => {
      return {
        ...item,
        s_no: index + 1,
        srn_fasttrack: item?.srn_fasttrack == "1" ? "Yes" : "No",
      };
    });
    if (newresp) {
      setExportData(newresp);
      toast.success(`${selectedCard?.title} Downloaded Successfully`);
    }
  };

  return (
    <>
      {loading && <FullScreenLoader show={loading} title="" />}
      {openFilterBox && (
        <LicenseRequestsFilter
          openFilterBox={openFilterBox}
          setOpenFilterBox={setOpenFilterBox}
          activeClearFilterBtn={activeClearFilterBtn}
          toBeFiltered={toBeFiltered}
          setToBeFiltered={setToBeFiltered}
          handleApplyFilter={() =>
            handleApplyFilter(selectedCard?.cardStatus, false, true)
          }
          loading={loading}
          initialStateToBeFiltered={initialStateToBeFiltered}
          vendorList={results[0]?.data?.data?.map((item) => item.name)}
          statusList={results[1]?.data?.data?.map((item) => item.name)}
          subStatusList={subStatusList}
          licenseTypeList={results[2]?.data?.data?.map((item) => item.name)}
          stateList={results[3]?.data?.data?.map((item) => item.name)}
          cityList={cityList}
          licenseNameList={results[4]?.data?.data?.map((item) => item.name)}
          requestedByList={results[5]?.data?.map((item) => item.name)}
          categoryList={results[6]?.data?.data?.map((item) => item.name)}
          companyTypeList={results[7]?.data?.data?.map((item) => item.name)}
          industryTypeList={results[8]?.data?.data?.map((item) => item.name)}
          showDuedateRange={showDuedateRange}
          setShowDuedateRange={setShowDuedateRange}
          showReqDateRange={showReqDateRange}
          setShowReqDateRange={setShowReqDateRange}
        />
      )}
      <Breadcrumb items={totalBreadcrumb} />
      <Tiles
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        handleApplyFilter={handleApplyFilter}
        setIsSearchbarActive={setIsSearchbarActive}
        setDataToShow={() => {
          setDataToShow(allLicenseRequests?.data);
        }}
        clearFilters={clearFilters}
        setToBeFiltered={setToBeFiltered}
        toBeFiltered={toBeFiltered}
        setCard={ setCard}
      />
      {selectedCard?.title === "Requests Completed" ? (
        <div className="card card-sm mb-3">
          <CompletedRequests />
        </div>
      ) : (
        <div className="row">
          <div className="card card-sm mb-3">
            <div className="card-body ">
              <div className="d-flex justify-content-between">
                <h2>
                  {selectedCard?.title ? selectedCard?.title : "Total Requests"}{" "}
                </h2>
                <div className="d-flex justify-content-end align-items-center gap-2 pb-2 mt-5">
                  {activeClearFilterBtn || searchQuery ? (
                    <div style={{ cursor: "pointer" }} className="button_new">
                      <a
                        onClick={() => {
                          setIsSearchbarActive(false);
                          setDataToShow(allLicenseRequests?.data);
                          clearFilters();
                        }}
                        onKeyDown={() => {
                          setIsSearchbarActive(false);
                          setDataToShow(allLicenseRequests?.data);
                          clearFilters();
                        }}
                        target="blank"
                        style={{ padding: "5px 12px !important" }}
                      >
                        Clear Filters
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="button_new">
                    <a>
                      {isSearchbarActive ? (
                        <input
                          type="search"
                          placeholder="Search "
                          value={searchQuery}
                          onChange={(e) => {
                            setSearchQuery(e.target.value);
                            if (e.target.value) {
                              setApplyFilter(false);
                              setPage(1);
                              setIsSearchingActive(true);
                            } else {
                              if (selectedCard?.cardStatus) {
                                setApplyFilter(true);
                                setPage(1);
                                setActiveClearFilterBtn(true);
                                setIsSearchingActive(false);
                              }
                            }
                          }}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "16px",
                          }}
                          title="Search"
                        />
                      ) : (
                        <i
                          class="fa fa-search requests-icons"
                          title="Search"
                          aria-hidden="true"
                          //title="Search"
                          onClick={() => {
                            setIsSearchbarActive(true);
                          }}
                        ></i>
                      )}
                    </a>
                  </div>
                  <div className="button_new">
                    <a
                      onClick={() => {
                        setOpenFilterBox(true);
                      }}
                      onKeyDown={() => {
                        setOpenFilterBox(true);
                      }}
                      target="blank"
                      // style={{ marginRight: "45px" }}
                    >
                      <i
                        className="fa fa-filter fa-fw requests-icons"
                        title="Show Filters"
                      ></i>
                    </a>
                  </div>
                  <button className=" button_new" onClick={downloadReport}>
                    <i
                      className="fa fa-download"
                      aria-hidden="true"
                      title="Download"
                    ></i>
                  </button>

                  <CSVLink
                    data={exportData}
                    filename={`${selectedCard?.title}.csv`}
                    headers={CSVheaders}
                    ref={excelRef}
                    onClick={() => {}}
                    asyncOnClick
                    className="display-hidden"
                  ></CSVLink>
                </div>
              </div>
              {/* ---------Table Starts Here-------- */}

              <div className="table-responsive">
                <table
                  className="table-bordered table-hover dataTable no-footer"
                  id="myTable1"
                  style={{
                    minWidth: "100%",
                  }}
                >
                  <thead>
                    <tr
                      role="row"
                      style={{
                        fontWeight: "500",
                        background: "#eee",
                      }}
                    >
                      <th
                        className="text-center align-top py-2 td-sr"
                        // className="sorting_asc"
                        tabindex="0"
                        aria-controls="myTable1"
                        rowspan="1"
                        colspan="1"
                        aria-sort="ascending"
                        aria-label="SR No.: activate to sort column ascending"
                      >
                        S. No.
                      </th>
                      <th
                        className="text-center align-top py-2"
                        // className="sorting_asc"
                        tabindex="0"
                        aria-controls="myTable1"
                        rowspan="1"
                        colspan="1"
                        aria-sort="ascending"
                        aria-label="SR No.: activate to sort column ascending"
                        style={{ width: "9%" }}
                      >
                        SRN
                      </th>

                      <th
                        className=" text-center align-top py-2"
                        // className="sorting"
                        tabindex="0"
                        aria-controls="myTable1"
                        rowspan="1"
                        colspan="1"
                        aria-label="Type of Request: activate to sort column ascending"
                        // style={{ width: "54px" }}
                      >
                        Type of Request
                      </th>
                      <th
                        className="text-center align-top py-2"
                        // className="sorting"
                        tabindex="0"
                        aria-controls="myTable1"
                        rowspan="1"
                        colspan="1"
                        aria-label="Location: activate to sort column ascending"
                        style={{ width: "8%" }}
                      >
                        Location
                      </th>
                      {/* <th
                className="text-center align-top py-2"
                // className="sorting"
                tabindex="0"
                aria-controls="myTable1"
                rowspan="1"
                colspan="1"
                aria-label="Location: activate to sort column ascending"
                // style={{ width: "63px" }}
              >
                City
              </th> */}

                      {/* <th className="sorting" tabindex="0" aria-controls="myTable1" rowspan="1" colspan="1" aria-label="State: activate to sort column ascending" style={{width:"52px"}}>State</th> */}
                      <th
                        className="text-center align-top py-2"
                        // className="sorting"
                        tabindex="0"
                        aria-controls="myTable1"
                        rowspan="1"
                        colspan="1"
                        aria-label="License Type: activate to sort column ascending"
                        style={{ width: "16%" }}
                      >
                        License
                      </th>
                      <th
                        className="text-center align-top py-2"
                        // className="sorting"
                        tabindex="0"
                        aria-controls="myTable1"
                        rowspan="1"
                        colspan="1"
                        aria-label="License Type: activate to sort column ascending"
                      >
                        Executive
                      </th>
                      <th
                        className="text-center align-top py-2"
                        // className="sorting"
                        tabindex="0"
                        aria-controls="myTable1"
                        rowspan="1"
                        colspan="1"
                        aria-label="License Type: activate to sort column ascending"
                      >
                        Field Executive
                      </th>
                      <th
                        className="text-center align-top py-2"
                        // className="sorting"
                        tabindex="0"
                        aria-controls="myTable1"
                        rowspan="1"
                        colspan="1"
                        aria-label="Category: activate to sort column ascending"
                        style={{ width: "10%" }}
                      >
                        Due Date
                      </th>
                      <th
                        className="text-center align-top py-2"
                        // className="sorting"
                        tabindex="0"
                        aria-controls="myTable1"
                        rowspan="1"
                        colspan="1"
                        aria-label="Requested Date: activate to sort column ascending"
                        style={{ width: "10%" }}
                      >
                        Requested Date
                      </th>
                      <th
                        className="text-center align-top py-2"
                        // className="sorting"
                        tabindex="0"
                        aria-controls="myTable1"
                        rowspan="1"
                        colspan="1"
                        aria-label="Requested By: activate to sort column ascending"
                        style={{ width: "10%" }}
                      >
                        Requested By
                      </th>
                      <th
                        className="text-center align-top py-2"
                        // className="sorting"
                        tabindex="0"
                        aria-controls="myTable1"
                        rowspan="1"
                        colspan="1"
                        aria-label="Status: activate to sort column ascending"
                        // style={{ width: "66px" }}
                      >
                        Status
                      </th>
                      <th
                        className="text-center align-top py-2"
                        // className="sorting"
                        tabindex="0"
                        aria-controls="myTable1"
                        rowspan="1"
                        colspan="1"
                        aria-label="Status: activate to sort column ascending"
                        style={{ width: "9%" }}
                      >
                        Sub Status
                      </th>
                    </tr>
                  </thead>

                  <tbody style={{ fontSize: "12px" }}>
                    {dataToShow?.length == 0 && !loading && (
                      <tr>
                        <td colSpan={12} className="text-center align-middle">
                          No data found
                        </td>
                      </tr>
                    )}
                    {dataToShow?.length > 0
                      ? dataToShow.map((item, key) => (
                          <tr role="row" className="odd" key={key}>
                            <td className="text-start align-middle">
                              {/* {limit * (page - 1) + key + 1} */}
                              {allLicenseRequests?.from + key}
                            </td>
                            <td className="sorting_1 text-start align-middle">
                              <Link
                                to={`/vendor/license-request-details/${item.id}`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  {item?.srn_fasttrack == "1" && (
                                    <i
                                      class="fa-solid fa-flag"
                                      style={{ color: "red" }}
                                    ></i>
                                  )}

                                  <span>{item?.srn_name || ""}</span>
                                </div>
                              </Link>
                            </td>

                            <td className="text-start align-middle">
                              {item?.license_type || ""}
                            </td>
                            <td className="text-start align-middle">
                              {item?.city_name || ""},<br />{" "}
                              {item?.state_name || ""} - {item?.pin_code || ""}
                            </td>
                            {/* <td className="text-start align-middle">
                    {item?.city_name || ""}
                  </td> */}
                            <td
                              className="text-start align-middle"
                              style={{ maxWidth: "150px" }}
                            >
                              {item?.license_name || ""}
                            </td>
                            <td className="text-start align-middle">
                              {item?.executive_name ? (
                                <>
                                  <i
                                    class="fa-solid fa-user"
                                    style={{ color: "#c6538c" }}
                                  ></i>{" "}
                                  {item.executive_name}
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              {item?.field_executive_name ? (
                                <>
                                  , <br />{" "}
                                  <i
                                    class="fa-solid fa-user"
                                    style={{ color: "#c6538c" }}
                                  ></i>{" "}
                                  {item.field_executive_name}
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="text-start align-middle">
                              {item?.srn_duedate
                                ? moment(item?.srn_duedate).format(
                                    "DD-MM-YY hh:mm A"
                                  )
                                : ""}
                            </td>
                            <td className="text-start align-middle">
                              {" "}
                              {item?.srn_requesteddate
                                ? moment(item?.srn_requesteddate).format(
                                    "DD-MM-YY hh:mm A"
                                  )
                                : ""}{" "}
                            </td>
                            <td className="text-start align-middle">
                              {" "}
                              {item?.requested_by || ""}{" "}
                            </td>
                            <td className="text-start align-middle">
                              {/* {item?.srn_status || ""} */}
                              {
                            card == "Copy Shared" ? "Copy Shared" : 
                            // card == "in_progress" ? "Request Inprogress" : 
                            // card == "Overdue" ? "Request Overdue" : 
                            item?.srn_status ? item?.srn_status : 
                            ""
                            }
                            </td>
                            <td className="text-start align-middle">
                              {item?.srn_substatus || ""}
                            </td>

                            {/* {item?.company_name || "--"} */}
                            {/* <!--<td>3</td>--> */}
                            {/* <!--<td></td>--> */}
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end mr-2">
                {allLicenseRequests?.total > limit && (
                  <Pagination
                    activePage={allLicenseRequests?.current_page}
                    itemsCountPerPage={allLicenseRequests?.per_page}
                    totalItemsCount={allLicenseRequests?.total}
                    pageRangeDisplayed={5}
                    onChange={(number) => {
                      setPage(number);
                    }}
                  />
                )}
              </div>
              {/* ---------Table Ends Here-------- */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TotalRequests;
