import React, { useState } from "react";
import "./myAccount.css";
import Profile from "../../../components/MyAccount";
import { Link, useNavigate } from "react-router-dom";
import ExecutiveLayout from "../../../components/layouts/ExecutiveLayout";
import { toast } from "react-toastify";
import {
  validateAccountFormData,
  validateChangePasswordFormData,
} from "../../../utils/utils";
import useAuth from "../../../hooks/useAuth";
import MyAccountService from "../../../services/API/MyAccountManager";
import AuthServices from "../../../services/authServices";
import { localStorageKeys } from "../../../constant/keys";
import { useQuery } from "react-query";
export default function MyAccountExecutive() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [passwordFormData, setPasswordFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState("profile");
  const handleChangePassword = (e) => {
    const { value, name } = e.target;
    setPasswordFormData({ ...passwordFormData, [name]: value.trim() });
  };

  const handleSubmitChangePassword = async () => {
    const error = validateChangePasswordFormData(passwordFormData, () =>
      setPasswordFormData({
        newPassword: "",
        confirmPassword: "",
      })
    );
    if (error) {
      toast.error(error);
    } else {
      try {
        const res = await MyAccountService.changePasswordService({
          userId: auth?.userId,
          ...passwordFormData,
        });
        if (res?.status_code === 200) {
          toast.success("Your password updated successfully.");
          const response = await AuthServices.logout();
          if (response) {
            localStorage.removeItem(localStorageKeys.aparajithaData);
            auth.setIsAuthenticated(false);
            navigate("/admin/login");
          }
        }
      } catch (error) {
        toast.message(error.message);
        setPasswordFormData({
          newPassword: "",
          confirmPassword: "",
        });
      }
    }
  };

  // Edit Details
  const localStore = JSON.parse(localStorage.getItem("aparajithaData"));
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    whatsapp_status: "",
    user_mob: "",
    user_email: "",
  });

  const { refetch } = useQuery(
    [`api/user_details`, { user_id: auth?.userId }],
    MyAccountService.getUserDetails,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (resp) => {
        const data = resp?.data;
        const newData = {
          ...JSON.parse(localStorage.getItem("aparajithaData")),
          data: data,
          user_mob: data?.mask_mobile,
          user_email: data?.mask_email,
        };
        localStorage.setItem("aparajithaData", JSON.stringify(newData));
        auth?.setEmail(data?.email);
        auth?.setPhone(data?.mobile_no);
        auth?.setName(data?.name);
        setFormData({
          fullName: data?.name,
          email: data?.email,
          phone: data?.mobile_no ? data?.mobile_no.replace("+91", "") : "",
          whatsapp_status: data?.whatsapp_status,
          user_mob: data?.mask_mobile,
          user_email: data?.mask_email,
        });
        setLoading("");
      },
    }
  );

  const handleChangeDetails = (e) => {
    const { value, name } = e.target;

    setFormData({
      ...formData,
      [name]:
        name === "phone"
          ? value.replace(/\D/g, "").trim()
          : name === "email"
          ? value.trim()
          : value,
    });
  };

  const handleCancel = () => {
    setFormData({
      fullName: auth?.name,
      email: auth?.email,
      phone: auth?.phone ? auth?.phone.replace("+91", "") : "",
      whatsapp_status: localStore?.data?.whatsapp_status,
      user_mob: localStore?.user_mob || "",
      user_email: localStore?.user_email || "",
    });
    setIsEdit(false);
  };

  const handleSubmit = async () => {
    if (isEdit) {
      const error = validateAccountFormData(formData);
      if (error) {
        toast.error(error);
      } else {
        try {
          const res = await MyAccountService.updateDetailsMyAccount({
            userId: auth?.userId,
            ...formData,
          });
          if (res?.status_code === 200) {
            auth?.setRoleId(res?.data.user_role_id);
            auth?.setName(res?.data.name);
            auth?.setUserId(res?.data.id);
            auth?.setEmail(res?.data.email);
            auth?.setPhone(res?.data.mobile_no);
            const newData = {
              ...JSON.parse(localStorage.getItem("aparajithaData")),
              data: res.data,
              user_email: res?.user_email,
              user_mob: res?.user_mob,
            };
            toast.success("Your account details updated successfully");
            setIsEdit(false);
            localStorage.setItem("aparajithaData", JSON.stringify(newData));
            setFormData({
              ...formData,
              user_mob: res?.user_mob || "",
              user_email: res?.user_email || "",
            });
          }
        } catch (error) {
          toast.message(error.message);
        }
      }
    } else {
      setIsEdit(true);
    }
  };
  return (
    <ExecutiveLayout>
      <div id="main" role="main">
        <div id="content">
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item ">
                <Link to={"/executive/dashboard"}>Home</Link>
              </li>
              <li className="breadcrumb-item active">My Account</li>
            </ol>
          </div>
          {loading !== "profile" ? (
            <Profile
              auth={auth}
              passwordFormData={passwordFormData}
              handleChangePassword={handleChangePassword}
              handleSubmitChangePassword={handleSubmitChangePassword}
              // edit Details
              handleChangeDetails={handleChangeDetails}
              handleSubmit={handleSubmit}
              isEdit={isEdit}
              formData={formData}
              handleCancel={handleCancel}
              refetch={refetch}
            />
          ) : (
            <div className="text-center">Loading...</div>
          )}
        </div>
      </div>
    </ExecutiveLayout>
  );
}
