import React, { createContext, useState } from "react";
import AddDoc from "./AddDoc";
import DocumentPanel from "./DocumentPanel";
import AdminLayout from "../../../components/layouts/adminLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import { useNavigate } from "react-router";
export const DocumentMasterContext = createContext(null);
export const spl_format = /^[A-Za-z\s]*$/;
export default function DocumentMaster() {
  const [show, setShow] = useState(null);
  const navigate = useNavigate();
  return (
    <AdminLayout test1="active">
      <AdminPageContainer>
        <DocumentMasterContext.Provider value={{ show, setShow }}>
          <div id="main" role="main">
            <div id="content">
              <div>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item ">
                    <a
                      style={{
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/system-admin/license-master")}
                      onKeyDown={() => navigate("/system-admin/license-master")}
                    >
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Document Masters</li>
                </ol>
              </div>
              {/* tabs */}
              <DocumentPanel />
            </div>
            {/* modals */}
          </div>
        </DocumentMasterContext.Provider>
      </AdminPageContainer>
    </AdminLayout>
  );
}
