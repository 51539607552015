import React from "react";
import AdminPageCard from "../../../../components/common/AdminPageCard/AdminPageCard";
import AdminPageHeader from "../../../../components/AdminPageHeader";
import AdminTable from "../../../../components/common/AdminTable/AdminTable";
import LicenseConfigureServices from "../../../../services/API/LicenseConfiguration";
import Pagination from "react-js-pagination";
import { useState } from "react";
import { useQuery } from "react-query";
import AddDetails from "../Modals/AddDetails";
import ADLIcenseDetail from "../Modals/ADLIcenseDetail";
import ViewDetails from "../Modals/ViewDetails";
import { useEffect } from "react";
import FilterLicenseDetails from "../Modals/FilterLicenseDetails";
import { toast } from "react-toastify";

const LicenseDetails = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [selectedITem, setSelectedItem] = useState();
  const [view, setView] = useState(false);
  const [viewInfo, setViewInfo] = useState([]);
  const [action, setAction] = useState();
  const limit = 20;

  const [loader, setLoader] = useState("");
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [searchkey, setSearchKey] = useState("");
  const [myData, setMyData] = useState([]);
  const [isFilterBtnActive, setIsFilterBtnActive] = useState(false);
  const [clearFields, setClearFields] = useState(false);

  const postsPerPage = 20;

  const {
    data: detailsList,
    isLoading,
    refetch,
  } = useQuery(
    [
      `/api/license_details`,
      { ...filters, q: searchkey, limit: limit, page: page },
    ],
    LicenseConfigureServices.LicenseDetailsList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const {
    data: seachLicenseDetailslist,
    isSearchFetching,
    isLoading: isSearchLoading,
    // isSuccess: success,
    // isRefetching,
    // refetch,
  } = useQuery(
    [
      `/api/license_details`,
      {
        q: searchkey,
        limit: limit,
        page: page,
      },
    ],
    LicenseConfigureServices.searchLicenseDetails,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!searchkey,
    }
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page, detailsList]);
  const clearFilter = () => {
    setClearFields(true);
    setIsFilterBtnActive(false);
    setFilters({});
    setSearchKey("");
    setDisplaySearchField(false);
    setPage(1);
  };
  const downloadReport = async () => {
    setLoader("download");
    const resp = await LicenseConfigureServices.downloadLicenseDetailsReport();
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "License-Details.csv");
      toast.success("License Details Downloaded Sucessfully");
      link.click();
    }
  };

  const tableRows = [
    {
      title: "S. No.",
      value: (column, index) => limit * (page - 1) + index + 1,
      thProps: { width: "6%" },
    },
    {
      title: "Name",
      value: (column, index) => column?.name,
    },
    {
      title: "Type",
      value: (column, index) =>
        column?.field_type == 0
          ? "Range"
          : column?.field_type == 1
          ? "Textfield"
          : "",
    },
    {
      title: "Unit",
      value: (column, index) => column?.unit,
    },
    {
      title: "Actions",
      value: (column, index) => (
        <>
          <span
            title="Edit"
            onClick={() => {
              setSelectedItem(column);
              setAction("edit");
            }}
            onKeyDown={() => {
              setSelectedItem(column);
              setAction("edit");
            }}
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-edit fa-fw"></i>
          </span>
          <i
            className={`fa fa-circle fa-fw ${
              column?.is_enabled == 0 ? "txt-fail" : "txt-success"
            } pe-auto`}
            style={{
              cursor: "pointer",
            }}
            title={column?.is_enabled == 0 ? "Activate " : "Deactivate"}
            onClick={() => {
              setSelectedItem(column);
              setAction(column?.is_enabled == 0 ? "activate" : "Inactivate");
            }}
            onKeyDown={() => {
              setSelectedItem(column);
              setAction(column?.is_enabled == 0 ? "activate" : "Inactivate");
            }}
          ></i>
          {column?.field_type !== "1" && (
            <span
              title="View"
              disabled={true}
              onClick={() => {
                setViewInfo(column?.field_values);
                setView(true);
              }}
              onKeyDown={() => {
                setViewInfo(column?.field_values);
                setView(true);
              }}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-eye fa-fw"></i>
            </span>
          )}
        </>
      ),
      thProps: { width: "8%" },
    },
  ];

  const Header = (
    <div className="d-flex gap-2 align-items-center pb-2">
      {(isFilterBtnActive || searchkey) && (
        <button type="button" className="button_new mt-0" onClick={clearFilter}>
          Clear Filters
        </button>
      )}
      {displaySearchField ? (
        <input
          type="text"
          className="button_new mt-0"
          value={searchkey}
          onChange={(e) => {
            setPage(1);
            setSearchKey(e.target.value);
          }}
          // disabled={workflow === "expert"}
          placeholder="Search"
          // onBlur={() => setDisplaySearchField(false)}
        />
      ) : (
        <a
          className=" button_new mt-0"
          onClick={() => {
            setDisplaySearchField(true);
          }}
          onKeyDown={() => {
            setDisplaySearchField(true);
          }}
          title="Search"
        >
          <i class="fa fa-search requests-icons" aria-hidden="true"></i>
        </a>
      )}
      <div
        className=" button_new mt-0"
        title="Show Filters"
        onClick={() => setDisplayFilter(true)}
        onKeyDown={() => setDisplayFilter(true)}

        // onClick={() => setFilter(true)}
      >
        <i className="fa fa-filter fa-fw requests-icons"></i>
      </div>

      <div 
      onClick={() => downloadReport()}
      onKeyDown={() => downloadReport()}
       className=" button_new mt-0">
        <i
          className="fa fa-download requests-icons"
          aria-hidden="true"
          title="Download"
        ></i>
      </div>

      <div
        target="_blank"
        className=" button_new mt-0"
        onClick={() => {
          setAction("add");
        }}
        onKeyDown={() => {
          setAction("add");
        }}
      >
        <i
          className="fa fa-plus fa-fw requests-icons"
          title="Add License Details"
        ></i>
      </div>
    </div>
  );

  return (
    <div>
      <FilterLicenseDetails
        show={displayFilter}
        onClose={() => setDisplayFilter(false)}
        setFilters={setFilters}
        setPage={setPage}
        setIsFilterBtnActive={setIsFilterBtnActive}
        clearFields={clearFields}
        setClearFields={setClearFields}
      />

      <ADLIcenseDetail
        show={
          selectedITem && (action === "activate" || action === "Inactivate")
        }
        selectedItem={setSelectedItem}
        onHide={() => {
          setSelectedItem();
          setAction("");
        }}
        refetch={refetch}
        selectedITem={selectedITem}
      />

      {action === "add" || (selectedITem && action === "edit") ? (
        <AddDetails
          show={action === "add" || (selectedITem && action === "edit")}
          onHide={() => {
            setSelectedItem();
            setAction("");
          }}
          selectedITem={selectedITem}
          action={action}
          refetch={refetch}
          clearFilter={clearFilter}
        />
      ) : (
        ""
      )}
      <AdminPageCard>
        <div className="d-flex justify-content-end align-items-center">
          {Header}
        </div>
        <AdminTable
          columns={tableRows}
          data={
            searchkey
              ? seachLicenseDetailslist?.data?.data
              : detailsList?.data?.data
          }
          // data={detailsList?.data?.data || []}
          isDataLoading={isLoading}
        />
        <div className="d-flex justify-content-end mr-2">
          {searchkey ? (
            seachLicenseDetailslist?.data?.total > limit ? (
              <Pagination
                activePage={seachLicenseDetailslist?.data?.current_page}
                itemsCountPerPage={limit}
                totalItemsCount={seachLicenseDetailslist?.data?.total}
                pageRangeDisplayed={5}
                onChange={(number) => setPage(number)}
              />
            ) : (
              ""
            )
          ) : detailsList?.data?.total > limit ? (
            <Pagination
              activePage={detailsList?.data?.current_page}
              itemsCountPerPage={limit}
              totalItemsCount={detailsList?.data?.total}
              pageRangeDisplayed={5}
              onChange={(number) => setPage(number)}
            />
          ) : (
            ""
          )}
        </div>

        {view && (
          <ViewDetails view={view} setView={setView} viewInfo={viewInfo} />
        )}
      </AdminPageCard>
    </div>
  );
};

export default LicenseDetails;