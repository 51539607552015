import React, { useEffect, useState } from 'react'
import { Button, Modal, Table,Spinner } from 'react-bootstrap'
import { useQuery } from "react-query";
import AllianceManagerServices from "../../services/API/AllianceManager"
import moment from 'moment';
import format from "date-fns/format";

const HistoryModal = ({show,handleHide,service_id}) => {
    // const {
    //     data: history,
    //     isLoading: loading,
    //     isSuccess: success,
    //     refetch,
    //   } = useQuery(
    //     [
    //         "/api/service-history",
    //         {service_id:service_id}
    //     ],
    //     AllianceManagerServices.getServiceHistory,
    //     {
    //       refetchOnWindowFocus: false,
    //       keepPreviousData: true,
    //     }
    //   );
    const [loading, setLoading] = useState(false);


    const [historyList,setHistoryList] = useState()

    useEffect(()=>{
        getHistory(service_id)
    },[service_id])

    const getHistory = async(service_id)=>{
        setLoading(true);
        try{
         const history = await  AllianceManagerServices.getServiceHistory(service_id)
         setHistoryList(history?.data)
        //  console.log(history?.data)
        }catch(error){
          console.log(error)
        } finally {
            setLoading(false);
          }
    }
    //   console.log(history)
  return (
    <Modal
    show={show}
    animation={false}
    onHide={handleHide}
    dialogClassName="modal-xl"
  >
    <Modal.Header closeButton>
      <h3 className="modal-title">
        {"Service History"}
      </h3>
    </Modal.Header>
    <Modal.Body style={{ height: "auto" }}>
    {loading ? (
          <div className="d-flex justify-content-center my-3">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) :(
    <div className="table-responsive">
      <table
        className="table table-bordered table-hover table-striped"
        id="myTable1"
        style={{
          minWidth: "100%",
        }}
      >
        <thead className="thead-dark">
        <tr>
      <th style={{ width: "5%" }}>S.No.</th>
      <th style={{ width: "20%" }}>Reassignment Done By</th>
      <th style={{ width: "20%" }}>Status</th>
      <th style={{ width: "20%" }}>Previous Assignee</th>
      <th style={{ width: "15%" }}>New Assignee</th>
      <th style={{ width: "15%" }}>Date & Time</th>
      <th style={{ width: "15%" }}>Assign Mode</th>
    </tr>
        </thead>
        <tbody>
          {historyList?.length
            ? historyList.map((data, index) => (
              <tr key={index}>
              <td>{index + 1}</td>
              <td style={{ wordWrap: "break-word" }}>{data?.assignee_name || "-"}</td>
              <td style={{ wordWrap: "break-word" }}>{data?.entity || "-"}</td>
              <td style={{ wordWrap: "break-word" }}>{historyList[index + 1]?.assigned_name ? historyList[index + 1]?.assigned_name : data?.role_id == 5 ? data?.assignee_name:"" }</td>
              <td style={{ wordWrap: "break-word" }}>{data?.assigned_name ? data?.assigned_name : data?.assignee_name}</td>
              <td style={{ wordWrap: "break-word" }}>
                {data?.created_at ? moment(data?.created_at).format("DD-MM-YY hh:mm A") : "-"}
              </td>
              <td style={{ wordWrap: "break-word" }}>{data?.assign_mode || "-"}</td>
            </tr>
              ))
            :  <tr>
            <td colSpan="7" className="text-center">No history available.</td>
          </tr>}
        </tbody>
      </table>
    </div>)}
    </Modal.Body>
    {/* <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={handleHide}>
          Close
        </Button>
      </Modal.Footer> */}
  </Modal>
  )
}

export default HistoryModal