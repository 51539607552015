import React, { useContext, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { LicenseConfigureContext } from "..";
import LicenseConfigurationServices from "../../../../services/API/LicenseConfiguration";
import { toast } from "react-toastify";
import { validateStatus } from "../licenceTabs/Statuses";
export default function AddStatus({ refetch, editData, setEdit, clearFilter,applyFilter,filtersAvailable,searchkey,handleSearch }) {
  const { show, setShow } = useContext(LicenseConfigureContext);
  const [name, setName] = useState(null);

  useEffect(() => {
    if (editData) {
      setName(editData?.name);
    }
  }, [editData]);

  const updateStatus = async () => {
    const payload = { name };
    const error = validateStatus(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LicenseConfigurationServices.updateStatus(
        editData?.id,
        payload
      );
      if (response) {
        toast.success("Status updated successfully");
        // clearFilter();
        handleHide();
        if(filtersAvailable){
          applyFilter(1)
        }else if(searchkey){
          handleSearch({target:{value:searchkey}},1)
        }else{
          refetch();
        }
        setEdit(null);
      }
    } catch (e) {
      toast.error(e);
    }
  };
  const addStatus = async () => {
    const payload = { name };
    const error = validateStatus(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LicenseConfigurationServices.addStatus(payload);
      if (response) {
        toast.success("Status created successfully");
        clearFilter();
        refetch();
        handleHide();
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const submitForm = () => {
    if (editData) {
      updateStatus();
    } else {
      addStatus();
    }
  };
  const handleHide = () => {
    setShow(null);
    setName(null);
    setEdit(null);
  };
  return (
    <Modal show={show === "status"} animation={false} onHide={handleHide}>
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {editData ? "Edit Status" : "Add Status"}
        </h3>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Status Name<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Status Name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn button_new_success"
          onClick={submitForm}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
