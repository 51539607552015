import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import LicenseConfigurationServices from "../../../../services/API/LicenseConfiguration";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Loader from "../../../../components/Loader";
const types = [
  {
    title: "Range",
    value: "0",
  },
  {
    title: "TextField",
    value: "1",
  },
];

const AddDetails = ({
  show,
  onHide,
  selectedITem,
  action,
  refetch,
  clearFilter,
}) => {
  const [detailsData, setDetailsData] = useState({
    name: "",
    type: "",
    unit: "",
    ranges: [],
  });
  const [editMode, setEditMode] = useState(false);
  const [rangeErrors, setRangeError] = useState([]);
  const [loader, setLoader] = useState("");
  const addDetail = async () => {
    const payload = {
      name: detailsData?.name,
      unit: detailsData?.unit,
      field_type: detailsData?.type,
      field_values: detailsData.type == "1" ? [] : detailsData?.ranges,
    };

    const error = validate();
    if (error) {
      toast.error(error);
      return;
    }
    if (editMode) {
      setLoader("edit");
      const resp = await LicenseConfigurationServices?.updateLicenseDetail(
        selectedITem.id,
        payload
      );
      setLoader("");
      if (resp?.status == "Success") {
        toast.success("License Details updated successfully");
        refetch();
        // clearFilter();
        onHide();
      }
    }
    if (!editMode) {
      setLoader("add");
      const resp = await LicenseConfigurationServices?.createLicenseDetail(
        payload
      );
      setLoader("");
      if (resp?.status == "Success") {
        toast.success("License Details created successfully");
        refetch();
        clearFilter();
        onHide();
      }
    }
  };

  function validate() {
    if (!detailsData.name) {
      return "Please enter valid Detail name";
    }
    if (!detailsData.unit) {
      return "Please enter unit";
    }
    if (!detailsData.type) {
      return "Please enter the type";
    }
    if (
      detailsData.type &&
      detailsData.type == "0" &&
      !detailsData.ranges.length > 0
    ) {
      return "Please enter at least one range ";
    }
    let temprangeError = [];
    for (let i = 0; i < detailsData?.ranges?.length; i++) {
      if (!detailsData?.ranges[i]?.min) {
        temprangeError[i] = "Please enter min value";
        continue;
      }
      if (!detailsData?.ranges[i]?.max) {
        temprangeError[i] = "Please enter max value";
        continue;
      }
      if (!detailsData?.ranges[i]?.price) {
        temprangeError[i] = "Please enter price";
        continue;
      }
      if (
        Number(detailsData?.ranges[i]?.max) <=
        Number(detailsData?.ranges[i]?.min)
      ) {
        temprangeError[i] = "Min value should be less than max value";
        continue;
      }
      if (
        detailsData?.ranges?.findIndex(
          (item) =>
            item?.min === detailsData?.ranges[i]?.min &&
            item?.max === detailsData?.ranges[i]?.max
        ) !== i
      ) {
        temprangeError[i] = "Duplicate range added";
        continue;
      }
    }
    setRangeError(temprangeError);
    if (temprangeError?.length && detailsData?.type == "0") {
      return "Please provide valid ranges";
    }
  }

  useEffect(() => {
    if (selectedITem) {
      setDetailsData({
        name: selectedITem?.name,
        unit: selectedITem?.unit,
        type: selectedITem?.field_type,
        ranges: selectedITem?.field_values?.map((range) => {
          return {
            ...range,
          };
        }),
      });
      setEditMode(true);
    }
  }, [selectedITem]);

  return (
    <Modal show={show} onHide={onHide} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="modal-title">
            {editMode ? "Edit" : "Add"} License Details
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Name<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Name"
            value={detailsData?.name}
            onChange={(e) => {
              setDetailsData((prevState) => {
                return {
                  ...prevState,
                  name: e.target.value,
                };
              });
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Unit<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Unit"
            value={detailsData?.unit}
            onChange={(e) => {
              setDetailsData((prevState) => {
                return {
                  ...prevState,
                  unit: e.target.value,
                };
              });
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            Type <span style={{ color: "red" }}> *</span>
          </label>
          <select
            className="form-control mb-3"
            value={detailsData?.type}
            onChange={(e) => {
              setDetailsData((prevState) => {
                return {
                  ...prevState,
                  type: e.target.value,
                };
              });
            }}
          >
            <option value={""}>Select Type</option>
            {types?.map((type) => (
              <option key={type?.title} value={type?.value}>
                {type?.title}
              </option>
            ))}
          </select>
        </div>

        {detailsData?.type === "0" ? (
          <div className="form-group ">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>Ranges</div>
              <button
                className=" button_new mt-0"
                onClick={() => {
                  let newRanges = detailsData?.ranges;
                  newRanges?.push({
                    min: "",
                    max: "",
                    price: "",
                  });
                  setDetailsData({
                    ...detailsData,
                    ranges: newRanges,
                  });
                  setRangeError([]);
                }}
              >
                <i className="fa fa-plus fa-fw" title="Add a Range"></i>
              </button>
            </div>
            <div>
              {detailsData?.ranges?.length ? (
                <div className="text-center ">
                  {detailsData?.ranges?.map((range, index) => {
                    return (
                      <div>
                        <div className="d-flex " key={index}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Min"
                              value={detailsData?.ranges[index]?.min}
                              onKeyDown={(event) => {
                                if (event.key === ".") event.preventDefault();
                              }}
                              onChange={(e) => {
                                setRangeError([]);
                                if (e.target.value >= 0) {
                                  let newRanges = detailsData?.ranges
                                    ? [...detailsData.ranges]
                                    : [];
                                  const minValue = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  newRanges[index].min = minValue;
                                  setDetailsData({
                                    ...detailsData,
                                    ranges: newRanges,
                                  });
                                }
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Max"
                              onKeyDown={(event) => {
                                if (event.key === ".") event.preventDefault();
                              }}
                              value={detailsData?.ranges[index]?.max}
                              onChange={(e) => {
                                setRangeError([]);
                                if (e.target.value >= 0) {
                                  let newRanges = detailsData?.ranges
                                    ? [...detailsData.ranges]
                                    : [];
                                  const maxValue = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  newRanges[index].max = maxValue;
                                  setDetailsData({
                                    ...detailsData,
                                    ranges: newRanges,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Price"
                              onKeyDown={(event) => {
                                if (event.key === ".") event.preventDefault();
                              }}
                              value={detailsData?.ranges[index]?.price}
                              onChange={(e) => {
                                setRangeError([]);
                                if (e.target.value >= 0) {
                                  let newRanges = detailsData?.ranges
                                    ? [...detailsData.ranges]
                                    : [];
                                  newRanges[index].price = e.target.value;
                                  setDetailsData({
                                    ...detailsData,
                                    ranges: newRanges,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <button
                              className="button_new mt-0 rounded-0 h-100"
                              title="Remove"
                              style={{
                                border: "1px solid #ccc",
                                ":&hover": "border: 1px solid ",
                              }}
                              onClick={() => {
                                const newArray = detailsData?.ranges?.filter(
                                  (item, fIndex) => index !== fIndex
                                );
                                setDetailsData({
                                  ...detailsData,
                                  ranges: newArray,
                                });
                                setRangeError([]);
                              }}
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </button>
                          </div>
                        </div>
                        {rangeErrors[index] ? (
                          <div
                            className="txt-fail"
                            style={{ marginTop: "-10px", marginBottom: "10px" }}
                          >
                            {rangeErrors[index]}{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-center p-2 border ">No Range Added</div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn button_new_success"
          onClick={addDetail}
          disabled={!!loader}
        >
          {loader ? <Loader /> : "Save"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDetails;
