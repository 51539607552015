import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from "react-bootstrap/Modal";
import "../../../styles/executive/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import OOOService from "../../../services/API/OOOService";
import useAuth from "../../../hooks/useAuth";

const localizer = momentLocalizer(moment);

export default function LeaveCalendar({ showModal1, handleClose }) {
  const [myLeaveList, setMyLeaveList] = useState([]);
  const auth = useAuth();

  const handleMonth = async (date) => {
    const dateTemplate = await moment(date).format("DD-MM-YYYY");
    const payload = {
      id: auth?.userId,
      date: dateTemplate,
    };
    const result = await OOOService.getMyLeaveHistory(payload);
    const res = result.data.test.map((el) => {
      return {
        leave_taken_from: new Date(`${el.leave_taken_from}T10:00:00`),
        leave_taken_to: new Date(`${el.leave_taken_to}T12:00:00`),
        title: "  ",
      };
    });
    setMyLeaveList(res);
  };
  useEffect(() => {
    if (showModal1) {
      console.log("Inside effect");
      handleMonth(new Date());
    }
  }, [showModal1]);

  return (
    <Modal
      centered
      style={{ opacity: 1 }}
      show={showModal1}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Calendar
          localizer={localizer}
          events={myLeaveList.length ? myLeaveList : []}
          startAccessor="leave_taken_from"
          endAccessor="leave_taken_to"
          style={{ height: 400 }}
          onNavigate={handleMonth}
        />
      </Modal.Body>
    </Modal>
  );
}
