/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import AliianceManagerLayout from "../../../components/layouts/AliianceManagerLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import VendorManagerServices from "../../../services/API/VendorManager";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../../components/Loader";
import { addDays, format } from "date-fns";
import Pagination from "react-js-pagination";
import VendorFilter from "../../../components/VendorFilter";
import { Link } from "react-router-dom";

const initial = {
  name: "",
  short_name: "",
  email: "",
  man_power_cnt_field: "",
  man_power_cnt_execuitve: "",
  address: "",
  status: "",
  dueDateRange: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ],
  dateSubmit: false,
  requestedDateRange: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ],
  requestDateSubmit: false,
};
const UserLists = () => {
  const vendor = {
    type: "active",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [adItem, setADItem] = useState("");
  const [enduserId, setEnduserId] = useState("");
  const [loader, setLoader] = useState("");
  const [showFilter, setShowFilter] = useState("");
  const [filters, setFilters] = useState(initial);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [clearFilters, setCleafilters] = useState(false);
  const [showDuedateRange, setShowDuedateRange] = useState(false);
  const [showReqDateRange, setShowReqDateRange] = useState(false);
  const [q, setq] = useState("");
  const limit = 20;

  const queryKey = `/api/vendor`;
  const {
    data: userLists,
    isFetching,
    isLoading: listLoading,
    isSuccess: success,
    isRefetching,
    refetch,
  } = useQuery(
    [
      queryKey,
      //   {
      //     ...filters,
      //     q,
      //     limit: limit,
      //     page: page,
      //   },
    ],
    AllianceManagerServices.getUsersLists,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  // Calculate the start index and end index for current page
  const startIndex = (page - 1) * limit;
  const endIndex = startIndex + limit;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [userLists, page]);

  const updateVendorStatus = async () => {
    let statusToset = true;
    if (adItem?.is_enabled == 1) {
      statusToset = false;
    }
    setLoader("update");
    const resp = await VendorManagerServices.updateVendorStatus(
      adItem?.id,
      statusToset
    );
    setLoader("");
    setADItem(null);
    console.log("here is resp", resp.data.status);
    if (resp.data.is_enabled == 0) {
      toast.success("Vendor Deactivated Successfully");
      refetch();
    } else {
      toast.success("Vendor Activated Successfully");
      refetch();
    }
  };

  const handlePermission = async () => {
    setLoader("update");

    const res = await AllianceManagerServices.grantPermission(enduserId);
    if (res?.status == "success") {
      toast.success("Permission Granted Successfully");
      refetch();
    }
    setLoader("");
    setEnduserId(null);
  };
  const cellClasses = "d-flex align-items-center h-full  ";
  // const cellClasses = ""
  return (
    <AliianceManagerLayout vendor="active">
      <AdminPageContainer>
        <Modal
          show={!!enduserId}
          onHide={() => setEnduserId(null)}
          animation={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Do you want to grant permission to this user?</p>
            <div className="d-flex gap-3 justify-content-end">
              <Button
                variant="danger"
                className="px-3 py-2"
                onClick={() => setEnduserId(null)}
                disabled={loader === "update"}
              >
                No
              </Button>
              <Button
                variant="success"
                className="px-3 py-2"
                onClick={() => handlePermission()}
                disabled={loader === "update"}
              >
                {loader === "update" ? (
                  <div className="d-flex align-items-center gap-2">
                    <Loader /> loading...
                  </div>
                ) : (
                  "Yes"
                )}
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {/* {showFilter && (
          <VendorFilter
            show={showFilter}
            onHide={() => setShowFilter(false)}
            setFilters={setFilters}
            filters={filters}
            setPage={setPage}
            showDuedateRange={showDuedateRange}
            setShowDuedateRange={setShowDuedateRange}
            showReqDateRange={showReqDateRange}
            setShowReqDateRange={setShowReqDateRange}
          />
        )} */}

        <div id="main" role="main">
          <div id="content">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/alliance-manager/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active">User Lists</li>
              </ol>
            </div>
            <div className="card card-sm card-shadow mb-3">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h2>User Lists </h2>
                </div>

                <div className="max-w-100 overflow-auto">
                  <div className="table-responsive">
                  <table
  className="table table-bordered table-hover dataTable no-footer"
  style={{ tableLayout: "fixed" }}
>
  <thead>
    <tr style={{ backgroundColor: "#eee" }}>
      <th width="5%" className="text-center align-top">
        S. No.
      </th>
      <th className="text-center align-top" width="20%">
        Name
      </th>
      <th className="text-center align-top" width="25%">
        Email ID
      </th>
      <th width="20%" className="text-center align-top">
        Mobile Number
      </th>
      <th width="15%" className="text-center align-top">
       Resend OTP Count
      </th>
      <th width="5%" className="text-center align-top">
        Actions
      </th>
    </tr>
  </thead>
  <tbody>
    {userLists?.data?.length ? (
      userLists?.data
        ?.slice(startIndex, endIndex)
        ?.map((endUser, index) => {
          return (
            <tr key={endUser?.id}>
              <td>
                <div className={cellClasses}>
                  <div>{startIndex + index + 1}</div>
                </div>
              </td>
              <td
                style={{
                  wordWrap: "break-word",
                  whiteSpace: "pre-line",
                }}
              >
                {endUser?.name || ""}
              </td>
              <td>
                {endUser?.email || ""}
              </td>
              <td>
                <div className={cellClasses}>
                  {endUser?.mobile_no
                    ? endUser?.mobile_no?.replace("+91", "")
                    : ""}
                </div>
              </td>
              <td>
                <div className={cellClasses}>
                  {endUser?.otp_count || 0}
                </div>
              </td>
              <td>
                {endUser?.otp_count == 3 ? (
                  <i
                    className="fa fa-circle fa-fw txt-pass"
                    title="Grant Permission"
                    style={{
                      cursor: "pointer",
                      color: "green",
                    }}
                    onClick={() => setEnduserId(endUser?.id)}
                    onKeyDown={() => setEnduserId(endUser?.id)}
                  ></i>
                ):(
                  <i
                  className="fa fa-circle fa-fw txt-fail"
                  // title="Grant Permission"
                  style={{
                    cursor: "",
                    color: "red",
                  }}
                  // onClick={() => setEnduserId(endUser?.id)}
                  // onKeyDown={() => setEnduserId(endUser?.id)}
                ></i>
                )}
              </td>
            </tr>
          );
        })
    ) : (
      <tr>
        <td colSpan={6} className="p-2 text-center">
          {listLoading || isFetching ? <Loader /> : "No data found"}
        </td>
      </tr>
    )}
  </tbody>
</table>


                  </div>
                </div>
                <div className="d-flex justify-content-end mr-2">
                  {/* {userLists?.data?.length > limit && (
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={limit}
                      totalItemsCount={userLists?.data?.total}
                      pageRangeDisplayed={5}
                      onChange={(number) => setPage(number)}
                    />
                  )} */}

                  {userLists?.data?.length > limit && (
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={limit}
                      totalItemsCount={userLists?.data?.length || 0}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      // itemClass="page-item"
                      // linkClass="page-link"
                      // innerClass="pagination justify-content-center"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminPageContainer>
    </AliianceManagerLayout>
  );
};

export default UserLists;
