import React from "react";
import VendorLayout from "../../../components/layouts/VendorLayout";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import OOOService from "../../../services/API/OOOService";
import { useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import moment from "moment";
import DatePicker from "react-datepicker";
import { CustomInput } from "../../../utils/utils";
export default function CreateOOO({ setOpenAddOOOModal, fetchOOOExecutive }) {
  const [executiveList, setExecutiveList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exec, setExec] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [subject, setSubject] = useState(null);
  const [reason, setReason] = useState(null);
  const auth = useAuth();
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];

  const fetchExecutive = async () => {
    setLoading("fetch");
    const params = {
      vendor_id: auth?.userId,
      active: 1,
    };
    const response = await OOOService.getExecutiveList(params);
    if (response) {
      setExecutiveList(response?.data);
    }
    setLoading(false);
  };

  const validate = () => {
    if (!exec) return "Please select executive";
    if (!startDate) return "Please select start date";
    if (!endDate) return "Please select end date";
    if (!subject) return "Please enter subject";
    if (subject.length < 5 || subject.length > 200)
      return "Please enter subject between 5-200 characters";
    if (!reason) return "Please enter reason";
    if (reason.length < 5 || reason.length > 500)
      return "Please enter reason between 5-500 characters";
  };

  useEffect(() => {
    fetchExecutive();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      subject,
      reason,
      leave_applied_from: moment(startDate).format("YYYY-MM-DD"),
      leave_applied_to: moment(endDate).format("YYYY-MM-DD"),
      created_for: exec,
      created_by: auth?.userId,
      is_vendor: 1,
    };
    const error = validate();
    if (error) {
      toast.error(error);
      return;
    }
    setLoading("create");

    const response = await OOOService.createOOO(payload);
    if (response) {
      toast.success("Executive Out-Of-Office status updated successfully");
      setOpenAddOOOModal(false);
      fetchOOOExecutive();
      navigate("/vendor/out-of-office");
    }
    setLoading(false);
  };

  return (
    // <VendorLayout>
    <div
    // id="main" role="main"
    >
      <div id="content">
        <div>
          <div className="row">
            <div className="">
              <div className="card card-sm card-shadow ">
                <div className="card-body">
                  <form className="smart-form">
                    <h2 style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                      Out of Office Settings{" "}
                    </h2>
                    {/* <a
                        href="#"
                        target="_blank"
                        className=" button_new pull-right"
                        data-toggle="modal"
                        data-target="#showOOOTrend"
                      >
                        <i
                          className="fa fa-calendar fa-fw"
                          title="Out-Of-Office Trend"
                        ></i>
                      </a> */}

                    <fieldset>
                      <div className="row padding-top-10">
                        <section className="col col-12 executiveSelect ">
                          <label className="label">
                            Select Executive
                            <span style={{ color: "red" }}> *</span>
                          </label>
                          <label className="select ">
                            <select
                              value={exec}
                              onChange={(e) => setExec(e.target.value)}
                            >
                              <option value="">Select Executive</option>
                              {executiveList
                                .filter((item) => item.is_enabled === 1)
                                .map((exec) => (
                                  <option value={exec?.id}>{exec?.name}</option>
                                ))}
                            </select>{" "}
                            <i></i>
                          </label>
                        </section>
                      </div>
                      <div className="row">
                        <div className="col col-6">
                          <div className="">
                            <label className="label">
                              Expected Start Date
                              <span style={{ color: "red" }}> *</span>
                            </label>

                            <div className="input-sm">
                              <DatePicker
                                minDate={moment().toDate()}
                                selected={startDate}
                                onChange={(e) => setStartDate(e)}
                                type="date"
                                value={
                                  startDate &&
                                  moment(startDate).format("DD-MM-YY")
                                }
                                dateFormat="dd-MM-yyyy"
                                className="py-2"
                                customInput={
                                  <CustomInput
                                    placeholderText={"Select Start Date"}
                                    icon={true}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col col-6">
                          <div>
                            <label className="label">
                              Expected End Date
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="input-sm">
                              <DatePicker
                                minDate={moment().toDate()}
                                selected={endDate}
                                onChange={(e) => setEndDate(e)}
                                type="date"
                                // value={endDate}
                                value={
                                  endDate && moment(endDate).format("DD-MM-YY")
                                }
                                dateFormat="dd-MM-yyyy"
                                className="py-2"
                                customInput={
                                  <CustomInput
                                    placeholderText={"Select End Date"}
                                    icon={true}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row padding-top-10">
                        <section className="col col-12">
                          <label className="label">
                            Subject<span style={{ color: "red" }}> *</span>
                          </label>
                          <label className="input">
                            <input
                              type="text"
                              placeholder="Enter subject"
                              value={subject}
                              onChange={(e) => setSubject(e.target.value)}
                              className="input-sm"
                            />
                          </label>
                        </section>
                      </div>
                      <div className="row ">
                        <section className="col col-12">
                          <label className="label">
                            Reason For Out of Office
                            <span style={{ color: "red" }}> *</span>
                          </label>
                          <label className="textarea textarea-resizable">
                            <textarea
                              rows="3"
                              value={reason}
                              placeholder="Enter reason for out of office"
                              onChange={(e) => setReason(e.target.value)}
                              className="custom-scroll"
                            ></textarea>
                          </label>
                        </section>
                      </div>
                    </fieldset>
                    <footer>
                      <button
                        type="submit"
                        className="btn button_new button_new_success"
                        onClick={handleSubmit}
                      >
                        {loading == "create" ? <Loader /> : "Save"}
                      </button>
                    </footer>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // {/* </VendorLayout> */}
  );
}
