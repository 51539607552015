import React from "react";
import axiosInstance from "../axiosConfig";

class FieldExecutiveServices extends React.Component {
  getFElist = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key]) {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.get(`/api/field_executive`, { params: newData });
  };
  updateFEStatus = async (id, status) => {
    return await axiosInstance.post(
      `/api/field_executive/update/status/${id}`,
      {
        is_enabled: status,
      }
    );
  };

  downloadReport = async (data) => {
    // const [_key] = queryKey;
    axiosInstance.defaults.headers.put["responseType"] = "blob";
    return await axiosInstance.post(
      `/api/field_executive/export_fieldexecutive`,
      data
    );
  };

  addFE = async (payload) => {
    return await axiosInstance.post(`/api/field_executive/add`, payload);
  };

  FEDetails = async ({ queryKey }) => {
    const [_key, id] = queryKey;
    return await axiosInstance.get(`/api/field_executive/edit/${id}`);
  };

  UpdateFE = async (id, payload) => {
    if (id && payload) {
      return await axiosInstance.post(
        `/api/field_executive/update/${id}`,
        payload
      );
    }
  };

  AddFEByExecutive = async (payload) => {
    return await axiosInstance.post(
      `/api/executive/field_executive/add`,
      payload
    );
  };

  FEByExecutive = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key]) {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.get(`/api/executive/field_executive`, {
      params: newData,
    });
  };

  UpdateFEStatusByExec = async (id, status) => {
    return await axiosInstance.post(
      `/api/executive/field_executive/update/status/${id}`,
      {
        is_enabled: status,
      }
    );
  };

  ExecFEdownloadReport = async (id) => {
    // const [_key] = queryKey;
    axiosInstance.defaults.headers.put["responseType"] = "blob";
    return await axiosInstance.post(`/api/executive/field_executive/export`, {
      params: { id },
    });
  };
}

const instance = new FieldExecutiveServices();

export default instance;
