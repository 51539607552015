import React from "react";
import AdminPageContainer from "../../../components/AdminPageContainer";
import AdminLayout from "../../../components/layouts/adminLayout";
import Reassignment from "../../../Containers/AllianceManager/Reassignment";

const Reassignments = () => {
  return (
    <AdminLayout>
      <AdminPageContainer>
        <Reassignment />
      </AdminPageContainer>
    </AdminLayout>
  );
};

export default Reassignments;
