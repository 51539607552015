import React, { useContext, useState, useEffect } from "react";
import { LicenseConfigureContext, spl_format } from "..";
import { useQuery } from "react-query";
import LicenseConfigureServices from "../../../../services/API/LicenseConfiguration";
import { Modal, Button, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import Pagination from "react-js-pagination";
import AddSubStatus from "../Modals/AddSubStatus";
export const validateSub = (data) => {
  if (!data.license_status_id || data.license_status_id === "select")
    return "Please select Status";
  if (!data.name) return "Please enter sub status name";
  if (data.name.length < 1 || data.name.length > 500) {
    return "Please enter valid input with 1-500 characters";
  }
  if (!spl_format.test(data.name))
    return "Please enter only alphabets in this field";
};
export default function SubStatuses() {
  const value = useContext(LicenseConfigureContext);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [statusId, setStatusId] = useState(null);
  const [del, setDel] = useState(false);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchkey] = useState(null);
  const [myData, setMyData] = useState([]);
  const [page, setPage] = useState(1);
  const [exported, setExported] = useState("");
  const [openItemIndex, setOpenItemIndex] = useState([]);
  const [filter, setFilter] = useState(false);
  let subStatusIndex = 0;
  const postsPerPage = 20;
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  useEffect(() => {
    const getStatus = async () => {
      const { data } = await LicenseConfigureServices.getActiveStatusList();
      setStatuses(data);
    };
    getStatus();
  }, []);

  function toggleRow(id) {
    if (openItemIndex.includes(id)) {
      setOpenItemIndex(openItemIndex.filter((item) => item !== id));
    } else {
      setOpenItemIndex((prevArray) => [...prevArray, id]);
    }
  }

  const queryKey = "api/license/license-sub-status";
  const {
    data: subStatusList,
    isLoading: loading,
    isSuccess: success,
    isFetching,
    refetch,
  } = useQuery([queryKey], LicenseConfigureServices.getSubStatusList, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentLists = Array.isArray(myData)
    ? myData?.slice(indexOfFirstPost, indexOfLastPost)
    : subStatusList?.data?.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    if (subStatusList?.data) {
      setMyData(subStatusList?.data);
    }
  }, [subStatusList]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    console.log(searchkey)
    console.log(displaySearchField)
  }, [page]);

  const handleExport = async () => {
    const resp = await LicenseConfigureServices.exportSubStatus();
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Sub-Statuses.csv");
      toast.success("Sub Statuses Downloaded Successfully");
      link.click();
    }
  };
  const handleDelete = async (status) => {
    const status1 = status.status === 1 ? 0 : 1;
    try {
      const response = await LicenseConfigureServices.deactivateSubStatus(
        status?.id,
        {
          status: status1,
        }
      );
      if (response) {
        const message =
          status?.status === 1
            ? "Sub Status Deactivated Successfully"
            : "Sub Status Activated Successfully";
        toast.success(message);
        setDel(null);
        if(name || statusId){
          applyFilter(page)
      }else if(searchkey){
        handleSearch({target:{value:searchkey}},page)
      }else{
        refetch();
      }
      }
    } catch (er) {
      console.log(er);
      toast.error(er);
    }
  };
  const handleEdit = async (status) => {
    try {
      const statusData = await LicenseConfigureServices.editSubStatus(
        status?.id
      );
      value.setShow("subStatus");
      setId(status?.id);
      setEdit(statusData?.data);
    } catch (er) {
      toast.error(er);
    }
  };

  const applyFilter = async (page) => {
    const payload = { name, license_status_id: statusId };
  
    const error = Object.values(payload).filter((dt) => dt !== null);
    if (!error.length) {
      toast.error("Please fill out the fields");
      return;
    }
    try {
      const response = await LicenseConfigureServices.filterSubStatus(payload);
      if (response?.data) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setFilter(false);
        if(!page){
          setPage(1);
        }
        setMyData(response?.data);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const clearFilter = () => {
    setDisplaySearchField(false);
    setSearchkey(null);
    setMyData(subStatusList?.data);
    setName(null);
    setStatusId(null);
    setPage(1);
  };
  const handleSearch = async (e,page) => {
    const value = e.target.value;
    setSearchkey(value);

    const payload = { name: value, license_status_id: null };
    const { data } = await LicenseConfigureServices.filterSubStatus(payload);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if(!page){
      setPage(1);
    }
    setMyData(data);
  };
  const handleHide = () => {
    setFilter(false);
  };

  return (
    <div style={{ borderTop: "0px", paddingTop: "30px" }}>
      <div
        style={{
          paddingTop: "30px",
        }}
        className="d-flex justify-content-end align-items-center gap-2 p-2"
      >
        {subStatusList?.data.length !== myData?.length && (
          <button
            type="button"
            className="button_new pull-right"
            onClick={clearFilter}
          >
            Clear Filters
          </button>
        )}{" "}
        {displaySearchField ? (
          <input
            type="text"
            className="button_new pull-right "
            value={searchkey}
            onChange={handleSearch}
            // disabled={workflow === "expert"}
            placeholder="Search"
            // onBlur={() => setDisplaySearchField(false)}
          />
        ) : (
          <a
            className=" button_new pull-right"
            onClick={() => {
              setDisplaySearchField(true);
            }}
            onKeyDown={() => {
              setDisplaySearchField(true);
            }}
            title="Search"
          >
            <i class="fa fa-search requests-icons" aria-hidden="true"></i>
          </a>
        )}
        <button
          className=" button_new pull-right"
          title="Show Filters"
          onClick={() => setFilter(true)}
        >
          <i className="fa fa-filter fa-fw requests-icons"></i>
        </button>
        <button
          className=" button_new pull-right"
          title="Download"
          onClick={handleExport}
        >
          <i className="fa fa-download fa-fw requests-icons"></i>
        </button>
        <a
          className=" button_new pull-right"
          onClick={() => value.setShow("subStatus")}
          onKeyDown={() => value.setShow("subStatus")}
          title="Add Sub Status"
        >
          <i className="fa fa-plus fa-fw requests-icons"></i>
        </a>
      </div>

      <div className="table-responsive ">
        <table className="table  table-bordered table-hover">
          <thead>
            <tr style={{ fontWeight: 700 }}>
              {/* <th className="text-center" width="8%">
                S. No.
              </th> */}
              <th className="text-center">Name</th>
              {/* <th className="text-center">Status</th> */}
              <th className="text-center" width="80px">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentLists?.length !== 0 ? (
              currentLists?.map((status, index) => {
                if (status.sub_status.length > 0) {
                  return (
                    <>
                      <tr>
                        <td
                          colSpan={2}
                          className="text-start cursor-pointer	"
                          onClick={() => {
                            toggleRow(index);
                          }}
                        >
                          <strong>
                            {/* {status?.sub_status.length !== 0 && ( */}
                            <i
                              className={`fa ${
                                openItemIndex.includes(index)
                                  ? "fa-minus-circle txt-fail"
                                  : "fa-plus-circle txt-success"
                              } fa-fw`}
                            ></i>
                            {/* )} */}
                            {status?.name}
                          </strong>
                        </td>
                      </tr>
                      {
                        status?.sub_status.length !== 0
                          ? status?.sub_status?.map((st) => {
                              subStatusIndex++;
                              return (
                                <tr
                                  className={`${
                                    openItemIndex.includes(index)
                                      ? ""
                                      : "d-none"
                                  }`}
                                >
                                  {/* <td
                                    className="text-start align-middle"
                                    style={{ paddingLeft: "60px" }}
                                  >
                                    {subStatusIndex}
                                  </td> */}
                                  <td
                                    className="text-start align-middle"
                                    style={{ paddingLeft: "60px" }}
                                  >
                                    {st.name}
                                  </td>
                                  {/* {st.status === 1 ? (
                                    <td className="text-start align-middle">
                                      <i className="fa fa-circle fa-fw txt-success"></i>
                                      Active
                                    </td>
                                  ) : (
                                    <td className="text-start align-middle">
                                      <i className="fa fa-circle fa-fw txt-fail"></i>
                                      Inactive
                                    </td>
                                  )} */}
                                  <td className="text-start align-middle">
                                    <a
                                      title="Edit"
                                      onClick={() => handleEdit(st)}
                                      onKeyDown={() => handleEdit(st)}
                                    >
                                      <i className="fa fa-edit fa-fw"></i>
                                    </a>

                                    {st.status === 1 ? (
                                      <i
                                        className="fa fa-circle fa-fw txt-success pe-auto"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        title="Deactivate"
                                        onClick={() => setDel(st)}
                                        onKeyDown={() => setDel(st)}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-circle fa-fw txt-fail"
                                        title="Activate"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => setDel(st)}
                                        onKeyDown={() => setDel(st)}
                                      ></i>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          : null
                        // (
                        //   <tr
                        //     className={`${
                        //       openItemIndex.includes(index) ? "" : "d-none"
                        //     }`}
                        //   >
                        //     <td colSpan="5" style={{ textAlign: "center" }}>
                        //       No Sub Status
                        //     </td>
                        //   </tr>
                        // )
                      }
                    </>
                  );
                } else {
                  return null;
                }
              })
            ) : (
              <tr>
                <td colSpan={2} className="p-2 text-center">
                  {loading || isFetching ? "Loading..." : "No data found"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {myData.length > postsPerPage && (
          <Pagination
            activePage={page}
            itemsCountPerPage={postsPerPage}
            totalItemsCount={myData.length}
            pageRangeDisplayed={postsPerPage}
            onChange={handlePageChange}
          />
        )}
      </div>

      <Modal
        show={!!del}
        onHide={() => setDel(null)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to {del?.status === 1 ? "deactivate " : "activate "}
            this sub status?
          </p>
          <div className="d-flex gap-3 justify-content-end">
            <Button
              variant="danger"
              className="px-3 py-2"
              onClick={() => setDel(null)}
            >
              No
            </Button>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={() => handleDelete(del)}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Offcanvas show={filter} placement="end" onHide={handleHide}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h3>Filter </h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modall_body">
          <div className="container-fluid filter-box pt-3  filterScroll">
            <div className="form-group">
              <label className="tags">Main Status</label>
              <select
                className="form-control mb-3"
                value={statusId}
                onChange={(e) => setStatusId(e.target.value)}
              >
                <option value={"select"}>Select Main Status</option>
                {statuses?.map((st) => (
                  <option key={st?.id} value={st?.id}>
                    {st.name}
                  </option>
                ))}
              </select>{" "}
            </div>
            <div className="form-group">
              <label htmlFor="tags"> Sub Status</label>
              <input
                type="text"
                className="form-control "
                placeholder="Enter Sub Status name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <section className="pull-right m-4" 
          onClick={()=>applyFilter()}
          onKeyDown={()=>applyFilter()}
          >
            <a className="btn button_new_success p-3">Apply</a>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
      <AddSubStatus
        refetch={refetch}
        editData={edit}
        setEdit={setEdit}
        clearFilter={clearFilter}
        applyFilter={applyFilter}
        filtersAvailable={name || statusId}
        searchkey={searchkey}
        handleSearch={handleSearch}
      />
    </div>
  );
}
