import React, { useContext, useEffect, useState } from "react";
import AdminLayout from "../../../components/layouts/adminLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import { useNavigate } from "react-router";
import Editor from "../../../components/common/Editor/Editor";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import StaticPageService from "../../../services/API/StaticPageService";
import AdminPageHeader from "../../../components/AdminPageHeader";
import { editorRegex } from "../../../utils/utils";
import LicenseConfigureServices from "../../../services/API/LicenseConfiguration";

import Pagination from "react-js-pagination";
import { LicenseConfigureContext } from "../LicenseConfiguration";
import AddLicenses from "../LicenseConfiguration/Modals/AddLicenses";
import { Modal } from "react-bootstrap";
import { validateLicense } from "../LicenseConfiguration/licenceTabs/Licenses";

export default function LicenseContent() {
  // const value = useContext(LicenseConfigureContext);

  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchkey] = useState(null);
  const [page, setPage] = useState(1);
  const [myData, setMyData] = useState([]);
  const [id, setId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [filter, setFilter] = useState(false);
  const [catId, setCatId] = useState(null);
  const [shortlist, setShortlist] = useState("");
  const [name, setName] = useState(null);
  const [short, setShort] = useState(null);

  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [timer, setTimer] = useState(null);
  const [licenseContent, setLicenseContent] = useState(null);
  const [licenseImportantPoints, setLicenseImportantPoints] = useState(null);
  const [licenseFaq, setLicenseFaq] = useState(null);
  const [licenseDocumentContent, setLicenseDocumentContent] = useState(null);

  const postsPerPage = 20;

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const queryKey = "api/license";
  const {
    data: licenseList,
    isLoading: loading,
    isSuccess: success,
    isFetching,
    refetch,
  } = useQuery([queryKey], LicenseConfigureServices.getLicenseList, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentLists = Array.isArray(myData)
    ? myData?.slice(indexOfFirstPost, indexOfLastPost)
    : licenseList?.data?.slice(indexOfFirstPost, indexOfLastPost);
  useEffect(() => {
    if (licenseList?.data) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setMyData(licenseList?.data);
    }
  }, [licenseList]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);

  const navigate = useNavigate();

  const handleEdit = async (license) => {
    try {
      const licenseData = await LicenseConfigureServices.editLicense(
        license?.id
      );

      setId(license?.id);
      setEdit(licenseData?.data);
      if (licenseData?.data) {
        setShow(true);
      }
    } catch (er) {
      toast.error(er);
    }
  };

  useEffect(() => {
    if (edit) {
      setName(edit?.name);
      setShort(edit?.short_name);
      setCatId(edit?.license_category_id);
      setShortlist(edit?.shortlist);
      setLicenseContent(edit?.license_content);
      setLicenseImportantPoints(edit?.license_important_points);
      setLicenseFaq(edit?.license_faq);
      setLicenseDocumentContent(edit?.license_document_content);
    }
  }, [edit]);

  const clearFilter = () => {
    setDisplaySearchField(false);
    setSearchkey(null);
    setMyData(licenseList?.data);
    setName(null);
    setShort(null);
    setCatId(null);
    setShortlist(null);
    setPage(1);
  };
  const handleSearch = async (e, page) => {
    const value = e.target.value;
    setSearchkey(value);
    const payload = { name: value };
    const { data } = await LicenseConfigureServices.searchLicense(payload);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (!page) {
      setPage(1);
    }
    setMyData(data);
  };

  const updateLicence = async () => {
    const payload = {
      name,
      short_name: short,
      license_category_id: catId,
      shortlist,
      license_content: licenseContent,
      license_important_points: licenseImportantPoints,
      license_faq: licenseFaq,
      license_document_content: licenseDocumentContent,
    };
    let staticContent = true 
    const error = validateLicense(payload,staticContent);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LicenseConfigureServices.updateLicense(
        edit?.id,
        payload
      );
      if (response) {
        toast.success("License Content updated successfully");
        // clearFilter();
        handleHide();
        if (searchkey) {
          handleSearch({ target: { value: searchkey } }, 1);
        } else {
          refetch();
        }
        setImmediate(null);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const submitForm = () => {
    if (edit) {
      updateLicence();
    }
  };

  const handleHide = () => {
    setShow(false);
    setName(null);
    setShort(null);
    setCatId(null);
    setShortlist(null);
    setEdit(null);
    setLicenseContent(null);
    setLicenseImportantPoints(null);
    setLicenseFaq(null);
    setLicenseDocumentContent(null);
  };

  return (
    <AdminLayout test="active">
      <AdminPageContainer>
        <div id="main" role="main">
          <div id="content">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item ">
                  <a
                    style={{
                      color: "blue",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/system-admin/license-master")}
                    onKeyDown={() => navigate("/system-admin/license-master")}
                  >
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active">Static Pages</li>

                <li className="breadcrumb-item active">License Content</li>
              </ol>
            </div>

            <div>
              <div className="row">
                <div className="card card-sm card-shadow mb-3">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                    <h2
                        style={{
                          fontWeight: "bold",
                          marginBottom: "0",
                        }}
                      >
                        License Content
                      </h2>
                    </div>

                    <div style={{ borderTop: "0px" }}>
                      <div
                        style={{
                          paddingTop: "30px",
                        }}
                        className="d-flex justify-content-end align-items-center gap-2 p-2"
                      >
                        {licenseList?.data.length !== myData?.length && (
                          <button
                            type="button"
                            className="button_new pull-right"
                            onClick={clearFilter}
                          >
                            Clear Filters
                          </button>
                        )}
                        {displaySearchField ? (
                          <input
                            type="text"
                            className="button_new pull-right "
                            value={searchkey}
                            onChange={handleSearch}
                            // disabled={workflow === "expert"}
                            placeholder="Search"
                          />
                        ) : (
                          <a
                            className=" button_new pull-right"
                            onClick={() => {
                              setDisplaySearchField(true);
                            }}
                            onKeyDown={() => {
                              setDisplaySearchField(true);
                            }}
                          >
                            <i
                              class="fa fa-search requests-icons"
                              aria-hidden="true"
                              title="Search"
                            ></i>
                          </a>
                        )}
                      </div>

                      <div className="table-responsive ">
                        <table
                          className="table  table-bordered table-hover dataTable no-footer"
                          style={{ tableLayout: "fixed" }}
                        >
                          <thead>
                            <tr role="row" style={{ fontWeight: 700 }}>
                              <th className="text-center" width="34px">
                                S. No.
                              </th>
                              <th className="text-center">License Name</th>
                              <th className="text-center" width="50px">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentLists?.length !== 0 ? (
                              currentLists?.map((license, index) => {
                                return (
                                  <tr key={license?.id}>
                                    <td className="text-start align-middle">
                                      {postsPerPage * (page - 1) + index + 1}
                                    </td>
                                    <td className="text-start align-middle">
                                      {license?.name}
                                    </td>

                                    {/* {license.status === 1 ? (
                      <td className="text-start align-middle">
                        <i className="fa fa-circle fa-fw txt-success"></i>Active
                      </td>
                    ) : (
                      <td className="text-start align-middle">
                        <i className="fa fa-circle fa-fw txt-fail"></i>Inactive
                      </td>
                    )} */}
                                    <td className="text-start align-middle">
                                      <a
                                        title="Add"
                                        onClick={() => handleEdit(license)}
                                        onKeyDown={() => handleEdit(license)}
                                      >
                                        <i className="fa fa-edit fa-fw cursor-pointer"></i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={3} className="p-2 text-center">
                                  {loading || isFetching
                                    ? "Loading..."
                                    : "No data found"}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {myData.length > postsPerPage && (
                          <Pagination
                            activePage={page}
                            itemsCountPerPage={postsPerPage}
                            totalItemsCount={myData.length}
                            pageRangeDisplayed={postsPerPage}
                            onChange={handlePageChange}
                          />
                        )}
                      </div>

                      {show && (
                        <Modal
                          show={show}
                          animation={false}
                          onHide={handleHide}
                          dialogClassName="modal-lg"
                        >
                          <Modal.Header closeButton>
                            <h3 className="modal-title">Edit License</h3>
                          </Modal.Header>
                          <Modal.Body
                            style={{ height: "80vh", overflowY: "scroll" }}
                          >
                            <div className="form-group">
                              <label htmlFor="tags">
                                {" "}
                                License Name{" "}
                                <span style={{ color: "red" }}> *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control mb-3"
                                placeholder="Enter License Name"
                                value={name}
                                disabled={true}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="tags">
                                {" "}
                                Short Name{" "}
                                <span style={{ color: "red" }}> *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control mb-3"
                                placeholder="Enter Short Name"
                                value={short}
                                maxLength={3}
                                disabled={true}
                                onChange={(e) => setShort(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="tags">
                                {" "}
                                Menu <span style={{ color: "red" }}> *</span>
                              </label>

                              <select
                                className="form-control mb-3"
                                id="Shortlist"
                                value={catId}
                                disabled={true}
                                onChange={(e) => setCatId(e.target.value)}
                              >
                                <option value={""}>Choose Menu</option>
                                {categories?.map((ct) => (
                                  <option key={ct?.id} value={ct?.id}>
                                    {ct.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="tags">
                                {" "}
                                Shortlist{" "}
                                <span style={{ color: "red" }}> *</span>
                              </label>

                              <select
                                className="form-control"
                                id="Shortlist"
                                disabled={true}
                                value={shortlist}
                                onChange={(e) => setShortlist(e.target.value)}
                              >
                                <option value="">Select Value</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </select>
                              <small>
                                If marked as YES, the item will display on
                                website home page
                              </small>
                            </div>
                            <div className="form-group">
                              <label htmlFor="tags"> License Content</label>
                              <Editor
                                data={licenseContent}
                                setData={setLicenseContent}
                                showSaveBtn={false}
                                // handleSave={handleSave}
                                small={true}
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="tags">
                                {" "}
                                License Important Points
                              </label>
                              <Editor
                                data={licenseImportantPoints}
                                setData={setLicenseImportantPoints}
                                showSaveBtn={false}
                                // handleSave={handleSave}
                                small={true}
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="tags"> License FAQs</label>
                              <Editor
                                data={licenseFaq}
                                setData={setLicenseFaq}
                                showSaveBtn={false}
                                // handleSave={handleSave}
                                small={true}
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="tags">
                                {" "}
                                License Document Content{" "}
                                <span style={{ color: "red" }}> *</span>
                              </label>
                              <Editor
                                data={licenseDocumentContent}
                                setData={setLicenseDocumentContent}
                                showSaveBtn={false}
                                // handleSave={handleSave}
                                small={true}
                              />
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              type="button"
                              className="btn button_new_success"
                              onClick={submitForm}
                            >
                              Save
                            </button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminPageContainer>
    </AdminLayout>
  );
}
