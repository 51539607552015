import React, { useContext, useState } from "react";
import CustomFilter from "../../../components/common/CustomFilter/CustomFilter";
import { Multiselect } from "multiselect-react-dropdown";
import { multiSelectStyle } from "../../../components/CreateExecutive/MultiSelectStyle";
import { useQuery } from "react-query";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import VendorManagerServices from "../../../services/API/VendorManager";
import { Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import DateRangePicker from "../../../components/common/DateRangePicker";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/authContext";
import { ROLES } from "../../../constant/roles";
import { filterCitiesFromData } from "../../../utils/utils";
const DashboardFilters = ({
  show,
  onHide,
  setFilters,
  setPage,
  filters,
  results,
  setSearchKey,
  setDisplaySearchField,
}) => {
  const [filtersStates, setFilterStates] = useState({ ...filters });
  // const [cityList, setCityList] = useState([]);

  function decode(str) {
    if (str?.length > 23) return str.slice(0, 23) + "...";
    else return str;
  }

  const {
    data: cityList,
    isLoading: isCitiesLoading,
    isFetched,
  } = useQuery(
    [
      "/api/city_filter",
      { state_id: filtersStates["assigned-state"]?.map((item) => item?.id) },
    ],
    VendorManagerServices.filterCity,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        const filteredData = filterCitiesFromData(
          data?.data,
          filtersStates["assigned-city"]
        );
        if (filteredData?.length) {
          setFilterStates({
            ...filtersStates,
            "assigned-city": filteredData,
          });
        }
      },
      // enabled: !!filtersStates?.states?.length,
    }
  );

  



  const handleApplyFilter = () => {
    if (Object.keys(filtersStates)?.length > 0) {
      setPage(1);
      setSearchKey("");
      setDisplaySearchField(false);
      setFilters({ ...filtersStates });
      onHide();
    } else {
      toast.error("Please fill out the fields");
    }
  };

  return (
    <CustomFilter show={show} onHide={onHide} heading="Filter">
      <div className="filter-box filterScroll pr-3">
        <div className="form-check form-switch track-switch mt-2 mb-4">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            onChange={() =>
              setFilterStates({
                ...filtersStates,
                srn_fasttrack:
                  filtersStates?.srn_fasttrack == "0" ||
                  !filtersStates?.srn_fasttrack
                    ? "1"
                    : "0",
              })
            }
            checked={filtersStates?.srn_fasttrack == "1"}
          />
          <span>Show Fast Track Requests</span>
        </div>
        <div className="my-2">
          <span>Type Of Request </span>

          <Multiselect
            options={results[0]?.data?.data || []}
            placeholder={"Select type of request"}
            displayValue="name"
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "license-type": selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "license-type": selectedList,
              });
            }}
            selectedValues={filtersStates["license-type"]}
            className="dropdown"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
            hidePlaceholder
          />
        </div>

        <div className="py-2">
          <span>State</span>

          <Multiselect
            options={results[1]?.data?.data || []}
            placeholder={"Select state"}
            displayValue="state_name"
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "assigned-state": selectedList,
              });
              // getCities(selectedList);
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "assigned-state": selectedList,
              });
              // getCities(selectedList);
            }}
            selectedValues={filtersStates["assigned-state"]}
            className="dropdown"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
            hidePlaceholder
          />
        </div>

        <div className="py-2">
          <span>City</span>

          <Multiselect
            options={cityList?.data}
            displayValue="name"
            disable={!filtersStates["assigned-state"]?.length}
            placeholder={filtersStates.cities?.length > 0 ? "" : "Select city"}
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "assigned-city": selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "assigned-city": selectedList,
              });
            }}
            selectedValues={filtersStates["assigned-city"]}
            className="dropdown"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
            hidePlaceholder
          />
        </div>

        <div className="py-2">
          <span>License</span>

          <Multiselect
            options={results[2]?.data?.data || []}
            placeholder="Select license"
            displayValue="name"
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "l-name": selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "l-name": selectedList,
              });
            }}
            selectedValues={filtersStates["l-name"]}
            className="dropdown"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
            hidePlaceholder
          />
        </div>


        {/* <div className="py-2">
              <span>Field Executive</span>

              <Multiselect
                options={FExecutivesList?.data || []}
                placeholder={"Select field executive"}
                displayValue="name"
                onSelect={(selectedList, selectedItem) => {
                  setFilterStates({
                    ...filtersStates,
                    field_executives: selectedList,
                  });
                }}
                onRemove={(selectedList, selectedItem) => {
                  setFilterStates({
                    ...filtersStates,
                    field_executives: selectedList,
                  });
                }}
                selectedValues={filtersStates["field_executives"]}
                className="dropdown"
                style={multiSelectStyle}
                selectedValueDecorator={(str) => decode(str)}
                hidePlaceholder
              />
            </div> */}





        <div className="py-2">
          <span>Menu</span>

          <Multiselect
            options={results[5]?.data?.data}
            placeholder={
              filtersStates["license-category"]?.length > 0
                ? ""
                : "Please select menu"
            }
            displayValue="name"
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "license-category": selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "license-category": selectedList,
              });
            }}
            selectedValues={filtersStates["license-category"]}
            className="dropdown"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
          />
        </div>

        <div className="py-2">
          <span>Requested Date</span>
          <DateRangePicker
            startDate={filtersStates?.requested_from_date}
            endDate={filtersStates?.requested_to_date}
            onChange={(selection) => {
              setFilterStates({
                ...filtersStates,
                requested_from_date: selection?.startDate,
                requested_to_date: selection?.endDate,
              });
            }}
          />
        </div>

        <div className="py-2">
          <span>Requested By</span>

          <Multiselect
            options={results[4]?.data}
            placeholder={
              filtersStates["u-name"]?.length > 0 ? "" : "Select requested by"
            }
            displayValue="name"
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "u-name": selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "u-name": selectedList,
              });
            }}
            selectedValues={filtersStates["u-name"]}
            className="dropdown multiSelectClass"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
          />
        </div>

        <div className="py-2">
          <span>Status</span>

          <Multiselect
            options={results[3]?.data?.data || []}
            placeholder={"Select status"}
            displayValue="name"
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "license-status": selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "license-status": selectedList,
              });
            }}
            selectedValues={filtersStates?.["license-status"]}
            className="dropdown multiSelectClass"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
            hidePlaceholder
          />
        </div>
      </div>
      <section
        className="pull-right m-4"
        onClick={() => {
          handleApplyFilter();
        }}
        onKeyDown={() => {
          handleApplyFilter();
        }}
      >
        <a className="btn button_new_success">Apply</a>
      </section>
    </CustomFilter>
  );
};

export default DashboardFilters;
