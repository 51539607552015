import React, { useContext, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { LocationSettingsContext } from "..";
import LocationSettingsServices from "../../../../services/API/LocationSettings";
import { toast } from "react-toastify";
import { validateMC } from "../locationTabs/MuncipalCorporation";
import { useQuery } from "react-query";
export default function AddMC({ refetch, editData, setEdit, clearFilter,searchkey,handleSearch,applyFilter,filtersAvailable }) {
  const { show, setShow } = useContext(LocationSettingsContext);
  const [name, setName] = useState(null);
  const [states, setStates] = useState([]);
  const [zones, setZones] = useState([]);
  const [citys, setCitys] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [zoneId, setZoneId] = useState("");
  const [cityId, setCityId] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const {
    data: latLongByPincode,
    isLoading,
    isFetching,
    refetch: latLongRefetch,
  } = useQuery(
    ["/api/map/search-lat-lng", { pin_code: pincode }], //
    LocationSettingsServices.getLatLong,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  useEffect(() => {
    if (latLongByPincode?.data) {
      setLatitude(latLongByPincode?.data?.latitude);
      setLongitude(latLongByPincode?.data?.longitude);
    } else {
      setLatitude("");
      setLongitude("");
    }
  }, [latLongByPincode]);

  const getStates = async () => {
    const response = await LocationSettingsServices.getActiveState();
    if (response) {
      setStates(response?.data);
    }
  };
  const getZones = async () => {
    const response = await LocationSettingsServices.getActiveZone();
    if (response) {
      setZones(response?.data);
    }
  };

  useEffect(() => {
    getStates();
    getZones();
    // getCities()
  }, []);

  useEffect(() => {
    if (editData) {
      setName(editData?.name);
      setStateId(editData?.state_id);
      // setZoneId(editData?.zone_id);
      setCityId(editData?.city_id);
      setPincode(editData?.pin_code);
      getCities(editData?.state_id);
      setLatitude(editData?.latitude);
      setLongitude(editData?.longitude);
    }
  }, [editData]);
  const addMC = async () => {
    const payload = {
      municipal_name: name,
      // zone_id: zoneId,
      state_id: stateId,
      city_id: cityId,
      pin_code: pincode,
    };

    if (latitude) {
      payload.latitude = latitude;
    }
    if (longitude) {
      payload.longitude = longitude;
    }

    const error = validateMC(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const resp = await LocationSettingsServices.storeMC(payload);
      if (resp?.status_code === 200) {
        toast.success("Municipal corporation created successfully");
        clearFilter();
        refetch();
        handleHide();
      }

      if (resp?.status_code === 403) {
        toast.error(resp?.message);
      }
    } catch (e) {
      toast.error(e);
    }
  };
  const getCities = async (value) => {
    // const { value } = e.target;
    setStateId(value);

    try {
      const resp = await LocationSettingsServices.getActiveCity({
        state_id: value,
      });
      if (resp) {
        console.log(resp?.data);
        setCitys(resp?.data);
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  const updateMC = async () => {
    const payload = {
      municipal_name: name,
      // zone_id: zoneId,
      state_id: stateId,
      city_id: cityId,
      pin_code: pincode,
    };
    if (latitude) {
      payload.latitude = latitude;
    }
    if (longitude) {
      payload.longitude = longitude;
    }

    const error = validateMC(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LocationSettingsServices.updateMC(
        editData?.id,
        payload
      );
      if (response) {
        toast.success("Municipal corporation updated successfully");
        // clearFilter();
        handleHide();
        if(filtersAvailable){
          applyFilter(1)
        }else if(searchkey){
          handleSearch({target:{value:searchkey}},1)
        }else{
          refetch();
        }
        setEdit(null);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const submitForm = () => {
    if (editData) {
      updateMC();
    } else {
      addMC();
    }
  };
  const handleHide = () => {
    setShow(null);
    setName(null);
    setStateId(null);
    // setZoneId("");
    setCityId(null);
    setPincode(null);
    setEdit(null);
  };
  return (
    <Modal show={show === "MC"} animation={false} onHide={handleHide}>
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {editData
            ? "Edit Municipal Corporation"
            : "Add Municipal Corporation"}
        </h3>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group padding-top-10">
          <label htmlFor="tags">
            State<span style={{ color: "red" }}> *</span>
          </label>
          <select
            className="form-control full-width"
            value={stateId}
            onChange={(e) => {
              setCityId(null);
              getCities(e.target.value);
            }}
          >
            <option value={null}>Select State</option>
            {states?.map((st) => (
              <option key={st?.id} value={st?.id}>
                {st.state_name}
              </option>
            ))}
          </select>{" "}
          <i></i>{" "}
        </div>
        <div className="form-group padding-top-10">
          <label htmlFor="tags">
            City<span style={{ color: "red" }}> *</span>
          </label>
          <select
            className="form-control full-width"
            value={cityId}
            onChange={(e) => setCityId(e.target.value)}
          >
            <option value="">Select City</option>
            {citys?.map((ct) => (
              <option key={ct?.id} value={ct?.id}>
                {ct.name}
              </option>
            ))}
          </select>{" "}
          <i></i>{" "}
        </div>
        <div className="form-group padding-top-10">
          <label htmlFor="tags">
            Municipal Corporation<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Municipal Corporation Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group padding-top-10">
          <label htmlFor="tags">
            {" "}
            Pincode<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            maxLength={6}
            className="form-control"
            placeholder="Enter Pincode"
            value={pincode}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, "");
              setPincode(value);
            }}
          />
        </div>
        <div className="form-group padding-top-10">
          <label htmlFor="tags"> Latitude</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Latitude"
            value={latitude}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, "");
              setLatitude(value);
            }}
          />
        </div>
        <div className="form-group padding-top-10">
          <label htmlFor="tags"> Longitude</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Longitude"
            value={longitude}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, "");
              setLongitude(value);
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn button_new_success"
          onClick={submitForm}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
