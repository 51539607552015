import React from "react";
import { Offcanvas } from "react-bootstrap";

const CustomFilter = ({ show, onHide, header = true, heading, children }) => {
  return (
    <Offcanvas
      show={show}
      onHide={onHide}
      placement="end"
      // style={{ maxWidth: "320px" }}
      backdropClassName="darkshadow"
      className="rounded-top mt-1"
    >
      {header && (
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h3>{heading} </h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
      )}

      <Offcanvas.Body className="modall_body scrollBody">
        {children}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CustomFilter;
