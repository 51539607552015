import moment from "moment";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { CustomInput } from "../../../utils/utils";

export default function FilterExecutiveOOO({
  filterStatus,
  setFilterStatus,
  filterData,
  setFilterData,
  handleChange,
  handleFilter,
  initialFilters,
  setfilteredData,
  filteredData,
  setOpenFilterBox,
  openFilterBox
}) {
  const handleHide = () => {
    // (initialFilters)
    if(!filterStatus){
      setfilteredData(initialFilters)
    }
    // setFilterStatus(false);
    setOpenFilterBox(false)

  };


  

  return (
    <Offcanvas
      show={openFilterBox}
      onHide={handleHide}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          {" "}
          <h3>Filter </h3>{" "}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modall_body">
        <div className="container-fluid filter-box pt-3 ">
          <div className="smart-form">
          {/* <section>
              <label className="label">ID</label>
              <label className="input">
                <input
                  type="text"
                  name="id"
                  value={filterData?.id}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter ID"
                />
              </label>
            </section> */}
            <section>
              <label className="label">Name</label>
              <label className="input">
                <input
                  type="text"
                  name="name"
                  value={filterData?.name}
                  onChange={(e)=>{
                    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
                      setfilteredData({...filteredData,name:e.target.value})
                    }
                   
                  }}
                  className="form-control"
                  placeholder="Enter Name"
                />
              </label>
            </section>
            <section>
              <label className="label">Out of Office From</label>
              <DatePicker
                // minDate={moment().toDate()}
                selected={filterData?.start_date}
                onChange={(e) =>
                setfilteredData({ ...filteredData, start_date: e })
                }
                value={filterData?.start_date && moment(filterData?.start_date).format("DD-MM-YY")}
                dateFormat="dd-MM-yyyy"
                className="py-2 px-2"
                customInput={<CustomInput placeholderText={"Select Out of Office From date"} icon={false}/>}

        
                
              />
            </section>

            <section>
              <label className="label">Out of Office Till</label>
              <DatePicker
                // minDate={moment().toDate()}
                selected={filterData?.end_date}
                onChange={(e) => setfilteredData({ ...filteredData, end_date: e })}
                type="date"
                value={filterData?.end_date && moment(filterData?.end_date).format("DD-MM-YY")}
                dateFormat="dd-MM-yyyy"
                className="py-2 px-2"
                customInput={<CustomInput placeholderText={"Select Out of Office Till date"} icon={false}/>}

              />
            </section>
            <section>
              <label className="label">Activities Pending</label>
              <label className="input">
                <input
                  type="text"
                  name="activity_pending"
                  value={filterData?.activity_pending}
                  onChange={(e)=>{
                    const value = e.target.value.replace(/\D/g, "");
                    setfilteredData({...filteredData,activity_pending:value})
                  }}
                  className="form-control"
                  placeholder="Enter Activity Pending"
                />
              </label>
            </section>


            <section>
              <label className="label">Mobile Number</label>
              <label className="input">
                <input
                  type="text"
                  name="mobile_no"
                  value={filterData?.mobile_no}
                  maxLength={10}
                  onChange={(e)=>{
                    const value = e.target.value.replace(/\D/g, "");
                    setfilteredData({...filteredData,mobile_no:value})
                   
                  }}
                  className="form-control"
                  placeholder="Enter Mobile Number"
                />
              </label>
            </section>


           


          </div>
        </div>
        <section className="pull-right m-4" 
        onClick={handleFilter}
        onKeyDown={handleFilter}
        >
          <a className="btn button_new_success p-3">Apply</a>
        </section>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
