import React from "react";
import Breadcrumb from "../../../components/common/Breadcrumb";
import AdminPageCard from "../../../components/common/AdminPageCard/AdminPageCard";
import AdminPageHeader from "../../../components/AdminPageHeader";
import AdminTable from "../../../components/common/AdminTable/AdminTable";
import FieldExecutiveServices from "../../../services/API/FieldExecutive";
import { useQuery } from "react-query";
import { useState } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router";
import ADModal from "./ADModal";
import SideFilter from "./SideFilter";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { addDays } from "date-fns";
import useAuth from "../../../hooks/useAuth";

const initial = {
  name: "",
  mobile_no: "",
  email: "",
  executive_name: "",
  is_enabled: "",
  dueDateRange: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ],
  dateSubmit: false,
};
const FieldExecutive = ({ detailsPageLink }) => {
  const auth = useAuth();

  const [adItem, setADItem] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [filters, setFilters] = useState(initial);
  const [loader, setLoader] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [showDuedateRange, setShowDuedateRange] = useState(false);
  const limit = 20;
  const { data, isLoading, isFetching, refetch } = useQuery(
    ["/api/field_executive", { ...filters, limit, page,executiveIds:auth.roleId == 2 ? [auth?.userId]:null     }], //
    FieldExecutiveServices.getFElist,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const breadcrumbs = [
    {
      title: "Home",
      link: "/executive/dashboard",
      isActive: true,
    },
    {
      title: "Field Executives Lists",
      isActive: false,
    },
  ];

  const tableRows = [
    {
      title: "S. No.",
      value: (column, index) => limit * (page - 1) + index + 1,
      thProps: { width: "40px" },
    },
    {
      title: "Name",
      value: (column) => (
        <Link to={`${detailsPageLink}${column?.id}`}>{column?.name}</Link>
      ),
      thProps: { width: "240px" },
    },
    {
      title: "Mobile Number",
      value: (column) => column?.mobile_no && column?.mobile_no?.replace("+91", "") ,
      thProps: { width: "80px" },
    },
    {
      title: "Email ID",
      value: (column) => column?.email,
      thProps: { width: "180px" },
    },
    {
      title: "Executive Name",
      value: (column) => column?.executive_name,
      thProps: { width: "120px" },
    },
    {
      title: "Created On",
      value: (column) => moment(column?.created_on).format("DD-MM-YY hh:mm A"),
      thProps: { width: "120px" },
    },
    {
      title: "Actions",
      value: (column) => (
        <>
          <i
            className="fa fa-edit fa-fw"
            onClick={() =>
              navigate(`edit/${column?.id}`, {
                state: { vendorId: column?.created_by },
              })
            }
            onKeyDown={() =>
              navigate(`edit/${column?.id}`, {
                state: { vendorId: column?.created_by },
              })
            }
            title="Edit"
          ></i>
          <i
            className={`fa fa-circle fa-fw ${
              column?.is_enabled == 0 ? "txt-fail" : "txt-success"
            } pe-auto`}
            style={{
              cursor: "pointer",
            }}
            title="Deactivate"
            onClick={() => setADItem(column)}
            onKeyDown={() => setADItem(column)}
          ></i>
        </>
      ),
      thProps: { width: "50px" },
    },
  ];

  const downloadReport = async () => {
    setLoader("download");
    const resp = await FieldExecutiveServices.downloadReport({executiveIds:auth.roleId == 2 ? [auth?.userId]:null });
    setLoader("");
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Field Executives Lists.csv");
      toast.success("Field Executives Downloaded Successfully");
      link.click();
    }
  };

  const Header = (
    <div className="d-flex gap-2 align-items-center pb-2">
      {(filters?.name ||
        filters?.mobile_no ||
        filters?.email ||
        filters?.executive_name ||
        filters?.is_enabled ||
        filters.dateSubmit ||
        filters?.q) && (
        <div
          type="button"
          className="button_new mt-0"
          onClick={() => {
            setDisplaySearchField(false);
            setFilters(initial);
            setShowDuedateRange(false);
            setPage(1);
          }}
          onKeyDown={() => {
            setDisplaySearchField(false);
            setFilters(initial);
            setShowDuedateRange(false);
            setPage(1);
          }}
          disabled={!!loader}
        >
          Clear Filters
        </div>
      )}

      {displaySearchField ? (
        <input
          type="text"
          className="button_new mt-0"
          value={filters?.q || ""}
          onChange={(e) => {
            setPage(1);
            setFilters((prevState) => {
              if (e.target.value)
                return {
                  ...prevState,
                  q: e.target.value,
                };
              else {
                let newState = { ...prevState };
                delete newState.q;
                return newState;
              }
            });
          }}
          placeholder="Search"
          // onBlur={() => setDisplaySearchField(false)}
        />
      ) : (
        <div
          className=" button_new mt-0"
          onClick={() => {
            setDisplaySearchField(true);
          }}
          onKeyDown={() => {
            setDisplaySearchField(true);
          }}
          title="Search"
        >
          <i className="fa fa-search" aria-hidden="true"></i>
        </div>
      )}
      <div
        onClick={() => setShowFilter(true)}
        onKeyDown={() => setShowFilter(true)}
        target="_blank"
        className=" button_new mt-0"
      >
        <i className="fa fa-filter fa-fw" title="Show Filters"></i>
      </div>
      <div 
      onClick={() => downloadReport()}
      onKeyDown={() => downloadReport()}
       className=" button_new mt-0">
        <i className="fa fa-download" aria-hidden="true" title="Download"></i>
      </div>

      <div
        target="_blank"
        className=" button_new mt-0"
        onClick={() => {
          navigate("create", { state: { vendorId: auth?.createdBy } });
        }}
        onKeyDown={() => {
          navigate("create", { state: { vendorId: auth?.createdBy } });
        }}
      >
        <i className="fa fa-plus fa-fw" title="Add Field Executive"></i>
      </div>
    </div>
  );

  return (
    <div id="main" role="main">
      <div id="content">
        <ADModal adItem={adItem} refetch={refetch} setADItem={setADItem} />
        {showFilter && (
          <SideFilter
            show={showFilter}
            filters={filters}
            setFilters={setFilters}
            onHide={() => setShowFilter(false)}
            setPage={setPage}
            initial={initial}
            showDuedateRange={showDuedateRange}
            setShowDuedateRange={setShowDuedateRange}
          />
        )}
        <Breadcrumb items={breadcrumbs} />
        <AdminPageCard>
          <div className="d-flex justify-content-between align-items-center">
            <AdminPageHeader title="Field Executives Lists" />
            {Header}
          </div>
          <AdminTable
            columns={tableRows}
            data={data?.data?.data || []}
            isDataLoading={isLoading}
          />
          {data?.data?.total > limit && (
            <Pagination
              activePage={data?.data?.current_page}
              itemsCountPerPage={limit}
              totalItemsCount={data?.data?.total}
              pageRangeDisplayed={5}
              onChange={(number) => setPage(number)}
            />
          )}
        </AdminPageCard>
      </div>
    </div>
  );
};

export default FieldExecutive;
