import React, { useContext, useState, useEffect } from "react";
import { LocationSettingsContext, spl_format } from "..";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import LocationSettingsServices from "../../../../services/API/LocationSettings";
//import LocationSettingsServices from "../../../../services/API/LocationSettings";
import { Modal, Button, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
//import { Modal, Button } from "react-bootstrap";
import Pagination from "react-js-pagination";
import AddState from "../Modals/AddState";
import { onlyLetter } from "../../../../utils/utils";
export const validateState = (data) => {
  if (!data.state_name) return "Please enter state name";
  if (data.state_name.length < 3 || data.state_name.length > 500)
    return "Please enter valid input with 3-500 characters";
  if (!spl_format.test(data.state_name))
    return "Please enter only alphabets and numeric characters in this field";
  if (!data?.state_code) return "Please enter state code";
  if (!onlyLetter(data?.state_code))
    return "Please enter only alphabets in this field";
  if (data?.state_code.length > 2 || data?.state_code.length < 2)
    return "State code should be 2 characters long";
};
export default function States() {
  const value = useContext(LocationSettingsContext);
  const queryKey = "api/get_state";
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [page, setPage] = useState(1);
  const [del, setDel] = useState(false);
  const [myData, setMyData] = useState([]);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchkey] = useState(null);
  const [filter, setFilter] = useState(false);
  const [exported, setExported] = useState("");
  const postsPerPage = 20;

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const {
    data: stateLists,
    isLoading: loading,
    isSuccess: success,
    isFetching,
    refetch,
  } = useQuery([queryKey], LocationSettingsServices.getState, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentLists = Array.isArray(myData)
    ? myData?.slice(indexOfFirstPost, indexOfLastPost)
    : stateLists?.data?.slice(indexOfFirstPost, indexOfLastPost);
  useEffect(() => {
    if (stateLists?.data) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setMyData(stateLists?.data);
    }
  }, [stateLists]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);
  const handleDelete = async (state) => {
    const status = state.status === "Active" ? "Inactive" : "Active";
    try {
      await LocationSettingsServices.deactivateState(state?.id, {
        status,
      });
      const message =
        state?.status === "Active"
          ? "State Deactivated Successfully"
          : "State Activated Successfully";
      toast.success(message);
      setDel(null);
      if(searchkey){
        handleSearch({target:{value:searchkey}},page)
      }else{
        refetch();
      }
    } catch (er) {
      toast.error(er);
    }
  };
  const handleEdit = async (state) => {
    try {
      const stateData = await LocationSettingsServices.editState(state?.id);
      // setName(stateData?.data?.state_name);
      setId(state?.id);
      setEdit(stateData?.data);
      value.setShow("state");
    } catch (er) {
      toast.error(er);
    }
  };

  const clearFilter = () => {
    setDisplaySearchField(false);
    setSearchkey(null);
    setMyData(stateLists?.data);
    setPage(1)
  };
  const handleSearch = async (e,page) => {
    const value = e.target.value;
    setSearchkey(value);

    const { data } = await LocationSettingsServices.searchState(value);
    if(!page){
      setPage(1);
    }
    setMyData(data);
  };

  const getExported = async () => {
    let response = await LocationSettingsServices.exportState();
    console.log(response);
    response?.forEach((element, index) => {
      response[index]["S. No"] = index + 1;
    });
    setExported(response ? response : []);
  };

  useEffect(() => {
    getExported();
  }, [stateLists]);

  const handleHide = () => {
    setFilter(false);
  };

  const applyFilter = () => {};

  return (
    <div style={{ borderTop: "0px", paddingTop: "30px" }}>
      <div
        style={{
          paddingTop: "30px",
        }}
        className="d-flex justify-content-end align-items-center gap-2 p-2"
      >
        {searchkey && (
          <button
            type="button"
            className="button_new pull-right"
            onClick={clearFilter}
          >
            Clear Filters
          </button>
        )}
        {displaySearchField ? (
          <input
            type="text"
            className="button_new pull-right "
            value={searchkey}
            onChange={handleSearch}
            onBlur={() => (!searchkey ? setDisplaySearchField(false) : null)}
            // disabled={workflow === "expert"}
            placeholder="Search"
          />
        ) : (
          <a
            className=" button_new pull-right"
            onClick={() => {
              setDisplaySearchField(true);
            }}
            onKeyDown={() => {
              setDisplaySearchField(true);
            }}
          >
            <i
              class="fa fa-search requests-icons"
              aria-hidden="true"
              title="Search"
            ></i>
          </a>
        )}
        {/* <button
          className=" button_new pull-right"
          title="Show Filters"
          onClick={() => setFilter(true)}
        >
          {" "}
          <i className="fa fa-filter fa-fw requests-icons"></i>
        </button> */}

        <CSVLink
          className=" button_new pull-right"
          title="Download"
          filename={"States.csv"}
          data={exported}
          onClick={() => {
            if (exported) toast.success("States Downloaded Successfully");
          }}
        >
          <i className="fa fa-download fa-fw requests-icons"></i>
        </CSVLink>
        <a
          className=" button_new pull-right"
          onClick={() => value.setShow("state")}
          onKeyDown={() => value.setShow("state")}
        >
          <i className="fa fa-plus fa-fw requests-icons" title="Add State"></i>
        </a>
      </div>

      <div className="table-responsive ">
        <table
          className="table table-bordered table-hover dataTable no-footer"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr role="row" style={{ fontWeight: 700 }}>
              <th className="text-center" width="34px">
                S. No.
              </th>
              <th className="text-center">State</th>
              <th className="text-center">Zone</th>
              {/* <th className="text-center">Status</th> */}
              <th className="text-center" width="50px">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentLists?.length !== 0 ? (
              currentLists?.map((state, index) => (
                <tr key={state?.id}>
                  <td className="text-start align-middle">
                    {postsPerPage * (page - 1) + index + 1}
                  </td>
                  <td className="text-start align-middle">
                    {state.state_name}
                  </td>
                  <td className="text-start align-middle">
                    {state?.zone?.zone_name}
                  </td>
                  {/* {state?.status === "Active" ? (
                    <td className="text-start align-middle">
                      <i className="fa fa-circle fa-fw txt-success"></i>Active
                    </td>
                  ) : (
                    <td className="text-start align-middle">
                      <i className="fa fa-circle fa-fw txt-fail"></i>Inactive
                    </td>
                  )} */}
                  <td className="text-start align-middle">
                    <a title="Edit" 
                    onClick={() => handleEdit(state)}
                    onKeyDown={() => handleEdit(state)}
                    >
                      <i className="fa fa-edit fa-fw"></i>
                    </a>
                    {state?.status === "Active" ? (
                      <i
                        className="fa fa-circle fa-fw txt-success pe-auto"
                        style={{
                          cursor: "pointer",
                        }}
                        title="Deactivate"
                        onClick={() => setDel(state)}
                        onKeyDown={() => setDel(state)}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-circle fa-fw txt-fail"
                        title="Activate"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => setDel(state)}
                        onKeyDown={() => setDel(state)}
                      ></i>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="p-2 text-center">
                  {loading || isFetching ? "Loading..." : "No data found"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {myData.length > postsPerPage && (
          <Pagination
            activePage={page}
            itemsCountPerPage={postsPerPage}
            totalItemsCount={myData.length}
            pageRangeDisplayed={postsPerPage}
            onChange={handlePageChange}
          />
        )}
      </div>

      <Modal
        show={!!del}
        onHide={() => setDel(null)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to{" "}
            {del?.status === "Active" ? "deactivate " : "activate "}
            this state?
          </p>
          <div className="d-flex gap-3 justify-content-end">
            <Button
              variant="danger"
              className="px-3 py-2"
              onClick={() => setDel(null)}
            >
              No
            </Button>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={() => handleDelete(del)}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas show={filter} onHide={handleHide} placement="end">
        <Offcanvas.Header closeButton>
          <h3 className="modal-title">Filter</h3>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-group padding-top-10">
            <label htmlFor="tags"> State </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter State"
              //value='demo'
              //onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group padding-top-10">
            <label htmlFor="tags"> Zone </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Zone"
              //value='demo'
              //onChange={(e) => setName(e.target.value)}
            />
          </div>
          {/* <div className="form-group ">
            <label htmlFor="tags">Select Zone</label>
            <select
              value={zoneId}
              className="form-control full-width"
              onChange={(e) => setZoneId(e.target.value)}
            >
              <option>Choose Zone</option>
              {zones?.map((zd) => (
                <option
                  disabled={zd?.status === "Inactive"}
                  key={zd?.id}
                  value={zd?.id}
                >
                  {zd.zone_name}
                </option>
              ))}
            </select>{" "}
            <i></i>{" "}
          </div>
          <div className="form-group padding-top-10">
            <label htmlFor="tags">Select State</label>
            <select
              className="form-control full-width"
              value={stateId}
              onChange={(e) => setStateId(e.target.value)}
            >
              <option>Choose State</option>
              {states?.map((st) => (
                <option
                  disabled={st?.status === "Inactive"}
                  key={st?.id}
                  value={st?.id}
                >
                  {st.state_name}
                </option>
              ))}
            </select>{" "}
            <i></i>{" "}
          </div> */}

          <div className="modal-footer mt-5">
            <button
              type="button"
              className="btn button_new_success"
              onClick={applyFilter}
            >
              Apply
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <AddState
        refetch={refetch}
        editData={edit}
        setEdit={setEdit}
        clearFilter={clearFilter}
        searchkey={searchkey}
        handleSearch={handleSearch}
      />
    </div>
  );
}