import React, { useState } from "react";
import AdminPageCard from "../../../components/common/AdminPageCard/AdminPageCard";
import AdminPageHeader from "../../../components/AdminPageHeader";
import AdminTable from "../../../components/common/AdminTable/AdminTable";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { useQueries, useQuery } from "react-query";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import { useEffect } from "react";
import { toast } from "react-toastify";
import TotalRequestFilters from "./TotalRequestFilters";
import useSearchData from "./useSearchData";
import useFilterData from "./usefilterData";
import Pagination from "react-js-pagination";

const CSVheaders = [
  { label: "S. No", key: "s_no" },
  { label: "Fast Track", key: "srn_fasttrack" },
  { label: "SRN", key: "srn_name" },
  { label: "Type of Request", key: "license_type" },
  { label: "Location", key: "location" },
  { label: "License", key: "license_name" },
  { label: "Menu", key: "license_category" },
  { label: "Requested Date", key: "srn_requesteddate" },
  { label: "Requested By", key: "requested_by" },
  { label: "Status", key: "srn_status" },
];

const TotalRequests = ({ selectedTile, setSelectedTile, defaultTile,cardPage,setCardPage }) => {
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [filters, setFilters] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [exportData, setExportData] = useState([]);
  const excelRef = useRef();

  const limit = 20;
  const { state } = useLocation();
  const { data, isLoading } = useQuery(
    ["/api/overall_license_request",{ data: { limit: limit, page: page } }],
    AllianceManagerServices.getAllRQLicenseRequests,
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );

  const { searchData, isLoading: isSearchLoading } = useSearchData(
    searchKey,
    page,
    limit,
    selectedTile
  );
  const { filterData, isLoading: filterLoading } = useFilterData(
    filters,
    page,
    limit,
    selectedTile
  );

  useEffect(() => {
    setSearchKey("");
    setFilters({});
  }, [selectedTile]);


  useEffect(() => {
    setPage(cardPage);
  }, [cardPage]);

  useEffect(() => {
    if (page !== cardPage) {
      setCardPage(page);
    }
  }, [page, cardPage, setCardPage]);



  const results = useQueries([
    {
      queryKey: ["api/license/license-type", 1],
      queryFn: AllianceManagerServices.getLicenseTypeList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/get_states", 2],
      queryFn: AllianceManagerServices.getStateList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license", 3],
      queryFn: AllianceManagerServices.getLicenseNameList,
      staleTime: Infinity,
    },

    {
      queryKey: ["/api/license/license-status", 4],
      queryFn: AllianceManagerServices.getStatusList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/user_list", 5],
      queryFn: AllianceManagerServices.getRequestedByList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-category", 6],
      queryFn: AllianceManagerServices.getCategoryList,
      staleTime: Infinity,
    },
  ]);

  const tableRows = [
    {
      title: "S. No.",
      value: (column, index) => limit * (page - 1) + index + 1,
      thProps: { width: "50px" },
    },
    {
      title: "SRN",
      value: (column, index) => (
        <Link to={`/field-executive/dashboard/${column?.id}`}>
          {column?.srn_fasttrack == "1" && (
            <i class="fa-solid fa-flag" style={{ color: "red" }}></i>
          )}
          <span>{column?.srn_name || ""}</span>
        </Link>
      ),
      thProps: { width: "12%" },
    },
    {
      title: "Type of Request",
      value: (column, index) => column?.license_type,
    },
    {
      title: "Location",
      value: (column, index) =>
        `${column?.city_name || ""}, ${column?.state_name || ""} ${
          column?.pin_code || ""
        }`,
    },
    {
      title: "License",
      value: (column, index) => column?.license_name || "",
    },
    
    {
      title: "Menu",
      value: (column, index) => column?.license_category,
    },

    {
      title: "Requested Date",
      value: (column, index) =>
        column?.srn_requesteddate
          ? moment(column?.srn_requesteddate).format("DD-MM-YY hh:mm A")
          : "",
    },
    {
      title: "Requested By",
      value: (column, index) => column?.requested_by || "",
    },
    {
      title: "Status",
      value: (column, index) => selectedTile?.name === "Copy Shared" ? "Copy Shared" : column?.srn_status || "",
    },
  ];
  useEffect(() => {
    if (excelRef.current && exportData?.length) {
      excelRef.current.link.click();
    }
  }, [exportData]);

  const downloadReport = async (event, done) => {
    const resp = await AllianceManagerServices.downloadReport({
      "request-status": selectedTile?.cardStatus,
    });
    const newresp = resp?.map((item, index) => {
      return {
        ...item,
        s_no: index + 1,
        srn_fasttrack: item?.srn_fasttrack == "1" ? "Yes" : "No",
      };
    });
    if (newresp) {
      setExportData(newresp);
      toast.success(`${selectedTile?.name} Downloaded Successfully`);
    }
  };
  const Header = (
    <div className="d-flex gap-2 align-items-center pb-2">
      {Object.keys(filters)?.length > 0 ||
      searchKey ||
      selectedTile?.cardStatus ? (
        <button
          type="button"
          className="button_new mt-0"
          onClick={() => {
            setSelectedTile(defaultTile);
            setDisplaySearchField(false);
            setFilters("");
            setSearchKey("");
            setPage(1)
          }}
          // disabled={!!loader}
        >
          Clear Filters
        </button>
      ) : (
        ""
      )}

      {displaySearchField ? (
        <input
          type="text"
          className="button_new mt-0"
          value={searchKey || ""}
          onChange={(e) => {
            setPage(1);
            setSearchKey(e.target.value);
          }}
          placeholder="Search"
          onBlur={() => {
            if (!searchKey) setDisplaySearchField(false);
          }}
        />
      ) : (
        <button
          className=" button_new mt-0"
          onClick={() => {
            setDisplaySearchField(true);
          }}
          title="Search"
        >
          <i className="fa fa-search" aria-hidden="true"></i>
        </button>
      )}
      <button
        onClick={() => setShowFilter(true)}
        target="_blank"
        className=" button_new mt-0"
      >
        <i className="fa fa-filter fa-fw" title="Show Filters"></i>
      </button>
      <button className=" button_new mt-0" onClick={downloadReport}>
        <i className="fa fa-download" aria-hidden="true" title="Download"></i>
      </button>

      <CSVLink
        data={exportData}
        filename={`${selectedTile?.name}.csv`}
        headers={CSVheaders}
        ref={excelRef}
        onClick={() => {}}
        asyncOnClick
        className="display-hidden"
      ></CSVLink>
    </div>
  );
  return (
    <div>
      {showFilter ? (
        <TotalRequestFilters
          show={showFilter}
          onHide={() => setShowFilter(false)}
          setSearchKey={setSearchKey}
          setDisplaySearchField={setDisplaySearchField}
          setFilters={setFilters}
          setPage={setPage}
          filters={filters}
          results={results}
        />
      ) : (
        ""
      )}
      <AdminPageCard className="mt-4">
        <div className="d-flex justify-content-between">
          <AdminPageHeader title="License Requests" />
          {Header}
        </div>
        <AdminTable
          columns={tableRows}
          data={
            searchKey
              ? searchData?.data
              : Object.keys(filters)?.length > 0 || selectedTile?.cardStatus
              ? filterData?.data
              : data?.data?.data
          }
          isDataLoading={
            searchKey
              ? isSearchLoading
              : Object.keys(filters)?.length > 0 || selectedTile?.cardStatus
              ? filterLoading
              : isLoading
          }
        />
         {searchKey
          ? searchData?.total > limit && (
              <Pagination
                activePage={searchData?.current_page}
                itemsCountPerPage={limit}
                totalItemsCount={searchData?.total}
                pageRangeDisplayed={5}
                onChange={(number) => setPage(number)}
              />
            )
          : Object.keys(filters)?.length > 0 || selectedTile?.cardStatus
          ? filterData?.total > limit && (
              <Pagination
                activePage={filterData?.current_page}
                itemsCountPerPage={limit}
                totalItemsCount={filterData?.total}
                pageRangeDisplayed={5}
                onChange={(number) => setPage(number)}
              />
            )
          : data?.data?.total > limit && (
              <Pagination
                activePage={data?.data?.current_page}
                itemsCountPerPage={limit}
                totalItemsCount={data?.data?.total}
                pageRangeDisplayed={5}
                onChange={(number) => setPage(number)}
              />
            )}
      </AdminPageCard>
    </div>
  );
};

export default TotalRequests;
