import React, { useContext, useState, useEffect } from "react";
import { LocationSettingsContext, spl_format } from "..";
import { useQuery } from "react-query";
import Pagination from "react-js-pagination";
import LocationSettingsServices from "../../../../services/API/LocationSettings";
import { toast } from "react-toastify";
import { Modal, Button, Offcanvas } from "react-bootstrap";
import { CSVLink } from "react-csv";
import AddMC from "../Modals/AddMC";
export const validateMC = (data) => {
  if (!data.state_id) return "Please select state";
  if (data.state_id == "Select State" ) return "Please select state";
  if (!data.city_id) return "Please select city";
  if (!data.municipal_name) return "Please enter municipal corporation name";

  // if (!data.zone_id) return "Please select zone";
  if (data.municipal_name.length < 3 || data.municipal_name.length > 100)
    return "Please enter valid input with 3-100 characters";
  if (!spl_format.test(data.municipal_name))
    return "Special characters are not allowed in municipal name";
  if (!data.pin_code) return "Please enter pincode";
  if (data.pin_code.toString().length !== 6)
    return "Please enter 6 digits only in the PIN Code field";
  if (parseInt(data.pin_code) < 99999) return "Please enter pin code properly";
};
export default function MuncipalCorporation() {
  const value = useContext(LocationSettingsContext);
  const queryKey = "api/get_municipal_details";
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(null);
  const [id, setId] = useState(null);
  const [states, setStates] = useState([]);
  const [zones, setZones] = useState([]);
  const [citys, setCitys] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [zoneId, setZoneId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [del, setDel] = useState(false);
  const [page, setPage] = useState(1);
  const [myData, setMyData] = useState([]);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchkey] = useState("");
  const [filter, setFilter] = useState(false);
  const [exported, setExported] = useState("");
  const [tempCity, setTempCity] = useState("");
  const postsPerPage = 20;
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const {
    data: muncipalLists,
    isLoading: loading,
    isSuccess: success,
    isFetching,
    refetch,
  } = useQuery([queryKey], LocationSettingsServices.getMc, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentLists = Array.isArray(myData)
    ? myData?.slice(indexOfFirstPost, indexOfLastPost)
    : muncipalLists?.data?.slice(indexOfFirstPost, indexOfLastPost);

  const getStates = async () => {
    const { data } = await LocationSettingsServices.getActiveState();
    setStates(data);

    // const res = await LocationSettingsServices.editState(stateId);

    // if (data.filter((e) => e.id === stateId).length > 0) {
    //   setStates([...new Set(data)]);
    // } else {
    //   setStates([...new Set([res?.data, ...data])]);
    // }
  };
  const getZones = async () => {
    const { data } = await LocationSettingsServices.getActiveZone();
    setZones(data);

    // const res = await LocationSettingsServices.editZone(zoneId);

    // if (data.filter((e) => e.id === zoneId).length > 0) {
    //   setZones([...new Set(data)]);
    // } else {
    //   setZones([...new Set([res?.data, ...data])]);
    // }
  };
  const getCity = async (payload) => {
    const { data } = await LocationSettingsServices.getActiveCity(payload);
    setCitys(data);

    // const res = await LocationSettingsServices.editCity(cityId);

    // if (data.filter((e) => e.id === cityId).length > 0) {
    //   setCitys([...new Set(data)]);
    // } else {
    //   setCitys([...new Set([res?.data, ...data])]);
    // }
  };

  const getExported = async () => {
    const response = await LocationSettingsServices.exportMC();
    response?.forEach((element, index) => {
      response[index]["S. No"] = index + 1;
    });
    setExported(response ? response : []);
  };
  useEffect(() => {
    getStates();
    getZones();
  }, []);

  useEffect(() => {
    getExported();
  }, [muncipalLists]);

  // useEffect(() => {
  //   if (edit === "edit") {
  //     getStates();
  //     getZones();
  //     getCity({ state_id: stateId });
  //   }
  // }, [edit]);

  useEffect(() => {
    setFilter(false);
    if (muncipalLists?.data) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setMyData(muncipalLists?.data);
    }
  }, [muncipalLists]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);
  const getCities = async (stateId) => {
    // const { value } = e.target;
    setStateId(stateId);
    try {
      const resp = await LocationSettingsServices.getActiveCity({
        state_id: stateId,
      });
      if (resp) {
        setCitys(resp?.data);
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  useEffect(() => {
    getCities(stateId);
  }, [stateId]);

  const handleDelete = async (mc) => {
    try {
      const status = mc.status === "Active" ? "Inactive" : "Active";
      await LocationSettingsServices.deactivateMC(mc?.id, {
        status,
      });
      const message =
        mc?.status === "Active"
          ? "Municipal Deactivated Successfully"
          : "Municipal Activated Successfully";
      toast.success(message);
      setDel(null);
    
      if(name || zoneId || stateId || cityId || pincode){
        applyFilter(page)
      }else if(searchkey){
        handleSearch({target:{value:searchkey}},page)
      }else{
        refetch();
      }
    } catch (er) {
      toast.error(er);
    }
  };
  const handleEdit = async (mc) => {
    try {
      const mcData = await LocationSettingsServices.editMC(mc?.id);
      value.setShow("MC");
      setEdit(mcData?.data);
    } catch (er) {
      toast.error(er);
    }
  };

  const applyFilter = async (page) => {
    const payload = {
      municipal_name: name,
      zone_id: zoneId,
      state_id: stateId,
      city_id: cityId,
      pin_code: pincode,
    };
    const error = Object.values(payload).filter((dt) => {
      if (dt !== null) {
        return dt;
      }
    });
    if (!error.length) {
      toast.error("Please fill out the fields");
      return;
    }
   
    try {
      const { data } = await LocationSettingsServices.filterMC(payload);
      setFilter(false);
      if(!page){
        setPage(1);
      }
      setMyData(data);
    } catch (e) {
      toast.error(e);
    }
  };
  const clearFilter = () => {
    setDisplaySearchField(false);
    setSearchkey(null);
    setMyData(muncipalLists?.data);
    setName(null);
    setStateId(null);
    setZoneId(null);
    setCityId(null);
    setPincode(null);
    setPage(1)
  };
  const handleSearch = async (e,page) => {
    const value = e.target.value;
    setSearchkey(value);
    const { data } = await LocationSettingsServices.searchMC(value);
    if(!page){
      setPage(1);
    }
    setMyData(data);
  };
  const handleHide = () => {
    setFilter(false);
  };

  return (
    <div style={{ borderTop: "0px", paddingTop: "30px" }}>
      <div
        style={{
          paddingTop: "30px",
        }}
        className="d-flex justify-content-end align-items-center gap-2 p-2"
      >
        {muncipalLists?.data.length !== myData?.length && (
          <button
            type="button"
            className="button_new pull-right"
            onClick={clearFilter}
          >
            Clear Filters
          </button>
        )}
        {displaySearchField ? (
          <input
            type="text"
            className="button_new pull-right "
            value={searchkey}
            onChange={handleSearch}
            onBlur={() => (!searchkey ? setDisplaySearchField(false) : null)}
            // disabled={workflow === "expert"}
            placeholder="Search"
          />
        ) : (
          <a
            className=" button_new pull-right"
            onClick={() => {
              setDisplaySearchField(true);
            }}
            onKeyDown={() => {
              setDisplaySearchField(true);
            }}
          >
            <i
              class="fa fa-search requests-icons"
              aria-hidden="true"
              title="Search"
            ></i>
          </a>
        )}
        <button
          className=" button_new pull-right"
          title="Show Filters"
          onClick={() => setFilter(true)}
        >
          <i className="fa fa-filter fa-fw requests-icons"></i>
        </button>

        <CSVLink
          className=" button_new pull-right"
          title="Download"
          filename={"Municipal-Corporations.csv"}
          data={exported}
          onClick={() => {
            if (exported)
              toast.success("Municipal Corporations Downloaded Successfully");
          }}
        >
          <i className="fa fa-download fa-fw requests-icons"></i>
        </CSVLink>

        <a
          className=" button_new pull-right"
          onClick={() => value.setShow("MC")}
          onKeyDown={() => value.setShow("MC")}
        >
          <i
            className="fa fa-plus fa-fw requests-icons"
            title="Add Municipal Corporation"
          ></i>
        </a>
      </div>

      <div className="table-responsive ">
        <table
          className="table table-bordered table-hover dataTable no-footer"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr role="row" style={{ fontWeight: 700 }}>
              <th className="text-center" width="34px">
                S. No.
              </th>
              <th className="text-center">Municipal Corporation</th>
              <th className="text-center">Pin Code</th>
              <th className="text-center">City</th>
              <th className="text-center">State</th>
              <th className="text-center">Zone</th>
              {/* <th className="text-center">Status</th> */}
              <th className="text-center" width="50px">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentLists?.length !== 0 ? (
              currentLists?.map((mc, index) => (
                <tr key={mc?.id}>
                  <td className="text-start align-middle">
                    {postsPerPage * (page - 1) + index + 1}
                  </td>
                  <td className="text-start align-middle">{mc?.name}</td>
                  <td className="text-start align-middle">{mc?.pin_code}</td>
                  <td className="text-start align-middle">{mc?.city_name}</td>
                  <td className="text-start align-middle">{mc?.state_name}</td>
                  <td className="text-start align-middle">{mc?.zone_name}</td>
                  {/* {mc?.status === "Active" ? (
                    <td className="text-start align-middle">
                      <i className="fa fa-circle fa-fw txt-success"></i>Active
                    </td>
                  ) : (
                    <td className="text-start align-middle">
                      <i className="fa fa-circle fa-fw txt-fail"></i>Inactive
                    </td>
                  )} */}
                  <td className="text-start align-middle">
                    <a title="Edit" 
                    onClick={() => handleEdit(mc)}
                    onKeyDown={() => handleEdit(mc)}
                    >
                      <i className="fa fa-edit fa-fw"></i>
                    </a>

                    {mc?.status === "Active" ? (
                      <i
                        className="fa fa-circle fa-fw txt-success pe-auto"
                        style={{
                          cursor: "pointer",
                        }}
                        title="Deactivate"
                        onClick={() => setDel(mc)}
                        onKeyDown={() => setDel(mc)}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-circle fa-fw txt-fail"
                        title="Activate"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => setDel(mc)}
                        onKeyDown={() => setDel(mc)}
                      ></i>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className="p-2 text-center">
                  {loading || isFetching ? "Loading..." : "No data found"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {myData.length > postsPerPage && (
          <Pagination
            activePage={page}
            itemsCountPerPage={postsPerPage}
            totalItemsCount={myData.length}
            pageRangeDisplayed={postsPerPage}
            onChange={handlePageChange}
          />
        )}
      </div>

      <Modal
        show={!!del}
        onHide={() => setDel(null)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to{" "}
            {del?.status === "Active" ? "deactivate " : "activate "}
            this municipal?
          </p>
          <div className="d-flex gap-3 justify-content-end">
            <Button
              variant="danger"
              className="px-3 py-2"
              onClick={() => setDel(null)}
            >
              No
            </Button>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={() => handleDelete(del)}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas show={filter} onHide={handleHide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h3>Filter </h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modall_body">
          <div className="container-fluid filter-box pt-3 filterScroll">
            <div className="form-group">
              <label htmlFor="tags"> Municipal Corporation </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter municipal corporation"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group ">
              <label htmlFor="tags"> Pincode</label>
              <input
                type="text"
                maxLength={6}
                className="form-control"
                placeholder="Enter pincode"
                value={pincode}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  setPincode(value);
                }}
              />
            </div>
            <div className="form-group ">
              <label htmlFor="tags">Zone</label>
              <select
                className="form-control full-width"
                value={zoneId}
                onChange={(e) => setZoneId(e.target.value)}
              >
                <option value="0">Select Zone</option>
                {zones?.map((zd) => (
                  <option
                    disabled={zd?.status === "Inactive"}
                    key={zd?.id}
                    value={zd?.id}
                  >
                    {zd.zone_name}
                  </option>
                ))}
              </select>{" "}
              <i></i>{" "}
            </div>
            <div className="form-group ">
              <label htmlFor="tags">State</label>
              <select
                className="form-control full-width"
                value={stateId}
                onChange={(e) => {
                  getCities(e.target.value);
                }}
              >
                <option>Select State</option>
                {states?.map((st) => (
                  <option
                    disabled={st?.status === "Inactive"}
                    key={st?.id}
                    value={st?.id}
                  >
                    {st.state_name}
                  </option>
                ))}
              </select>{" "}
              <i></i>{" "}
            </div>
            <div className="form-group ">
              <label htmlFor="tags">City</label>
              <select
                className="form-control full-width"
                value={cityId}
                onChange={(e) => setCityId(e.target.value)}
              >
                <option>Select City</option>
                {citys?.map((ct) => (
                  <option
                    disabled={ct?.status === "Inactive"}
                    key={ct?.id}
                    value={ct?.id}
                  >
                    {ct.name}
                  </option>
                ))}
              </select>{" "}
              <i></i>{" "}
            </div>
          </div>
          <section className="pull-right m-4" 
          onClick={()=>applyFilter()}
          onKeyDown={()=>applyFilter()}
          >
            <a className="btn button_new_success p-3">Apply</a>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
      <AddMC
        refetch={refetch}
        editData={edit}
        setEdit={setEdit}
        clearFilter={clearFilter}
        filtersAvailable={name || zoneId || stateId || cityId || pincode}
        searchkey={searchkey}
        applyFilter={applyFilter}
        handleSearch={handleSearch}
      />
    </div>
  );
}
