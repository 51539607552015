import { addDays, format } from "date-fns";
import React, { useState } from "react";
import { useEffect } from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import DateRangeComponent from "../../../components/AllianceManager/LicenseRequestDetails/DateRangeComponent";
import { toast } from "react-toastify";
const FilterModal = ({
  show,
  onClose,
  setFilters,
  setPage,
  setIsFilterBtnActive,
  clearFields,
  setClearFields,
  showDuedateRange,
  showReqDateRange,
  setShowReqDateRange,
  setShowDuedateRange,
}) => {
  const [invoiceNo, setInvoiceNo] = useState("");
  const [status, setStatus] = useState("");
  const [SRN, SetSRN] = useState("");
  const [amount, setAmount] = useState();
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [dateRange, setDateRange] = useState({
    dueDateRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    dateSubmit: false,
    requestedDateRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    requestDateSubmit: false,
  });
  const [openDueDateCalendar, setOpenDueDateCalendar] = useState(false);
  const [openRequestDateCalendar, setOpenRequestDateCalendar] = useState(false);

  const applyFilter = () => {

    if(invoiceNo || SRN || amount || email || contact || status || dateRange.dateSubmit || dateRange.requestDateSubmit){

    
    let createdDate = `${format(
      dateRange.dueDateRange[0].startDate,
      "dd-MM-yyyy"
    )} to ${format(dateRange.dueDateRange[0].endDate, "dd-MM-yyyy")}`;
    let closedDate = `${format(
      dateRange.requestedDateRange[0].startDate,
      "dd-MM-yyyy"
    )} to ${format(dateRange.requestedDateRange[0].endDate, "dd-MM-yyyy")}`;

    let from_created_on = createdDate
      .split("to")[0]
      ?.trim()
      .split("-")
      .reverse()
      .join("-");
    let to_created_on = createdDate
      .split("to")[1]
      ?.trim()
      .split("-")
      .reverse()
      .join("-");
    let from_closed_on = closedDate
      .split("to")[0]
      ?.trim()
      .split("-")
      .reverse()
      .join("-");

    let to_closed_on = closedDate
      .split("to")[1]
      ?.trim()
      .split("-")
      .reverse()
      .join("-");

    const filters = {
      invoice: invoiceNo,
      amount: amount,
      contact,
      email,
      srn: SRN,
      status,
      from_created_on: dateRange.dateSubmit ? from_created_on : null,
      to_created_on: dateRange.dateSubmit ? to_created_on : null,
      from_closed_on: dateRange.requestDateSubmit ? from_closed_on : null,
      to_closed_on: dateRange.requestDateSubmit ? to_closed_on : null,
    };
    Object.values(filters).forEach((item) => {
      if (item) setIsFilterBtnActive(true);
    });
    setPage(1);
    setFilters(filters);
    setClearFields(false);
    onClose();
  }else{
    toast.error("Please fill out the fields");
      return;
  }
  };

  useEffect(() => {
    if (clearFields) {
      setInvoiceNo("");
      setStatus("");
      setAmount("");
      SetSRN("");
      setEmail("");
      setContact("");
      setDateRange({
        dueDateRange: [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ],
        dateSubmit: false,
        requestedDateRange: [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ],
        requestDateSubmit: false,
      });
    }
  }, [clearFields]);
  return (
    <Offcanvas show={show} placement="end" onHide={() => onClose()} size="lg">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h3>Filter </h3>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modall_body scrollBody">
        <div className="filter-box pt-3 filterScroll">
          <div className=" container-fluid">
            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                {" "}
                Receipt Number
              </label>
              <input
                placeholder="Enter Receipt Number"
                value={invoiceNo}
                onChange={(e) => setInvoiceNo(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>

            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                {" "}
                SRN
              </label>
              <input
                placeholder="Enter SRN"
                value={SRN}
                onChange={(e) => SetSRN(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>
            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                {" "}
                Amount
              </label>
              <input
                placeholder="Enter Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                type="number"
                className="form-control"
              />
            </div>

            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                {" "}
                Email
              </label>
              <input
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>

            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                {" "}
                Mobile Number
              </label>
              <input
                placeholder="Enter Mobile Number"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>

            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                Requested Date
              </label>
              <input
                value={
                  showDuedateRange
                    ? `${format(
                        dateRange.dueDateRange[0].startDate,
                        "dd-MM-yy"
                      )} to ${format(
                        dateRange.dueDateRange[0].endDate,
                        "dd-MM-yy"
                      )}`
                    : ""
                }
                placeholder="Select Requested Date Range"
                readOnly
                onClick={() => setOpenDueDateCalendar((open) => !open)}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  outline: "none",
                  padding: "6px 12px",
                }}
              />
              {openDueDateCalendar && (
                <DateRangeComponent
                  setOpenCalendar={setOpenDueDateCalendar}
                  openCalendar={openDueDateCalendar}
                  isDueDateComponent={true}
                  toBeFiltered={dateRange}
                  setToBeFiltered={setDateRange}
                  setShowReqDateRange={setShowReqDateRange}
                  setShowDuedateRange={setShowDuedateRange}
                />
              )}
            </div>

            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                Payment Date
              </label>
              <input
                value={
                  showReqDateRange
                    ? `${format(
                        dateRange.requestedDateRange[0].startDate,
                        "dd-MM-yy"
                      )} to ${format(
                        dateRange.requestedDateRange[0].endDate,
                        "dd-MM-yy"
                      )}`
                    : ""
                }
                placeholder="Select Payment Date Range"
                readOnly
                onClick={() => setOpenRequestDateCalendar((open) => !open)}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  outline: "none",
                  padding: "6px 12px",
                }}
              />
              {openRequestDateCalendar && (
                <DateRangeComponent
                  setOpenCalendar={setOpenRequestDateCalendar}
                  openCalendar={openRequestDateCalendar}
                  isDueDateComponent={false}
                  toBeFiltered={dateRange}
                  setToBeFiltered={setDateRange}
                  setShowReqDateRange={setShowReqDateRange}
                  setShowDuedateRange={setShowDuedateRange}
                />
              )}
            </div>

            <div className="form-group my-2">
              <label htmlFor="tags" className="mb-2">
                {" "}
                Status
              </label>
              <select
                className="form-control"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Select status</option>
                {/* <option value="0">Initiated</option> */}
                <option value="1">Success</option>
                <option value="2">Failed</option>
                {/* <option value="3">Cancelled</option> */}
              </select>
            </div>
          </div>
        </div>

        <section className="pull-right m-4" 
        onClick={applyFilter}
        onKeyDown={applyFilter}
        >
          <a className="btn button_new_success p-3">Apply</a>
        </section>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FilterModal;
