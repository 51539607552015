import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import AdminTable from "../../common/AdminTable/AdminTable";
import Pagination from "react-js-pagination";

const VendorWorkloadStatistic = () => {
  const limit = 10;
  const [page, setPage] = useState(1);
  const [dataToShow, setDataToShow] = useState([]);

  const {
    data: vendorWorkload,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ["/api/alliance/dashboard/vendor_workload_list", { limit, page: 1 }], //
    AllianceManagerServices.vendorWorkloadList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (vendorWorkload?.data.length) {
      const mutation = vendorWorkload?.data ? [...vendorWorkload.data] : [];

      const data = mutation?.splice(page * limit - limit, limit);

      setDataToShow(data);
    }
  }, [vendorWorkload, page]);

  const tableRows = [
    {
      title: "S. No.",
      value: (column, index) => limit * (page - 1) + index + 1,
      thProps: { width: "40px" },
    },
    {
      title: "Name",
      value: (column) => column?.name,
      thProps: { width: "240px" },
    },
    {
      title: "Assigned",
      value: (column) => (
        <Link
          to="/alliance-manager/overall-license-requests"
          state={{ vendorId: column?.id, vendorName: column?.name }}
        >
          {column?.TotalAssigned || 0}
        </Link>
      ),
      thProps: { width: "60px" },
    },
    {
      title: "Inprogress",
      value: (column) => (
        <Link
          to="/alliance-manager/overall-license-requests"
          state={{
            vendorName: column?.name,
            vendorId: column?.id,
            card: {
              title: "Requests Inprogress",
              cardStatus: "in_progress",
            },
          }}
        >
          {column?.Inprogress || 0}
        </Link>
      ),
      thProps: { width: "60px" },
    },
    {
      title: "Not Started",
      value: (column) => (
        <Link
          to="/alliance-manager/overall-license-requests"
          state={{
            vendorName: column?.name,
            vendorId: column?.id,
            card: {
              title: "Requests Not Started",
              cardStatus: "not_started",
            },
          }}
        >
          {column?.not_started || 0}
        </Link>
      ),
      thProps: { width: "70px" },
    },
    {
      title: "Completed",
      value: (column) => (
        <Link
          to="/alliance-manager/overall-license-requests"
          state={{
            vendorName: column?.name,
            vendorId: column?.id,
            card: {
              title: "Requests Completed",
              cardStatus: "",
            },
          }}
        >
          {column?.Completed || 0}
        </Link>
      ),
      thProps: { width: "60px" },
    },
    {
      title: "TAT Score",
      value: (column) => column?.tat_score + "%",
      thProps: { width: "60px" },
    },
  ];
  return (
    <div>
      <div className="card card-sm card-shadow mb-3">
        <div className="card-body">
          <h4>Vendor Workload Statistics</h4>
          <div
            className="table-responsive"
            style={{ height: "500px", overflowY: "scroll" }}
          >
            <AdminTable
              columns={tableRows}
              data={dataToShow}
              isDataLoading={false}
            />
          </div>
          {vendorWorkload?.data?.length > limit && (
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={vendorWorkload?.data?.length}
              pageRangeDisplayed={5}
              onChange={(number) => setPage(number)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VendorWorkloadStatistic;
