import React, { useEffect, useMemo, useState } from "react";
import AliianceManagerLayout from "../../../components/layouts/AliianceManagerLayout";
import { Link } from "react-router-dom";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  Sector,
  PieChart,
  Cell,
  Pie,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  // Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import AdminTable from "../../../components/common/AdminTable/AdminTable";
import { useQuery } from "react-query";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import Pagination from "react-js-pagination";
import VendorWorkloadStatistic from "../../../components/AllianceManager/Dashboard/VendorWorkloadStatistic";
import LicnseTypeWiseStatistics from "../../../components/AllianceManager/Dashboard/LicenseTypeWiseStatistics";
import BarChartComponent from "../../../components/common/Charts/BarChartComponent";
import DognutChartComponent from "../../../components/common/Charts/DognutChartComponent";
import AreaChartComponent from "../../../components/common/Charts/AreaChartComponent";
import moment from "moment";
import { compare, getDataSortedAreaChart } from "../../../utils/utils";
import Tiles from "../../../components/Vendor/Tile/Tiles";
import { useNavigate } from "react-router";
import LocationWiseCount from "../../../components/AllianceManager/Dashboard/LocationWiseCount";
import DashboardCard from "../../../components/common/DashboardCard";

export default function DashboardAllianceManager() {
  const navigate = useNavigate();
  const [cardNumber, setCardNumber] = useState(0);

  useEffect(() => {
    if (cardNumber !== 0) {
      navigate("/alliance-manager/overall-license-requests", {
        state: { cardNumber: cardNumber },
      });
    }
  }, [cardNumber]);

  const {
    data: assignmentStatus,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ["/api/alliance/dashboard/alliance-assignment-status"], //
    AllianceManagerServices.allianceAssignmentStatus,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { data: overdueCount } = useQuery(
    ["/api/alliance/dashboard/overdue-count"], //
    AllianceManagerServices.executivesWithOverdueCount,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  // getting weekly trend data via api
  const { data: weeklyTrend } = useQuery(
    ["api/request-weekly-trend"], //
    AllianceManagerServices.requestWeeklyTrend,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  
  const formattedWeeklyTrend = useMemo(()=>{
    
    return  getDataSortedAreaChart(weeklyTrend)
  
  },[weeklyTrend])


  // getting closure trend data via api
  const { data: closureTrend } = useQuery(
    ["api/weekly-closure-trend"], //
    AllianceManagerServices.weeklyClosureTrend,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const formattedClosureTrend = useMemo(()=>{
    
    return  getDataSortedAreaChart(closureTrend)
  
  },[closureTrend])


  const { data } = useQuery(
    ["/api/all_count_license_request", {}],
    AllianceManagerServices.allCountLicenseRequest,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { data: visitors_count } = useQuery(
    ["/api/get_ip_count"],
    AllianceManagerServices.fetchVisitorsCount,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const cards = [
    {
      name: "Total Requests",
      value: data?.data[0]?.total ?? "-",
      cardStatus: "",
      onClick: () => navigate("/alliance-manager/overall-license-requests"),
    },
    {
      name: "Requests Not Started",
      value: data?.data[2]?.total ?? "-",
      cardStatus: "not_started",
      onClick: () =>
        navigate("/alliance-manager/overall-license-requests", {
          state: {
            card: {
              title: "Requests Not Started",
              cardStatus: "not_started",
            },
          },
        }),
    },
    {
      name: "Requests Inprogress",
      value: data?.data[3]?.total ?? "-",
      valueColor: "#00a5e5",
      cardStatus: "in_progress",
      onClick: () =>
        navigate("/alliance-manager/overall-license-requests", {
          state: {
            card: {
              title: "Requests Inprogress",
              cardStatus: "in_progress",
            },
          },
        }),
    },
    {
      name: "Requests Overdue",
      value: data?.data[4]?.total ?? "-",
      valueColor: "#eb6e6e",
      cardStatus: "Overdue",
      onClick: () =>
        navigate("/alliance-manager/overall-license-requests", {
          state: {
            card: {
              title: "Requests Overdue",
              cardStatus: "Overdue",
            },
          },
        }),
    },
    {
      name: "Pending With Authority",
      value: data?.data[7]?.total,
      cardStatus: "Pending with Authority",
      onClick: () =>
        navigate("/alliance-manager/overall-license-requests", {
          state: {
            card: {
              title: "Pending With Authority",
              cardStatus: "Pending With Authority",
            },
          },
        }),
    },
    {
      name: "Copy Shared",
      value: data?.data[8]?.total,
      cardStatus: "Copy Shared",
      onClick: () =>
        navigate("/alliance-manager/overall-license-requests", {
          state: {
            card: {
              title: "Copy Shared",
              cardStatus: "Copy Shared",
            },
          },
        }),
    },
    {
      name: "Requests Completed",
      value: data?.data[1]?.total ?? "-",
      valueColor: "#00FF00",
      cardStatus: "Completed",
      onClick: () =>
        navigate("/alliance-manager/overall-license-requests", {
          state: {
            card: {
              title: "Requests Completed",
              cardStatus: "Completed",
            },
          },
        }),
    },
  ];

  return (
    <AliianceManagerLayout account="active">
      <div id="main" role="main">
        <div id="content">
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item ">
                <Link to={"/alliance-manager/dashboard"}>Home</Link>
              </li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </div>
          {/* <div className="status-cards">
            {statuses?.length > 0 &&
              statuses.map((item, key) => (
                <Card
                  className="card card-sm card-shadow mb-3 text-center"
                  key={key}
                >
                  <Card.Body className="pb-2">
                    <h2 className={`${item?.colorClass} status-card-count`}>
                    {item?.total == null ? 0 : item?.total}
                    </h2>
                    <span className="status-card-title">{item?.title}</span>
                  </Card.Body>
                </Card>
              ))}
          </div> */}
          <div className="d-flex gap-2 flex-wrap pb-4">
            {cards?.map((item, index) => {
              return (
                <div key={item?.name}>
                  <DashboardCard
                    title={item?.name}
                    valueColor={item?.valueColor}
                    value={item?.value}
                    onClick={() => (item?.onClick ? item?.onClick() : () => {})}
                  />
                </div>
              );
            })}
          </div>
          <div className="row">
            <div className="col-md-6 col-xl-4">
              <div>
                <div className="card card-sm card-shadow mb-3">
                  <div className="card-body">
                    <div className="chartjs-size-monitor">
                      <div className="chartjs-size-monitor-expand">
                        <div className=""></div>
                      </div>
                      <div className="chartjs-size-monitor-shrink">
                        <div className=""></div>
                      </div>
                    </div>
                    <h4>
                      TAT Status
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => (
                          <Tooltip id="button-tooltip" {...props}>
                            TAT Status Formula - <br />
                            Total no. of requests closed within due date / Total
                            no of requests = Adhered <br />
                            Total no. of requests closed after due date / Total
                            no of requests = Lapsed
                          </Tooltip>
                        )}
                      >
                        <span className="pull-right">
                          <i
                            className="fa fa-info-circle fa-fw font-md text-info"
                            rel="popover-hover"
                            data-placement="top"
                            data-original-title="Popover activated on hover"
                            data-content="Sed posuere consectetur est at lobortis. Aenean eu leo quam.
									   Pellentesque ornare sem lacinia quam venenatis vestibulum."
                          ></i>
                        </span>
                      </OverlayTrigger>
                    </h4>
                    <Card>
                      <div className="mt-3 mx-3">
                        <div className="d-flex gap-2 mb-1">
                          <div
                            className="h-20"
                            style={{
                              width: "20px",
                              backgroundColor: "#6FE3A5",
                            }}
                          ></div>
                          <div>
                            Adhered {" = " + assignmentStatus?.data?.adhered_count || 0 }
                          </div>
                        </div>
                        <div className="d-flex gap-2 ">
                          <div
                            className="h-20"
                            style={{
                              width: "20px",
                              backgroundColor: "#EA7A66",
                            }}
                          ></div>
                          <div>
                            Lapsed {" = " + assignmentStatus?.data?.overdue_count || 0 }
                          </div>
                        </div>
                      </div>
                      <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
                        {assignmentStatus?.data?.Adhered +
                          assignmentStatus?.data?.Lapsed !==
                        0 ? (
                          <DognutChartComponent
                            data={[
                              {
                                name: "Adhered",
                                value:
                                  +assignmentStatus?.data
                                    ?.adhered_percen_format,
                              },
                              {
                                name: "Lapsed",
                                value:
                                  +assignmentStatus?.data?.lapsed_percen_format,
                              },
                            ]}
                            COLORS={["#6FE3A5", "#EA7A66"]}
                            responsiveHeight={200}
                            responsiveWidth={"100%"}
                          />
                        ) : (
                          <DognutChartComponent
                            data={[
                              {
                                name: "None",
                                value: 1,
                              },
                            ]}
                            COLORS={["#F9F6EE"]}
                            responsiveHeight={200}
                            responsiveWidth={"100%"}
                          />
                        )}
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
              <div>
                <div className="card card-sm card-shadow mb-3 ">
                  <div className="card-body" style={{ height: "270px" }}>
                    <div className="chartjs-size-monitor">
                      <div className="chartjs-size-monitor-expand">
                        <div className=""></div>
                      </div>
                      <div className="chartjs-size-monitor-shrink">
                        <div className=""></div>
                      </div>
                    </div>
                    <h4>Assignment Status</h4>

                    <BarChartComponent
                      data={[
                        {
                          name: "Assigned",
                          Count: assignmentStatus?.data?.Assigned,
                        },
                        {
                          name: "Not Assigned",
                          Count: assignmentStatus?.data?.notAssigned,
                        },
                      ]}
                      COLORS={["#6FE3A5", "#EA7A66"]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-8">
              <VendorWorkloadStatistic />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-xl-4">
              <div>
                <div class="card card-sm card-shadow mb-3 ">
                  <div class="card-body  " style={{ height: "221px" }}>
                    <h4>Vendor With Overdue Count</h4>
                    <AreaChartComponent
                      data={overdueCount?.data?.map((item) => {
                        return { name: item.vendor_name, Count: +item.Overdue };
                      })}
                      bgColor={"#EA7A66"}
                      hideXAxis={true}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div class="card card-sm card-shadow mb-3">
                  <div class="card-body " style={{ height: "211px" }}>
                    <h4>Requests Weekly Trend</h4>
                    {formattedWeeklyTrend?.length ? (
                      <AreaChartComponent
                        data={formattedWeeklyTrend}
                        bgColor={"#6EA6E9"}
                      />
                    ) : (
                      <div
                        style={{
                          height: "320px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        No Data to Show
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div class="card card-sm card-shadow mb-3 ">
                  <div class="card-body" style={{ height: "211px" }}>
                    <h4>Weekly Closure Trend</h4>
                    {formattedClosureTrend?.length ? (
                      <AreaChartComponent
                        data={formattedClosureTrend}
                        bgColor={"#6FE3A5"}
                      />
                    ) : (
                      <div
                        style={{
                          height: "320px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        No Data to Show
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-xl-8">
              <div>
                <div class="card card-sm card-shadow mb-3">
                  <div class="card-body">
                    <h4>Location Wise Requests Count</h4>
                    {/* <img
                      src="img/portal/map.png"
                      width="100%"
                      style={{ height: "615px" }}
                    /> */}
                    <div style={{ height: "605px", width: "100%" }}>
                      <LocationWiseCount />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="row">
            <div class="col-md-12 col-xl-12">
              <div>
                <div class="card card-sm card-shadow mb-3">
                  <div class="card-body ageing">
                    <h4>Ageing By Authority</h4>
                    <div class="table-responsive">
                      <table class="table table-nowrap">
                        <thead>
                          <tr>
                            <th
                              style={{ textAlign: "center", fonWweight: "700" }}
                            >
                              Authority
                            </th>
                            <th
                              style={{ textAlign: "center", fonWweight: "700" }}
                            >
                              0-7 days
                            </th>
                            <th
                              style={{ textAlign: "center", fontWeight: "700" }}
                            >
                              8-14 days
                            </th>
                            <th
                              style={{ textAlign: "center", fontWeight: "700" }}
                            >
                              15-21 days
                            </th>
                            <th
                              style={{ textAlign: "center", fontWeight: "700" }}
                            >
                              &gt;21 days
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row" style={{ verticalAlign: "bottom" }}>
                              GST Authority
                            </th>
                            <td align="middle">
                              <a href="#">10</a>
                            </td>
                            <td align="middle">
                              <a href="#">5</a>
                            </td>
                            <td align="middle">
                              <a href="#">0</a>
                            </td>
                            <td align="middle">
                              <a href="#">0</a>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ verticalAlign: "bottom" }}>
                              MCA Authority
                            </th>
                            <td align="middle">
                              <a href="#">0</a>
                            </td>
                            <td align="middle">
                              <a href="#">5</a>
                            </td>
                            <td align="middle">
                              <a href="#">1</a>
                            </td>
                            <td align="middle">
                              <a href="#">0</a>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ verticalAlign: "bottom" }}>
                              PF Authority
                            </th>
                            <td align="middle">
                              <a href="#">3</a>
                            </td>
                            <td align="middle">
                              <a href="#">2</a>
                            </td>
                            <td align="middle">
                              <a href="#">0</a>
                            </td>
                            <td align="middle">
                              <a href="#">1</a>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">ESI Authority</th>
                            <td align="middle">
                              <a href="#">6</a>
                            </td>
                            <td align="middle">
                              <a href="#">2</a>
                            </td>
                            <td align="middle">
                              <a href="#">0</a>
                            </td>
                            <td align="middle">
                              <a href="#">2</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div class="row">
            <LicnseTypeWiseStatistics />
          </div>
          <div class="row">
            <div class="col-md-12 col-xl-12">
              <div class="card card-sm card-shadow mb-3">
                <div class="card-body">
                  <p style={{ margin: "0 10px" }}>
                    Number of visitors till date:{" "}
                    <strong>{visitors_count?.data?.total_count}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AliianceManagerLayout>
  );
}
