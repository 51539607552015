import React, { useState, useEffect, useContext } from "react";
import Breadcrumb from "../../../components/common/Breadcrumb";
import AdminPageHeader from "../../../components/AdminPageHeader";
import AdminPageCard from "../../../components/common/AdminPageCard/AdminPageCard";
import AdminTable from "../../../components/common/AdminTable/AdminTable";
import ReassignFilter from "./ReassignFilter";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import HomeServices from "../../../services/API/home";
import ReassignServices from "../../../services/API/Reassign";
import Pagination from "react-js-pagination";
import { useQueries, useQuery } from "react-query";
import { format } from "date-fns";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useRef } from "react";
import ReassignModal from "./ReassignModal";
import { AuthContext } from "../../../context/authContext";
import { ROLES } from "../../../constant/roles";
import ReassignVendorModal from "./ReassignVendorModal";

const CSVheaders = [
  { label: "SRN", key: "srn_name" },
  { label: "Fast Track", key: "srn_fasttrack" },
  { label: "Type of Request", key: "license_type" },
  { label: "Location", key: "location" },
  { label: "License", key: "license_name" },
  { label: "Menu", key: "license_category" },
  { label: "Requested Date", key: "srn_requesteddate" },
  { label: "Vendor", key: "vendor_name" },
  { label: "Executive", key: "executive_name" },
  { label: "Field Executive", key: "field_executive_name" },
  { label: "Status", key: "srn_status" },
];

const Reassignment = () => {
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [filters, setFilters] = useState({});
  const auth = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [exportData, setExportData] = useState([]);
  const excelRef = useRef();
  const [selectedSRN, setSelectedSRN] = useState();
  const [licenseId, setLicenseId] = useState();
  const searchInputref = useRef();
  const limit = 20;
  const breadcrumbs = [
    {
      title: "Home",
      link: "/alliance-manager/dashboard",
      isActive: true,
    },
    {
      title: "License Reassignments",
      link: "/alliance-manager/dashboard",
      isActive: false,
    },
  ];

  const { data, isLoading, refetch } = useQuery(
    [
      `/api/reassignment`,
      {
        params: {
          page,
          limit,
          ...filters,
          requestType: filters?.requestType?.map((item) => item?.id),
          states: filters?.states?.map((item) => item?.id),
          cities: filters?.cities?.map((item) => item?.id),
          licenses: filters?.licenses?.map((item) => item?.id),
          categories: filters?.categories?.map((item) => item?.id),
          requested_on_from: filters?.requested_on_from
            ? moment(filters?.requested_on_from)?.format("YYYY-MM-DD")
            : null,
          requested_on_to: filters?.requested_on_to
            ? moment(filters?.requested_on_to)?.format("YYYY-MM-DD")
            : null,
          vendors: filters?.vendors?.map((item) => item?.id),
          executives: filters?.executives?.map((item) => item?.id),
          field_executives: filters?.field_executives?.map((item) => item?.id),
          statuses: filters?.statuses?.map((item) => item?.id),
        },
      },
    ],
    ReassignServices.getsrnList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (excelRef.current && exportData?.length) {
      excelRef.current.link.click();
      toast.success(
        "Reassignment Requests Downloaded Successfully"
      );
    }
  }, [exportData]);

  const downloadReport = async (event, done) => {
    const resp = await ReassignServices.downloadReport();
    const newresp = resp?.map((item, index) => {
      return {
        ...item,
        srn_fasttrack: item?.srn_fasttrack == "1" ? "Yes" : "No",
      };
    });
    if (newresp) {
      setExportData(newresp);
    }
  };
  let tableRows = [
    {
      title: "SRN",
      value: (column) => (
        <Link
          to={
            auth?.roleId == ROLES.allianceManager
              ? `/alliance-manager/overall-license-requests/license-request-details/${column?.id}`
              : `/vendor/license-request-details/${column?.id}`
          }
        >
          {column?.srn_fasttrack == "1" && (
            <i className="fa-solid fa-flag" style={{ color: "red" }}></i>
          )}{" "}
          {column?.srn_name}
        </Link>
      ),

      thProps: { width: "12%" },
    },
    {
      title: "Type of Request",
      value: (column) => column?.service_type,
    },
    {
      title: "Location",
      value: (column) => column?.city_name + "," + column?.state_name,
    },
    {
      title: "License",
      value: (column, index) => column?.license_name,
    },
    {
      title: "Menu",
      value: (column, index) => column?.license_category,
    },
    {
      title: "Requested Date",
      value: (column, index) =>
        column?.srn_requesteddate
          ? moment(column?.srn_requesteddate)?.format("DD-MM-YY hh:mm A")
          : "",
    },
    {
      title: "Executive",
      value: (column, index) => {
        return (
          <>
            {column?.executive_name ? (
              <>
                <i class="fa-solid fa-user" style={{ color: "#c6538c" }}></i>{" "}
                {column?.executive_name}
              </>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      title: "Field Executive",
      value: (column, index) => {
        return (
          <>
            {column?.field_executive_name ? (
              <>
                <i class="fa-solid fa-user" style={{ color: "#c6538c" }}></i>{" "}
                {column?.field_executive_name}
              </>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      title: "Status",
      value: (column, index) => column?.srn_status,
    },
    {
      title: "Actions",
      value: (column, index) => (
        <button className="button_new " onClick={() => {
          setSelectedSRN(column)
          setLicenseId(column?.license_id)
          }}>
          Reassign
        </button>
      ),
    },
  ];

  if (auth.roleId == ROLES.allianceManager) {
    tableRows.splice(6, 0, {
      title: "Vendor",
      value: (column, index) => column?.vendor_name,
    });
  }

  const Header = (
    <div className="d-flex gap-2 align-items-center pb-2">
      {Object.keys(filters)?.length > 0 ? (
        <button
          type="button"
          className="button_new mt-0"
          onClick={() => {
            setDisplaySearchField(false);
            setFilters("");
          }}
          // disabled={!!loader}
        >
          Clear Filters
        </button>
      ) : (
        ""
      )}

      {displaySearchField ? (
        <input
          type="text"
          className="button_new mt-0"
          ref={searchInputref}
          value={filters?.q || ""}
          onChange={(e) => {
            setPage(1);
            setFilters((prevState) => {
              if (e.target.value)
                return {
                  ...prevState,
                  q: e.target.value,
                };
              else {
                let newState = { ...prevState };
                delete newState.q;
                return newState;
              }
            });
          }}
          placeholder="Search"
          onBlur={() => {
            if (!filters?.q) setDisplaySearchField(false);
          }}
        />
      ) : (
        <button
          className=" button_new mt-0"
          onClick={() => {
            setDisplaySearchField(true);
          }}
          title="Search"
        >
          <i className="fa fa-search" aria-hidden="true"></i>
        </button>
      )}
      <button
        onClick={() => setShowFilter(true)}
        target="_blank"
        className=" button_new mt-0"
      >
        <i className="fa fa-filter fa-fw" title="Show Filters"></i>
      </button>
      <button className=" button_new mt-0" onClick={downloadReport}>
        <i className="fa fa-download" aria-hidden="true" title="Download"></i>
      </button>
      <CSVLink
        data={exportData}
        filename="Reassignment Requests Received.csv"
        headers={CSVheaders}
        ref={excelRef}
        onClick={() => {}}
        asyncOnClick
        className="display-hidden"
      ></CSVLink>
    </div>
  );

  const results = useQueries([
    {
      queryKey: ["api/license/license-type", 1],
      queryFn: AllianceManagerServices.getLicenseTypeList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/get_states", 2],
      queryFn: AllianceManagerServices.getStateList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license", 3],
      queryFn: AllianceManagerServices.getLicenseNameList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-category", 4],
      queryFn: HomeServices.getCategoryMenuList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-status", 5],
      queryFn: AllianceManagerServices.getStatusList,
      staleTime: Infinity,
    },
    {
      queryKey: [`/api/vendor_list`, 6],
      queryFn: AllianceManagerServices.getVendorList,
      staleTime: Infinity,
    },
  ]);

  return (
    <>
      <div id="main" className="main">
        <div id="content">
          {showFilter ? (
            <ReassignFilter
              show={showFilter}
              onHide={() => setShowFilter(false)}
              setFilters={setFilters}
              setPage={setPage}
              filters={filters}
              results={results}
            />
          ) : (
            ""
          )}

          {selectedSRN ? (
            auth?.roleId == ROLES.allianceManager ? (
              <ReassignModal
                show={!!selectedSRN}
                selectedSRN={selectedSRN}
                vendorsList={results[5]?.data?.data || []}
                setSelectedSRN={setSelectedSRN}
                licenseId={licenseId}
                refetch={refetch}
              />
            ) : (
              <ReassignVendorModal
                show={!!selectedSRN}
                selectedSRN={selectedSRN}
                setSelectedSRN={setSelectedSRN}
                refetch={refetch}
                licenseId={licenseId}
              />
            )
          ) : (
            ""
          )}

          <Breadcrumb items={breadcrumbs} />
          <AdminPageCard>
            <div className="d-flex justify-content-between align-items-center">
              <AdminPageHeader title="Reassignment Requests Received" />
              {Header}
            </div>

            <AdminTable
              columns={tableRows}
              data={data?.data?.data}
              isDataLoading={isLoading}
            />

            {data?.data?.total > limit && (
              <Pagination
                activePage={data?.data?.current_page}
                itemsCountPerPage={limit}
                totalItemsCount={data?.data?.total}
                pageRangeDisplayed={5}
                onChange={(number) => setPage(number)}
              />
            )}
          </AdminPageCard>
        </div>
      </div>
    </>
  );
};

export default Reassignment;
