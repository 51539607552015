import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { LicenseConfigureContext, spl_format } from "..";
import LicenseConfigurationServices from "../../../../services/API/LicenseConfiguration";
import { toast } from "react-toastify";
import { validate } from "../licenceTabs/Categories";

export default function AddCategories({
  refetch,
  editData,
  setEdit,
  clearFilter,
  filtersAvailable,
  applyFilter,searchkey,handleSearch
}) {
  const { show, setShow } = useContext(LicenseConfigureContext);
  const [name, setName] = useState(null);
  const [shortName, setShort] = useState(null);
  const [shortList, setShortList] = useState(null);
  const [menuOrder, setMenuOrder] = useState(null);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (editData) {
      setName(editData?.name);
      setShort(editData?.short_name);
      setShortList(""+editData?.shortlist);
      setMenuOrder(editData?.menu_order);
    }
  }, [editData]);
  const createShortName = async (e) => {
    const { value } = e.target;
    setName(value);
    if (value.length === 0) {
      toast.error("Please enter category");
      clearTimeout(timer);
      setShort("");
      return;
    }

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      async function shortNameCreate(){
        try {
          const response = await LicenseConfigurationServices.createShortName({
            short_name: value,
            model_name: "LicenseCategory",
          });
          setShort(response?.data);
        } catch (e) {
          toast.error(e.message);
        }
      }
      shortNameCreate()
    }, 500);
    setTimer(newTimer);
  };
  const addCategory = async () => {
    const payload = {
      name,
      short_name: shortName,
      shortlist: shortList,
      menu_order: menuOrder,
    };
    const error = validate(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LicenseConfigurationServices.addCategory(payload);
      if (response?.data) {
        toast.success("Menu created successfully");
        clearFilter();
        refetch();
        handleHide();
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const updateCategory = async () => {
    const payload = {
      name,
      short_name: shortName,
      shortlist: shortList,
      menu_order: menuOrder,
    };
    const error = validate(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LicenseConfigurationServices.updateCategory(
        editData?.id,
        payload
      );
      if (response) {
        toast.success("Menu updated successfully");
        // clearFilter();
        handleHide();
        if(filtersAvailable){
          applyFilter(1)
        }else if(searchkey){
          handleSearch({target:{value:searchkey}},1)
        }else{
          refetch();
        }
        setEdit(null);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const submitForm = () => {
    if (editData) {
      updateCategory();
    } else {
      addCategory();
    }
  };

  const handleHide = () => {
    setShow(null);
    setName(null);
    setShort(null);
    setShortList("");
    setMenuOrder(null);
    setEdit(null);
  };
  return (
    <Modal show={show === "categories"} animation={false} onHide={handleHide}>
      {/* <ModalDialog style={{ marginTop: "20%" }}> */}
      {/* <div className="modal-content" style={{ marginTop: "20%" }}> */}
      <Modal.Header closeButton>
        <h3 className="modal-title">{editData ? "Edit Menu" : "Add Menu"}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Menu Name<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Enter Menu Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Short Name<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Short Name"
            maxLength={3}
            onChange={(e) => setShort(e.target.value)}
            value={shortName}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Show On Portal<span style={{ color: "red" }}> *</span>
          </label>

          <select
            value={shortList}
            placeholder="Select Show On Portal"
            className="form-control"
            onChange={(e) => setShortList(""+e.target.value)}
          >
            <option value="">Select Show On Portal</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Menu Order<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Menu Order"
            // maxLength={3}
            onChange={(e) =>{
              const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters

    setMenuOrder(numericValue);
            } 
          }
            value={menuOrder}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn button_new_success"
          onClick={submitForm}
        >
          Save
        </button>
      </Modal.Footer>
      {/* </div> */}
      {/* </ModalDialog> */}
    </Modal>
  );
}
