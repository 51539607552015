import moment from "moment";

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhone = (phone) => {
  return phone.match(/^[0-9]{10}$/);
};

export const onlyLetter = (string) => {
  return String(string)
    .toLowerCase()
    .match(/^[A-Za-z]+$/);
};

export function get_url_extension(url) {
  return url.split(/[#?]/)[0].split(".").pop().trim();
}

export const AlphaNumWithSpace = (testString) => {
  return String(testString)
    .toLowerCase()
    .match(/^[a-zA-Z0-9 ]*$/);
};
export function validateAlphaNumeric(name) {
  return name.match(/^[a-z0-9\s]+$/i);
}
export function validateName(name) {
  // return name.match(/^[a-z0-9\s]+$/i);
  const spl_format = /^[a-zA-Z./s ]*$/;
  return spl_format.test(name);
}

export function checkPassword(str) {
  var re =
    /^(?=.*\d)(?=.*[*.!@#$%^&(){}/[\]:;<>,.?~_+-=|\])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  console.log(re.test(str));
  return re.test(str);
}

export function truncate(str) {
  if (str.length > 25) return str.slice(0, 25) + "...";
  else return str;
}

export const validateChangePasswordFormData = (
  passwordFormData,
  resetFields
) => {
  const { newPassword, confirmPassword } = passwordFormData;
  if (!newPassword) {
    resetFields();
    return "Please enter your new password";
  }
  if (newPassword.length < 8 || newPassword.length > 16) {
    resetFields();
    return "Please enter valid password with at least 8 characters with one lower case, one upper case, one number and one special character";
  }
  if (!checkPassword(newPassword)) {
    resetFields();
    return "Please enter valid password with at least 8 characters with one lower case, one upper case, one number and one special character";
  }
  if (!confirmPassword) {
    resetFields();
    return "Please re-enter your new password";
  }
  if (newPassword !== confirmPassword) {
    resetFields();
    return "Entered passwords do not match";
  }
};
export const validateAccountFormData = (accountFormData) => {
  const { email, fullName, phone } = accountFormData;
  const spl_format = /^[a-zA-Z./s ]*$/;
  const email_format =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!fullName) return "Please enter your full name";
  if (fullName.length < 3 || fullName.length > 50) {
    return "Please enter valid input with 3-50 characters";
  }
  if (!spl_format.test(fullName))
    return "Special characters and numbers are not allowed in full name";
  if (!email) return "Please enter Email";
  if (email.split("@")[0].length < 3 || email.split("@")[0].length > 500) {
    return "Please enter valid input with 3-500 characters";
  }
  if (!email_format.test(email)) return "Enter valid email";
  if (!phone) return "Please enter valid 10 digit mobile number";
  if (phone.length !== 10) return "Please enter valid 10 digit mobile number";
};
export const formatMobileNumber = (number) => {
  if (number.length === 13) {
    return number.split("").slice(3).join("");
  } else {
    return number;
  }
};

export const CustomInput = ({ value, onClick, placeholderText, icon }) => {
  return (
    <div className="d-flex align-items-center">
      <input
        type="text"
        className="form-control"
        value={value}
        onClick={onClick}
        placeholder={placeholderText}
      />
      {icon ? (
        <i className="fa fa-calendar fa-fw" style={{ marginLeft: "-20px" }}></i>
      ) : (
        ""
      )}
    </div>
  );
};

export const compare = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

export const filterCitiesFromData = (citiesData, cities) => {
  const newCities = cities?.filter((item) =>
    citiesData?.some((city) => city?.id == item?.id)
  );
  return newCities;
};

export const filterCitiesFromDataByName = (cityList, currentCities) => {
  const newCities = cityList?.filter((item) =>
    currentCities?.some((city) => city === item)
  );
  return newCities;
};

export const filterCitiesByName = (citiesData, cities) => {
  const newCities = cities?.filter((item) =>
    citiesData?.some((city) => city?.name == item)
  );
  return newCities;
};

export const matchVendorToSRN = (user, selectedSRN) => {
  return (
    user?.states?.some((item) => item?.id == selectedSRN?.state_id) &&
    user?.cities?.some((item) => item?.id == selectedSRN?.city_id) &&
    user?.municipals?.some((item) => item?.id == selectedSRN?.municipal_id) &&
    user?.industry_types?.some(
      (item) => item?.id === selectedSRN?.industry_id
    ) &&
    user?.license_names?.some((item) => item?.id == selectedSRN?.license_id) &&
    user?.company_types?.some((item) => item?.id == selectedSRN?.company_id)
  );
};

export const matchEXEFEToSRN = (user, selectedSRN) => {
  return (
    user?.states?.some((item) => item?.id == selectedSRN?.state_id) &&
    user?.cities?.some((item) => item?.id == selectedSRN?.city_id) &&
    user?.municipals?.some((item) => item?.id == selectedSRN?.municipal_id) &&
    user?.industry_types?.some(
      (item) => item?.id == selectedSRN?.industry_id
    ) &&
    user?.license_types?.some(
      (item) =>
        item?.id == selectedSRN?.service_type ||
        item?.id == selectedSRN?.license_types_id
    )
  );
};

export const getDocName = (ids, allDocs) => {
  const docNames = [];
  ids.forEach((id) => {
    allDocs.forEach((doc) => doc.id == id && docNames.push(doc.document.name));
  });

  return docNames;
};

function getWeekOfMonth(dateString) {
  const date = new Date(dateString);
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const dayOfWeek = firstDay.getDay();
  const dayOfMonth = date.getDate();
  const offset = (dayOfWeek + 6) % 7;
  return Math.ceil((dayOfMonth + offset) / 7);
}


export const getDataSortedAreaChart = (data) => {
  console.log("workign")
  // sorting data according to latest month and year i.e jan 2024 , dec 2023, nov 2023
  const sortedArray = data?.data?.sort((a, b) => new Date(a.start_date) - new Date(b.start_date) || a?.start_date?.localeCompare(b?.start_date));
  const result = sortedArray?.reverse()?.map((item,key)=>{
   return  {name:`wk${getWeekOfMonth(item?.start_date?.split(" ")[0])}-${moment(item?.start_date, "YYYY-MM-DD HH:mm:ss").format("MMM")}`,Count:item["Total Request"]} 
  })
  
  return result?.length > 4 ? result?.slice(0,4) : result
//   const result = data?.data?.map((item, key) => {
//     return { name: `WK${key + 1}`, Count: item["Total Request"] };
//   });

//   result?.sort(compare);
// console.log(result)
//   return result;
};

export const filterActiveInactive = (data) => {
  const result = [];
  data?.forEach((item) => {
    if (item.is_enabled) {
      result.push(item);
    }
  });
  return result;
};

export const PendingStatusList = (data) => {
  const result = [];
  data?.forEach((item) => {
    if (item.name != "Completed" && item.name != "Closed") {
      result.push(item.name);
    }
  });
  return [...result, "not_started"];
};

export const IfObjectHasValidKey = (obj) => {
  let hasValidKey = false;
  const keys = Object.keys(obj);
  keys.forEach((key, index) => {
    if (obj[key] || obj[key] === 0) {
      hasValidKey = true;
    }
  });
  return hasValidKey;
};


export const currentFinancialYear = ()=>{
  const today = new Date();
  const currentMonth = today.getMonth(); // 0-indexed (0 - January, 1 - February, ..., 11 - December)
  const currentYear = today.getFullYear();

  if (currentMonth >= 3) {
      // If the current month is April (index 3) or later, return the ongoing financial year
      return currentYear + '-' + (currentYear + 1);
  } else {
      // If the current month is earlier than April, return the previous financial year
      return (currentYear - 1) + '-' + currentYear;
  }
}

export const  getCurrentFinancialYear = ()=>{
 return currentFinancialYear().split("-")[0]
} 


export function preventNonNumericalInput(e) {
  e = e || window.event;
  var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
  var charStr = String.fromCharCode(charCode);

  if (!charStr.match(/^[0-9]+$/))
    e.preventDefault();
}


export const editorRegex = /^&nbsp;|&nbsp;$/g