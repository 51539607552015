import React, { useEffect, useState } from "react";
//----------css import --------------
import "../../../styles/alliance-manager/allLicenseRequest.css";
import AdminLayout from "../../../components/layouts/adminLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import TotalRequests from "./TotalRequests";
//----------library import-----------

const VendorLicenseRequests = () => {
  return (
    <>
      <AdminLayout>
        <AdminPageContainer>
          <div
            className="py-2 px-1 h-100 lr-container"
            style={{ backgroundColor: "#f9f9f9" }}
          >
            <div id="main" role="main">
              <div id="content">
                <TotalRequests />
              </div>
            </div>
          </div>
        </AdminPageContainer>
      </AdminLayout>
    </>
  );
};

export default VendorLicenseRequests;
