import React from "react";
import ExecutiveLayout from "../../../../components/layouts/ExecutiveLayout";
import AdminPageContainer from "../../../../components/AdminPageContainer";
import FieldExecutiveServices from "../../../../services/API/FieldExecutive";

import { useLocation, useParams } from "react-router";
import { useQuery } from "react-query";
import Loader from "../../../../components/Loader";
import AddFE from "../../../../Containers/Vendor/AddFE/AddFE";
import FullScreenLoader from "../../../../components/FullScreenLoader";

const EditFEPage = () => {
    const { id } = useParams();
    const { state } = useLocation();
    const {
      data: FEData,
      isFetching,
      isLoading,
    } = useQuery(
      [`/api/executive/executive_byId/${id}`, id],
      FieldExecutiveServices.FEDetails,
      {
        refetchOnWindowFocus: false,
      }
    );

    const executiveFEEdit = [
        {
          title: "Home",
          link: "/executive/dashboard",
          isActive: true,
        },
        {
          title: "Field Executives Lists",
          link: "/executive/field-executive",
          isActive: true,
        },
        {
          title: "Update Field Executive",
          // link: "/vendor/field-executives",
          isActive: false,
        },
      ];
    
  return (
    <ExecutiveLayout>
      <AdminPageContainer>
      {isLoading || isFetching ? (
          <FullScreenLoader show={true} title={""}/>
        ) : (
          <AddFE
            FEDetails={FEData?.data[0]}
            breadcrumbs={executiveFEEdit}
            isEdit={true}
            vendorIds={state?.vendorId}
          />
        )}
      </AdminPageContainer>
    </ExecutiveLayout>
  );
};

export default EditFEPage;
