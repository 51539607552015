import React, { useState, memo, useEffect } from "react";
import { Button, Modal, Offcanvas } from "react-bootstrap";
//-------------css import ----------------
import "../../../styles/alliance-manager/licenseRequestsFilter.css";
//--------------------api handler------------------
import AllianceManagerServices from "../../../services/API/AllianceManager";
import LicenseMasterServices from "../../../services/API/LicenseMaster";

//-------------third party library-------
import format from "date-fns/format";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateRangeComponent from "../LicenseRequestDetails/DateRangeComponent";
import { toast } from "react-toastify";
import { multiSelectStyle } from "../../CreateExecutive/MultiSelectStyle";
import CustomFilter from "../../common/CustomFilter/CustomFilter";
import {
  filterCitiesFromData,
  filterCitiesFromDataByName,
} from "../../../utils/utils";
const CompletedRequestsFilter = ({
  show,
  onHide,
  toBeFiltered,
  setToBeFiltered,
  handleApplyFilter,
  licenseTypeList,
  stateList,
  cityList,
  licenseNameList,
  categoryList,
  showDuedateRange,
  setShowDuedateRange,
  setOpenFilterBox,
  loading,
  vendorList,
  statusList,
  subStatusList,
  requestedByList,
  companyTypeList,
  industryTypeList,
  showReqDateRange,
  setShowReqDateRange,
  isLoading,
  role,
}) => {
  const [openDueDateCalendar, setOpenDueDateCalendar] = useState(false);
  const [initialStateToBeFiltered, setInitialStateToBeFiltered] =
    useState(toBeFiltered);
  function decode(str) {
    if (str?.length > 23) return str.slice(0, 23) + "...";
    else return str;
  }

  useEffect(() => {
    if (!toBeFiltered["state"]?.length) {
      setToBeFiltered({
        ...toBeFiltered,
        cities: [],
      });
    } else {
      const newCities = filterCitiesFromDataByName(
        cityList,
        toBeFiltered["cities"]
      );

      setToBeFiltered({
        ...toBeFiltered,
        cities: newCities,
      });
    }
  }, [cityList]);
  return (
    <CustomFilter
      show={show}
      onHide={() => {
        setToBeFiltered(initialStateToBeFiltered);
        onHide();
      }}
      heading="Filter"
    >
      <div className="filter-box pt-3  filterScroll h-100">
        <div className="container-fluid">
          <div className="form-check form-switch track-switch mb-5 ">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              onClick={() =>
                setToBeFiltered({
                  ...toBeFiltered,
                  fastTrackStatus: true,
                  ["fastTrackRequests"]: !toBeFiltered.fastTrackRequests,
                })
              }
              checked={toBeFiltered.fastTrackRequests ? true : false}
            />
            <span>Show Fast Track Requests</span>
          </div>

          <div className="my-2">
            <span>Type Of Request </span>

            <Multiselect
              options={licenseTypeList}
              isObject={false}
              // displayValue="name"
              placeholder={
                toBeFiltered.requestType?.length > 0
                  ? ""
                  : "Select type of request "
              }
              onSelect={(selectedList, selectedItem) => {
                setToBeFiltered({
                  ...toBeFiltered,
                  ["requestType"]: selectedList,
                });
              }}
              onRemove={(selectedList, selectedItem) => {
                setToBeFiltered({
                  ...toBeFiltered,
                  ["requestType"]: selectedList,
                });
              }}
              selectedValues={toBeFiltered.requestType}
              className="dropdown"
              style={multiSelectStyle}
              selectedValueDecorator={(str) => decode(str)}
            />
          </div>

          <div className="py-2">
            <span>State</span>

            <Multiselect
              options={stateList}
              isObject={false}
              placeholder={toBeFiltered.state?.length > 0 ? "" : "Select state"}
              onSelect={(selectedList, selectedItem) => {
                setToBeFiltered({
                  ...toBeFiltered,
                  ["state"]: selectedList,
                });
              }}
              onRemove={(selectedList, selectedItem) => {
                setToBeFiltered({
                  ...toBeFiltered,
                  ["state"]: selectedList,
                });
              }}
              selectedValues={toBeFiltered.state}
              className="dropdown"
              style={multiSelectStyle}
              selectedValueDecorator={(str) => decode(str)}
            />
          </div>

          <div className="py-2">
            <span>City</span>

            <Multiselect
              options={cityList}
              isObject={false}
              // displayValue="name"
              disable={!cityList?.length || !toBeFiltered.state?.length}
              placeholder={toBeFiltered.cities?.length > 0 ? "" : "Select city"}
              onSelect={(selectedList, selectedItem) => {
                setToBeFiltered({
                  ...toBeFiltered,
                  ["cities"]: selectedList,
                });
              }}
              onRemove={(selectedList, selectedItem) => {
                setToBeFiltered({
                  ...toBeFiltered,
                  ["cities"]: selectedList,
                });
              }}
              selectedValues={toBeFiltered.cities}
              className="dropdown"
              style={multiSelectStyle}
              selectedValueDecorator={(str) => decode(str)}
            />
          </div>

          <div className="py-2">
            <span>License</span>

            <Multiselect
              options={licenseNameList}
              isObject={false}
              placeholder={
                toBeFiltered?.license?.length > 0 ? "" : "Select license"
              }
              onSelect={(selectedList, selectedItem) => {
                setToBeFiltered({
                  ...toBeFiltered,
                  ["license"]: selectedList,
                });
              }}
              onRemove={(selectedList, selectedItem) => {
                setToBeFiltered({
                  ...toBeFiltered,
                  ["license"]: selectedList,
                });
              }}
              selectedValues={toBeFiltered.license}
              className="dropdown"
              style={multiSelectStyle}
              selectedValueDecorator={(str) => decode(str)}
            />
          </div>

          <div className="py-2">
            <span>Menu</span>

            <Multiselect
              options={categoryList}
              isObject={false}
              placeholder={
                toBeFiltered.category?.length > 0 ? "" : "Please select menu"
              }
              onSelect={(selectedList, selectedItem) => {
                setToBeFiltered({
                  ...toBeFiltered,
                  ["category"]: selectedList,
                });
              }}
              onRemove={(selectedList, selectedItem) => {
                setToBeFiltered({
                  ...toBeFiltered,
                  ["category"]: selectedList,
                });
              }}
              selectedValues={toBeFiltered["category"]}
              className="dropdown"
              style={multiSelectStyle}
              selectedValueDecorator={(str) => decode(str)}
            />
          </div>

          <div className="py-2">
            <span>Completed Date Range</span>
            <input
              value={
                showDuedateRange
                  ? `${format(
                      toBeFiltered.dueDateRange[0].startDate,
                      "dd-MM-yy"
                    )} to ${format(
                      toBeFiltered.dueDateRange[0].endDate,
                      "dd-MM-yy"
                    )}`
                  : ""
              }
              placeholder="Select Completed Date Range"
              readOnly
              className="inputBox"
              onClick={() => setOpenDueDateCalendar((open) => !open)}
              style={{
                width: "100%",
                height: "33px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                outline: "none",
                padding: "5px",
              }}
            />
            {openDueDateCalendar && (
              <DateRangeComponent
                setOpenCalendar={setOpenDueDateCalendar}
                openCalendar={openDueDateCalendar}
                //  onChange={(date) =>{
                setShowReqDateRange={setShowReqDateRange}
                setShowDuedateRange={setShowDuedateRange}
                isDueDateComponent={true}
                toBeFiltered={toBeFiltered}
                setToBeFiltered={setToBeFiltered}
                //  }
              />
            )}
          </div>

          <div className="form-group my-2">
            <span>Completed By</span>
            <input
              value={toBeFiltered?.completedBy}
              onChange={(e) =>
                setToBeFiltered({
                  ...toBeFiltered,
                  ["completedBy"]: e.target.value,
                })
              }
              type="text"
              className="form-control"
              placeholder="Enter Completed By"
            />
          </div>

          <div className="py-2">
            <span>TAT Status</span>

            <Multiselect
              options={["Adhered", "Delayed"]}
              isObject={false}
              placeholder={toBeFiltered.tatStatus ? "" : "Select TAT"}
              onSelect={(selectedList, selectedItem) => {
                if (selectedList.length > 0) {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["tatStatus"]: selectedList[selectedList.length - 1],
                  });
                } else {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["tatStatus"]: "",
                  });
                }
              }}
              onRemove={(selectedList, selectedItem) => {
                setToBeFiltered({
                  ...toBeFiltered,
                  ["tatStatus"]: "",
                });
              }}
              selectedValues={
                toBeFiltered.tatStatus ? [toBeFiltered.tatStatus] : []
              }
              className="dropdown multiSelectClass"
              style={multiSelectStyle}
              selectedValueDecorator={(str) => decode(str)}
            />
          </div>
        </div>
      </div>
      <section className="pull-right m-4" 
      onClick={handleApplyFilter}
      onKeyDown={handleApplyFilter}
      >
        <a className="btn button_new_success p-3">Apply</a>
      </section>
    </CustomFilter>
  );
};

export default memo(CompletedRequestsFilter);
