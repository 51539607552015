import { format } from "date-fns";
import React, { useRef, useState, useEffect } from "react";
import { DateRange } from "react-date-range";

const DateRangePicker = ({ startDate, endDate, onChange, placeholder }) => {
  const refOne = useRef(null);
  const [showPicker, setshowPicker] = useState(false);
  const fullDate =
    startDate && endDate
      ? `${format(startDate, "dd-MM-yy")} to ${format(endDate, "dd-MM-yy")}`
      : "";

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setshowPicker(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if (refOne.current && !refOne.current.contains(e.target)) {
      setshowPicker(false);
    }
  };

  function handleSelect(ranges) {
    onChange(ranges?.selection);
  }

  return (
    <>
      <div ref={refOne}>
        <input
          value={fullDate}
          placeholder={placeholder || "Select requested date range"}
          readOnly
          className="inputBox"
          onClick={() => setshowPicker(!showPicker)}
          style={{
            width: "100%",
            height: "33px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            outline: "none",
            padding: "5px",
            paddingLeft: "8px",
          }}
        />
        {showPicker && (
          <DateRange
            onChange={handleSelect}
            ranges={[
              {
                startDate: startDate || new Date(),
                endDate: endDate || new Date(),
                key: "selection",
              },
            ]}
            showPicker={showPicker}
          />
        )}
      </div>
    </>
  );
};

export default DateRangePicker;
