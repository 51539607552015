import React, { useEffect, useMemo, useState } from "react";

import { toast } from "react-toastify";
import {
  Editor as EditorComp,
  BtnBold,
  BtnItalic,
  BtnRedo,
  BtnStrikeThrough,
  BtnUnderline,
  BtnUndo,
  EditorProvider,
  Toolbar,
  Separator,
  BtnNumberedList,
  BtnBulletList,
  BtnClearFormatting,
  HtmlButton,
  BtnStyles,
  BtnLink,
  // subStatusList,
  // setSubStatusList
} from "react-simple-wysiwyg";
const Editor = ({ data, setData, handleSave, showSaveBtn, small }) => {
  return (
    <>
      <EditorProvider>
        <EditorComp
          value={data}
          onChange={(e) => {
            setData(e.target.value);
          }}
          style={{
            overflowY: "scroll",
            height: `${small ? "200px" : "450px"}`,
            padding: "10px 15px",
          }}
        >
          <Toolbar>
            <BtnUndo />
            <BtnRedo />
            <Separator />
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnStrikeThrough />
            <Separator />
            <BtnNumberedList />
            <BtnBulletList />
            <BtnClearFormatting />
            <HtmlButton />
            <BtnLink />
            <Separator />
            <BtnStyles />
            <Separator />
          </Toolbar>
        </EditorComp>
      </EditorProvider>

      {showSaveBtn && (
        <section
          className="pull-right m-4"
          onClick={handleSave}
          onKeyDown={handleSave}
        >
          <a className="btn button_new_success p-3">Save</a>
        </section>
      )}
    </>
  );
};

export default Editor;
