import React from "react";
import { Button, Modal } from "react-bootstrap";
const CustomModal = ({
  children,
  headerTitle = null,
  show,
  onClose,
  onSubmit,
  modalSize,
  onCloseText = "Cancel",
  onSubmitText = "Submit",
  footer = null,
  extraClass,
  disableClose,
  disableSubmit,
}) => {
  return (
    <Modal
      show={show}
      animation={false}
      onHide={() => onClose()}
      size={modalSize}
      centered
      className={extraClass}
    >
      {headerTitle && (
        <Modal.Header closeButton>
          <h3 className="modal-title">{headerTitle}</h3>
        </Modal.Header>
      )}
      <Modal.Body style={{ height: "auto", overflow: "auto" }}>
        {children}
      </Modal.Body>
      {footer || (
        <Modal.Footer className="d-flex justify-content-end align-items-stretch">
          <Button variant="danger" onClick={onClose} disabled={disableClose}>
            {onCloseText}
          </Button>
          <Button onClick={onSubmit} variant="success" disabled={disableSubmit}>
            {onSubmitText}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CustomModal;
