import React from "react";
import ExecutiveLayout from "../../../../components/layouts/ExecutiveLayout";
import AdminPageContainer from "../../../../components/AdminPageContainer";
import AddFE from "../../../../Containers/Vendor/AddFE/AddFE";
import { useLocation } from "react-router";
// import AddFE from "../../../../Containers/Executive/AddFE/AddFE";

const AddFEPage = () => {
  const { state } = useLocation();
  console.log(state)
  const breadcrumbs = [
    {
      title: "Home",
      link: "/executive/dashboard",
      isActive: true,
    },
    {
      title: "Field Executives Lists",
      link: "/executive/field-executive",
      isActive: true,
    },
    {
      title: "Add Field Executive",
      // link: "/vendor/field-executives",
      isActive: false,
    },
  ];

  return (
    <ExecutiveLayout>
      <AdminPageContainer>
        {/* <AddFE /> */}
        <AddFE  
         vendorIds={state?.vendorId}
         breadcrumbs={breadcrumbs}
         isEdit={false}
        />
      </AdminPageContainer>
    </ExecutiveLayout>
  );
};

export default AddFEPage;
