import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AllianceManagerServices from "../../../services/API/AllianceManager";

function useSearchData(searchQuery, page, limit, selectedTile) {
  const [searchData, setSearchData] = useState();
  const [isLoading, setIsloading] = useState(false);
  useEffect(() => {
    if (searchQuery) {
      let newParams = { page, limit };
      if (selectedTile?.cardStatus)
        newParams["request-status"] = selectedTile?.cardStatus;
      setIsloading(true);
      AllianceManagerServices.search({
        searchQuery,
        paramsData: newParams,
      }).then((resp) => {
        setIsloading(false);
        if (resp?.status == "success") {
          setSearchData(resp?.data);
        } else {
          setSearchData("");
        }
      });
    }
  }, [searchQuery, page, limit]);

  return { searchData, isLoading };
}

export default useSearchData;
