import React, { useEffect } from "react";


function preventNonNumericalInput(e) {
  e = e || window.event;
  var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
  var charStr = String.fromCharCode(charCode);

  if (!charStr.match(/^[0-9]+$/))
    e.preventDefault();
}
const PaymentDetails = ({
  paymentDetails,
  setPaymentDetails,
  paymentDetailsErrors,
  savePaymentDetails,
  setCourierDetails,
  initialCourierDetails,
  setCourierDetailsErrors,
  setLicenseStatus,
  setLicenseStatusErrors,
  initialLicenseStatus
}) => {
  useEffect(()=>{
   setCourierDetails(initialCourierDetails)
   setCourierDetailsErrors({})
   setLicenseStatus(initialLicenseStatus)
   setLicenseStatusErrors({})
  },[])
  return (
    <div className="card card-sm card-shadow">
      <div className="card-body"  style={{height:'480px'}}>
        <div>
          <h3 className="mb-3">Enter Payment Details</h3>
          <section>
            <label className="label">
              Amount (In INR)<span style={{ color: "red" }}> *</span>
            </label>
            <label className="input">
              <input
                type="number"
                className="input-xs"
                placeholder="Enter Amount (In INR)"
                name="amount"
                value={paymentDetails.amount}
                style={{height:"33px",MozAppearance:'textfield'}}
                onkeypress={(e)=>preventNonNumericalInput(e)}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onChange={(e) => {
                  if (parseInt(e.target.value) < 0) {
                    setPaymentDetails({
                      ...paymentDetails,
                      [e.target.name]: "",
                    });
                  } else {
                    setPaymentDetails({
                      ...paymentDetails,
                      [e.target.name]: e.target.value,
                    });
                  }
                }}
              />
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {paymentDetailsErrors.amount}
              </p>
            </label>
          </section>
          <section>
            <label className="label">Notes (If Any)</label>
            <label className="input">
              <input
                placeholder="Enter Notes (If Any)"
                type="text"
                className="input-xs"
                name="payment_note"
                style={{height:"33px"}}
                value={paymentDetails.payment_note}
                onChange={(e) => {
                  setPaymentDetails({
                    ...paymentDetails,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </label>
          </section>
          <section>
            <label className="label">
              Upload Payment Challan<span style={{ color: "red" }}> *</span>
            </label>
            <div className="input input-file pr-3">
              <span className="button button_new">
                <input
                  type="file"
                  onChange={(e) => {
                    let arr = e.target.files[0]?.name?.split(".")  
                    if (e.target.files) {
                      setPaymentDetails({
                        ...paymentDetails,
                        ["document"]: e.target.files[0],
                        documentType:arr[arr?.length-1]

                      });
                    }
                  }}
                  onClick={(event)=> { 
               event.target.value = null
          }}
                />
                Browse
              </span>

              <input
                type="text"
                placeholder="Attach file as required"
                value={
                  paymentDetails.document &&
                  `${
                    paymentDetails?.document?.name?.length > 50
                      ? paymentDetails.document.name?.slice(0, 50) + "..."
                      : paymentDetails.document.name
                  }` 
                }
                disabled
              />

              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {paymentDetailsErrors.document}
              </p>
            </div>
          </section>
        </div>
        <button
          style={{ background: "green", color: "white" }}
          className="btn button_new margin-top-10 pull-right"
          onClick={savePaymentDetails}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default PaymentDetails;
