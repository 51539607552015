import React from "react";
import AliianceManagerLayout from "../../../components/layouts/AliianceManagerLayout";
import ViewExecutiveDetails from "../../../components/ViewExecutiveDetails";
import { Link } from "react-head";
import { useLocation, useNavigate, useParams } from "react-router";
import FEDetails from "../../../Containers/Vendor/FEDetails/FEDetails";

export default function ExecutiveDetailsPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const breadcrumbs = [
    {
      title: "Home",
      link: "/alliance-manager/dashboard",
      isActive: true,
    },
    {
      title: "Vendors",
      link: "",
      isActive: true,
      onClick: navigate(-1),
    },
    {
      title: "View Field Executive Details",
      // link: "/vendor/field-executives",
      isActive: false,
    },
  ];
  return (
    <AliianceManagerLayout>
      {state?.spocType == "Executive" ? (
        <div id="main" role="main">
          <div id="content">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a
                  href="#"
                  onClick={() => navigate("/alliance-manager/dashboard")}
                >
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <a
                  href="#"
                  onClick={() => navigate("/alliance-manager/vendors")}
                >
                  Vendors
                </a>
              </li>
              <li className="breadcrumb-item active">
                View {state?.spocType} Details
              </li>
            </ol>
            <ViewExecutiveDetails exec_id={params?.id} />
          </div>
        </div>
      ) : (
        <FEDetails breadcrumbProp={breadcrumbs} />
      )}
    </AliianceManagerLayout>
  );
}
